import { Fragment, useCallback, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import sortBy from 'lodash/sortBy';
import BloodTest from './BloodTest';
import { LoaderSmall } from 'components/common/Loader';
import { t } from '@lingui/macro';

/**
 * @function BloodTests
 * Render a List of blood tests that belong to a patient ID
 * @param  match    match object contains information about how a <Route path> matched the URL.
 * @param  history  session route history and route utility methods
 */
const BloodTests = ({ history, match, bloodTests, handleDelete }) => {
  const handleBloodTestSelect = (test) => {
    history.push(`${match.params.id}/blood-test/${test.id}`);
  };

  const sortedBloodTests = sortBy(bloodTests, 'modified').reverse();

  const [loadingCode, setLoadingCode] = useState(undefined);

  const deleteTest = useCallback(
    (id) => {
      setLoadingCode(t`Deleting ...`);
      handleDelete(id);
    },
    [handleDelete],
  );

  const duplicateTest = useCallback(() => {
    setLoadingCode(t`Duplicating ...`);
  }, []);

  useEffect(() => {
    setLoadingCode(undefined);
  }, [bloodTests]);

  return (
    <Fragment>
      <style>{'@keyframes fadeIn { 0% { opacity: 0; }  100% { opacity: 1; }} '}</style>
      <div style={{ position: 'relative' }}>
        {sortedBloodTests.map((test, index) => (
          <BloodTest
            key={index}
            test={test}
            index={index}
            handleBloodTestSelect={handleBloodTestSelect}
            handleDelete={deleteTest}
            handleDuplicate={duplicateTest}
          />
        ))}
        {loadingCode && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              background: 'rgba(255, 255, 255, 0.7)',
              display: 'flex',
              animation: 'fadeIn 0.4s ease-in-out',
              alignItems: 'flex-start',
            }}
          >
            <div
              style={{
                display: 'flex',
                position: 'sticky',
                top: '10%',
                width: '100%',
                justifyContent: 'center',
                marginTop: '10%',
              }}
            >
              <LoaderSmall dark inline text={loadingCode} />
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default withRouter(BloodTests);
