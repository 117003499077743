import { useEffect, useState } from 'react';
import { Flex, Box, Text } from 'rebass';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { Trans } from '@lingui/macro';
import { updateBloodTest } from 'api';
import useGlobal from 'hooks/global';
import AddBasicInfo from './AddBasicInfo';
import AddParameter from './AddParameter';
import Parameters from './Parameters';
import Loader from 'components/common/Loader';
import Button from 'components/common/Button';
import { parseLocalizedDate } from 'components/common/DateFormat';
import AvailableModels from 'components/Reports/AvailableModels/AvailableModels';
import { FaArrowLeft } from 'react-icons/fa';
import ConfirmationModal from 'components/common/ConfirmationModal';
import { useErrorDialog } from 'components/errorDialog/ErrorDialog';
import { calculateAge } from 'utils';

/**
 * @function BloodTest
 * Container for basic blood test info and blood test parameters
 * @param  match    match object contains information about how a <Route path> matched the URL.
 * @param  lockEdit hide the edit part for a blood test
 */
const BloodTest = ({ compact, match, history }) => {
  const { showError } = useErrorDialog();
  const { register, handleSubmit, setValue } = useForm({ shouldFocusError: false });
  const [globalState, globalActions] = useGlobal();
  const { bloodTest, patient, savedParameters, user } = globalState;
  const isLocked = bloodTest.status === 'completed';
  const [createReportError, setCreateReportError] = useState(undefined);

  const patientAgeOnBloodTest = (() => {
    if (bloodTest.blood_test_date === null || (patient.birth_year === null && patient.birth_date === null)) {
      return null;
    }

    return calculateAge(patient.birth_year, patient.birth_date, bloodTest.blood_test_date);
  })();

  useEffect(() => {
    return () => {
      globalActions.resetBlockGenerateReport();
    };
  }, [globalActions]);

  /**
   * Handle AddBasicInfo form submit
   */
  const onSubmit = async (data) => {
    try {
      data = {
        ...data,
        blood_test_date: data.blood_test_date === '' ? null : parseLocalizedDate(data.blood_test_date),
        is_on_treatment: data.is_on_treatment == null ? null : data.is_on_treatment === 'true',
        is_pregnant: data.is_pregnant == null ? null : data.is_pregnant === 'true',
        patient_id: match.params.id,
        blood_test_id: match.params.bloodTestId,
      };
      const bloodTest = await updateBloodTest(data);
      globalActions.setPatientBloodTest(bloodTest);
    } catch (e) {
      showError(e);
      console.log(e);
    }
  };

  const isInvalidBloodTest = () => {
    if (
      globalState.blockGenerateReport.length !== 0 ||
      savedParameters.filter((item) => item.value === null || item.value === '').length !== 0 ||
      bloodTest.is_pregnant === true ||
      bloodTest.blood_test_date === null ||
      patientAgeOnBloodTest < 18
    ) {
      return true;
    }
    return false;
  };

  /**
   * Delay the onChange submission for 400ms seconds,
   * prevents to many requests if you solve the form quickly
   */
  const debounceSubmit = () => debounce(handleSubmit(onSubmit), 400);

  if (!bloodTest.id) return <Loader big text="Loading blood test" />;

  // Disable add parameter disabled
  const isDisabled =
    bloodTest.blood_test_date === null ||
    bloodTest.is_on_treatment === null ||
    (bloodTest.is_pregnant === null && patient.gender === 'F');

  return (
    <>
      <ConfirmationModal
        cancelText={<Trans>Close</Trans>}
        header={<Trans>Error!</Trans>}
        open={createReportError != null}
        handleClose={() => setCreateReportError(undefined)}
        message={createReportError}
      />
      {!bloodTest.in_production && (
        <Text sx={{ mb: 3, textAlign: 'left', ml: 1 }} color="orange" fontSize={3}>
          <Trans>For educational and research purposes only (not for clinical use)</Trans>
        </Text>
      )}
      <Flex pb={2} alignItems="center" flexDirection="column" key="edit-forms">
        {!compact && bloodTest.status !== 'completed' && (
          <Box bg="lightgray" key="form" mx={3} mb={3} p={[3, 3, 3, 3, 3]} sx={{ borderRadius: 6 }} width={1}>
            <AddBasicInfo
              key="blood-test-info"
              register={register}
              onChange={debounceSubmit()}
              bloodTest={bloodTest}
              user={user}
              gender={patient.gender}
              disabled={isDisabled}
              setValue={setValue}
              patientAge={patientAgeOnBloodTest}
            />
            <AddParameter
              disabled={isDisabled || bloodTest.is_pregnant === true || patientAgeOnBloodTest < 18}
              key="blood-test-add-parameter"
              defaultConvention={bloodTest.convention_type}
            />
          </Box>
        )}

        {bloodTest.imported_report_code != null && (
          <Box fontSize={3} sx={{ my: 4, textAlign: 'center' }}>
            <Text color="darkergray" mb={2}>
              <Trans>
                {`This blood test has been imported using the following code: ${bloodTest.imported_report_code}`}
              </Trans>
            </Text>

            <Text color="orange">
              <Trans>
                Only the blood parameters supported by SBAS Clinical Decision Support Software have been imported from
                your blood test. SBAS Clinical Decision Support Software uses the International System of Units (SI) for
                its calculations. Therefore, some parameter values may automatically convert and may not be the same as
                on your blood test.
              </Trans>
            </Text>
          </Box>
        )}

        <Parameters isLocked={isLocked} key="parameters" />

        {!compact && (
          <>
            <Flex
              key="create-report"
              width={1}
              // variant="fluid"
              justifyContent="flex-end"
              alignContent="center"
              alignItems="center"
              sx={{
                position: 'sticky',
                bottom: 0,
                background: 'rgba(255, 255, 255, 0.95)',
                borderTop: '2px solid rgba(230, 230, 230, 0.5)',
              }}
              pb={1}
            >
              <Box sx={{ display: 'flex', alignItems: 'end', justifyContent: 'start', flexGrow: 0 }}>
                <Button
                  // m={3}
                  // mb={2}
                  pl={2}
                  onClick={() => history.push(`/patient/${match.params.id}`)}
                  style={{
                    background: 'unset',
                    color: '#8F9299',
                  }}
                >
                  <Flex alignItems={'center'}>
                    <FaArrowLeft />
                    &nbsp;
                    <Trans>Blood tests</Trans>
                  </Flex>
                </Button>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'end', justifyContent: 'end', flexGrow: 1 }}>
                {bloodTest.blood_test_date != null && (
                  <AvailableModels match={match} disabled={isInvalidBloodTest()} skipFetchValues />
                )}
              </Box>
            </Flex>
          </>
        )}
      </Flex>
    </>
  );
};

export default withRouter(BloodTest);
