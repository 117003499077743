import { Input, Label, Select } from '@rebass/forms';
import DatePicker from 'components/common/DatePicker';
import { getSharedData } from 'components/common/SharedData/SharedData';
import { useRef } from 'react';
import { Box, Flex, Text } from 'rebass';

export function UserInputForm({ register, setFormValue, errors }) {
  const countries = useRef(getSharedData('COUNTRIES'));
  return (
    <>
      <Flex variant="fluid">
        <Box pr={[0, 0, 0, 0, 3]} width={['100%', '100%', '100%', '100%', 1 / 2]}>
          <Label mt={2}>First Name *</Label>
          <Input className={errors.first_name ? 'error' : ''} {...register('first_name', { required: true })} />
          {errors.first_name && (
            <Text py={2} fontSize={1} color="orange">
              Field is required
            </Text>
          )}
        </Box>
        <Box width={['100%', '100%', '100%', '100%', 1 / 2]}>
          <Label mt={2}>Last Name *</Label>
          <Input className={errors.last_name ? 'error' : ''} {...register('last_name', { required: true })} />
          {errors.last_name && (
            <Text py={2} fontSize={1} color="orange">
              Field is required
            </Text>
          )}
        </Box>
      </Flex>
      <Flex variant="fluid" mt={2}>
        <Box pr={[0, 0, 0, 0, 3]} width={['100%', '100%', '100%', '100%', 1 / 2]}>
          <Label>Date Of Birth *</Label>
          <DatePicker
            over18
            register={register}
            registerProps={{ required: true }}
            name="birthday"
            setFormValue={setFormValue}
            errors={errors.birthday}
          />
          {errors.birthday && (
            <Text py={2} fontSize={1} color="orange">
              Field is required
            </Text>
          )}
        </Box>
        <Box width={['100%', '100%', '100%', '100%', 1 / 2]}>
          <Label>Country *</Label>
          <Select className={errors.country ? 'error' : ''} {...register('country', { required: true })}>
            <option></option>
            {countries.current.map((country, index) => (
              <option key={index} value={country.code}>
                {country.name}
              </option>
            ))}
          </Select>
          {errors.country && (
            <Text py={2} fontSize={1} color="orange">
              Country is required
            </Text>
          )}
        </Box>
      </Flex>
      <Flex variant="fluid">
        <Box width={['100%', '100%', '100%', '100%', 1 / 2]} pr={[0, 0, 0, 0, 3]}>
          <Label mt={2}>E-mail *</Label>
          <Input className={errors.email ? 'error' : ''} {...register('email', { required: true })} />
          {errors.email && (
            <Text py={2} fontSize={1} color="orange">
              E-mail is required
            </Text>
          )}
        </Box>
        <Box width={['100%', '100%', '100%', '100%', 1 / 2]} />
      </Flex>
    </>
  );
}
