import { Text } from 'rebass';
import { i18n } from '@lingui/core';
import { defineMessage } from '@lingui/macro';

const messages = {
  uid: {
    required: defineMessage({ message: 'Patient ID is required' }),
  },
  gender: {
    required: defineMessage({ message: 'Gender is required' }),
  },
  birth_date: {
    required: defineMessage({ message: 'Date of birth is required' }),
  },
  comment: {
    required: defineMessage({ message: 'Comment is required' }),
  },
};

const InputErrorMessage = ({ errors, name }) => {
  // Note: if you are using FormContext, then you can use Errors without props eg:
  // const { errors } = useFormContext();
  if (!errors[name]) return null;

  return (
    <Text py={2} fontSize={1} color="orange">
      {i18n._(messages[name][errors[name].type])}
    </Text>
  );
};

export default InputErrorMessage;
