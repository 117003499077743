import { Box, Flex } from 'rebass';

export function ParamContributionLine({ value, max, color, rtl }) {
  const width = (Math.abs(value) / max) * 100;

  return (
    <Flex
      bg={'lightergray'}
      flexDirection={rtl ? 'row-reverse' : 'row'}
      sx={{ borderRadius: '10px', width: '100%' }}
    >
      <Box
        bg={color}
        style={{
          width: width + '%',
          height: '6px',
          borderRadius: '10px',

          transition: 'all 0.4s ease-in-out',
        }}
      />
    </Flex>
  );
}
