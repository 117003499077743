import { useEffect } from 'react';
import { Route, withRouter } from 'react-router-dom';
import { Flex, Box, Text } from 'rebass';
import { Trans } from '@lingui/macro';

import Profile from './Profile';
import Password from './Password';
import Other from './Other';
import TwoFactorSettings from './TwoFactorSettings';
import BillingEventsHistory from './BllingEventsHistory';
import Breadcrumbs from 'components/common/Breadcrumbs';
import Link from 'components/common/Link';
import Documents from './Documents';
import Subscriptions from './Subscriptions/Subscriptions';
import Support from './Support';
import Organization from './Organization';

const Settings = (props) => {
  useEffect(() => {
    if (props.location.pathname === '/settings') {
      props.history.replace('/settings/profile');
    }
  }, [props.location.pathname, props.history]);

  return (
    <Flex pb={4} variant="container">
      <Breadcrumbs />
      <Flex pb={4} pt={1} flexDirection={['column', 'column', 'column', 'row', 'row']} color="text">
        <Box py={3} width={[1, 1, 1, 1 / 3, 1 / 5]}>
          <Box py={3} px={4} mr={3} bg="lightgray" sx={{ borderRadius: 16 }}>
            <Link to="/settings/organization">
              <Text py={2}>
                <Trans>Organization information</Trans>
              </Text>
            </Link>
            <Link to="/settings/profile">
              <Text py={2}>
                <Trans>User information</Trans>
              </Text>
            </Link>
            <Link to="/settings/password">
              <Text py={2}>
                <Trans>Change your password</Trans>
              </Text>
            </Link>
            <Link to="/settings/2fa">
              <Text py={2}>
                <Trans>2-Factor authentication</Trans>
              </Text>
            </Link>
            <Link to="/settings/other">
              <Text py={2}>
                <Trans>Settings</Trans>
              </Text>
            </Link>
            <Link to="/settings/documents">
              <Text py={2}>
                <Trans>Documents</Trans>
              </Text>
            </Link>
            <Link to="/settings/subscriptions">
              <Text py={2}>
                <Trans>Subscriptions</Trans>
              </Text>
            </Link>
            <Link to="/settings/billing-events-history">
              <Text py={2}>
                <Trans>Billing events</Trans>
              </Text>
            </Link>
            <Link to="/settings/support">
              <Text py={2}>
                <Trans>Support</Trans>
              </Text>
            </Link>
          </Box>
        </Box>
        <Box width={[1, 1, 1, 2 / 3, 4 / 5]}>
          <Route path="/settings/organization">
            <Organization />
          </Route>
          <Route path="/settings/profile">
            <Profile />
          </Route>
          <Route path="/settings/password">
            <Password />
          </Route>
          <Route path="/settings/2fa">
            <TwoFactorSettings />
          </Route>
          <Route path="/settings/other">
            <Other />
          </Route>
          <Route path="/settings/billing-events-history">
            <BillingEventsHistory />
          </Route>
          <Route path="/settings/documents">
            <Documents />
          </Route>
          <Route path="/settings/subscriptions">
            <Subscriptions />
          </Route>
          <Route path="/settings/support">
            <Support />
          </Route>
        </Box>
      </Flex>
    </Flex>
  );
};

export default withRouter(Settings);
