import { Trans } from '@lingui/macro';
import { Checkbox, Label, Select } from '@rebass/forms';
import { useCallback, useState } from 'react';
import { FaAngleLeft, FaAngleRight, FaWrench } from 'react-icons/fa';
import { Heading, Box, Flex, Text, Button } from 'rebass';
import ParamContribution from './paramContribution';
import { DiseaseColorBox } from './DiseaseColorBox';

export default function ReportParamDescriptionCard({
  disease,
  diseaseColor,
  selectedIndex,
  diseaseCount,
  allDiseasesMaxContribution,
  prev,
  next,
  userIsStaff,
}) {
  const [showNanParams, setShowNanParams] = useState(false);
  const [noOfParams, setNoOfParams] = useState(10);

  const onSelectChange = useCallback((el) => {
    setNoOfParams(el.target.value);
  }, []);

  const toggleShowNanParams = useCallback(() => {
    setShowNanParams((prevValue) => !prevValue);
  }, []);

  const contributions = showNanParams ? disease?.explanations : disease?.explanations.filter((d) => d.value != null);

  return (
    <div>
      <Box style={{ position: 'sticky', top: 0, background: 'linear-gradient(white,white 90%, #ffffffcc)' }}>
        <Flex justifyContent={'space-between'} alignItems={'baseline'}>
          <CustomCardButton
            text={<Trans>Previous</Trans>}
            icon={<FaAngleLeft style={{ verticalAlign: 'sub' }} />}
            disabled={selectedIndex === 0}
            onClick={prev}
          />

          <Flex flexDirection={'column'}>
            <Heading p={2} color="orange">
              <DiseaseColorBox color={diseaseColor} />
              {disease.code} - {disease.description}
            </Heading>
          </Flex>

          <CustomCardButton
            text={<Trans>Next</Trans>}
            iconIsRight
            icon={<FaAngleRight style={{ verticalAlign: 'sub' }} />}
            disabled={selectedIndex === diseaseCount - 1}
            onClick={next}
          />
        </Flex>
        <hr
          style={{
            border: 0,
            height: '1px',
            margin: '0.5em',
            backgroundImage: 'linear-gradient(90deg, transparent, rgba(160,160,160, 0.1), transparent)',
          }}
        />
        <Flex p={2} pt={0}>
          <Text textAlign={'right'} mr={2} color="orange" width="50%">
            <Trans>Against the disease</Trans>
          </Text>
          <Flex ml={2} width="50%">
            <Text flexGrow={1} textAlign={'left'} color="blue">
              <Trans>For the disease</Trans>
            </Text>

            {userIsStaff && (
              <CustomCardSettings>
                <Label htmlFor="no_of_paramas">
                  <Trans>Parameters:</Trans>
                </Label>
                <Select
                  name="no_of_paramas"
                  value={noOfParams}
                  style={{
                    padding: 3,
                  }}
                  onChange={onSelectChange}
                >
                  {[5, 10, 20, 40, 80].map((value) => (
                    <option key={value}>{value}</option>
                  ))}
                  <option value={Number.MAX_VALUE}>∞</option>
                </Select>

                <Label alignItems={'center'} width="auto">
                  <Checkbox name="showNanParams" checked={showNanParams} onChange={toggleShowNanParams} />
                  <Trans>Show not measured parameters</Trans>
                </Label>
              </CustomCardSettings>
            )}
          </Flex>
        </Flex>
      </Box>
      {contributions.slice(0, noOfParams).map((p, i) => (
        <ParamContribution param={p} key={i} maxContribution={allDiseasesMaxContribution} />
      ))}
    </div>
  );
}

export function CustomCardButton({ iconIsRight, text, icon, onClick, disabled }) {
  return (
    <Button
      disabled={disabled}
      px={2}
      pr={3}
      height={'unset'}
      onClick={onClick}
      bg="unset"
      color="text"
      minWidth={'initial'}
      sx={{
        marginTop: -4,
        whiteSpace: 'nowrap',
        borderRadius: 4,
        ':hover': {
          backgroundColor: 'unset',
          color: '#f77b13',
        },
        ':disabled': {
          color: 'transparent',
          background: 'unset',
        },
        ':focus': {
          background: 'unset',
        },
      }}
    >
      {iconIsRight ? (
        <>
          {text} {icon}
        </>
      ) : (
        <>
          {icon} {text}
        </>
      )}
    </Button>
  );
}

export function CustomCardSettings({ children, smallWidth }) {
  const [show, setShow] = useState(false);

  return (
    <Flex sx={{ zIndex: 8000 }}>
      <Box px={2} onClick={() => setShow(!show)} color={show ? 'primary' : 'darkgray'} sx={{ cursor: 'pointer' }}>
        <FaWrench size="1em" />
      </Box>
      {show && (
        <Box
          onClick={() => setShow(!show)}
          sx={{ zIndex: 100, position: 'fixed', top: 0, bottom: 0, left: 0, right: 0 }}
        />
      )}
      {show && (
        <Box
          p={2}
          bg="white"
          sx={{
            zIndex: 9000,
            position: 'absolute',
            marginTop: 36,
            marginLeft: smallWidth ? -150 : -250,
            border: '1px solid',
            borderColor: '#edeff3',
            // transform: 'translate(-52px)',
            width: smallWidth ? 200 : 300,
            textAlign: 'center',
            boxShadow: '0 3px 8px rgba(0, 0, 0, .1)',
            borderRadius: 8,
          }}
        >
          {children}
        </Box>
      )}
    </Flex>
  );
}
