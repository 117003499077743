import MicroModal from 'react-micro-modal';
import 'react-micro-modal/dist/index.css';
import { Box, Flex, Heading, Text } from 'rebass';
import { Label, Radio, Input } from '@rebass/forms';
import { Trans } from '@lingui/macro';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import Message from 'components/common/Message';
import { useHistory } from 'react-router-dom';
import useGlobal from 'hooks/global';
import { FaExclamationTriangle } from 'react-icons/fa';
import Button from 'components/common/Button';
import { importPatientBloodTest } from 'api';
import { AboutImport } from './AboutImport';

export default function ImportBloodTestDialog({ isOpen, showDialog }) {
  const history = useHistory();
  const [{ patient }] = useGlobal();

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm();

  const postFormData = useCallback(
    async (data) => {
      setIsLoading(true);
      try {
        const bloodTest = await importPatientBloodTest(patient.id, {
          is_pregnant: data.is_pregnant === 'true',
          is_on_treatment: data.is_on_treatment === 'true',
          code: data.code,
        });

        history.push(`/patient/${patient.id}/blood-test/${bloodTest.id}`);
      } catch (error) {
        if (error.code) {
          setError(<Trans>The code is invalid!</Trans>);
        } else if (error.is_pregnant) {
          setError(<Trans>Patient is pregnant, editing disabled.</Trans>);
        } else if (error.birth_year || error.birth_date) {
          setError(<Trans>Date of birth mismatch!</Trans>);
        } else if (error.sex) {
          setError(<Trans>Gender mismatch!</Trans>);
        } else {
          setError(
            <Trans>
              An unexpected error occurred. Please refresh the page and try again. If the error persists, please contact
              administrator.
            </Trans>,
          );
        }
        setIsLoading(false);
      }
    },
    [history, patient.id],
  );

  const isOnTreatment = watch('is_on_treatment');
  const isPregnant = watch('is_pregnant');

  return (
    <MicroModal
      open={isOpen}
      trigger={showDialog}
      overrides={{
        Dialog: {
          style: { maxWidth: '600px', width: '100%' },
        },
      }}
      // eslint-disable-next-line react/no-children-prop
      children={(handleCloseUncontrolled) => (
        <Flex flexDirection={'column'}>
          <Heading mb={3} color="text">
            <Trans>Import blood test</Trans>
          </Heading>

          <AboutImport />

          <Flex as="form" sx={{ flexDirection: 'column' }} autoComplete="off">
            <Box width={1} mb={2}>
              <Label>
                <Trans>Blood test's alphanumeric code</Trans>{' '}
                <Box ml={1} color="orange">
                  *
                </Box>
              </Label>
              <Input {...register('code', { required: true })} className={errors.code ? 'error' : ''} autoFocus/>
              {errors.code && (
                <Text py={2} fontSize={1} color="orange">
                  Field is required
                </Text>
              )}
            </Box>
            <Flex sx={{ flexDirection: 'row' }}>
              <Box mt={[2, 2, 2, 0, 0]} width={[1 / 2, 1 / 2, 1 / 2, 1 / 2, 1 / 2]}>
                <Label pb={2}>
                  <Trans>Patient receiving treatment</Trans>
                  <Box ml={1} color="orange">
                    <Box ml={1} color="orange">
                      *
                    </Box>
                  </Box>
                </Label>
                <Flex>
                  <Label>
                    <Radio {...register('is_on_treatment', { required: true })} value={'true'} />
                    <Trans>Yes</Trans>
                  </Label>
                  <Label>
                    <Radio {...register('is_on_treatment', { required: true })} value={'false'} />
                    <Trans>No</Trans>
                  </Label>
                </Flex>
                {errors.is_on_treatment && (
                  <Text pb={2} fontSize={1} color="orange">
                    Field is required
                  </Text>
                )}
              </Box>

              {patient.gender === 'F' && (
                <Box mt={[2, 2, 2, 0, 0]} width={[1 / 2, 1 / 2, 1 / 2, 1 / 2, 1 / 2]}>
                  <Label pb={2}>
                    <Trans>Patient pregnant</Trans>
                    <Box ml={1} color="orange">
                      *
                    </Box>
                  </Label>
                  <Flex>
                    <Label>
                      <Radio {...register('is_pregnant', { required: true })} value={'true'} />
                      <Trans>Yes</Trans>
                    </Label>
                    <Label>
                      <Radio {...register('is_pregnant', { required: true })} value={'false'} />
                      <Trans>No</Trans>
                    </Label>
                  </Flex>
                  {errors.is_pregnant && (
                    <Text pb={2} fontSize={1} color="orange">
                      Field is required
                    </Text>
                  )}
                </Box>
              )}
            </Flex>
          </Flex>

          {isPregnant === 'true' && (
            <Flex mb={3} mt={2} width={1} sx={{ alignItems: 'center', color: 'text' }}>
              <FaExclamationTriangle style={{ width: 36 }} />
              <Text key="warning-pregnant" color="text" ml={2} fontSize={1}>
                <Trans>Patient is pregnant, editing disabled.</Trans>
              </Text>
            </Flex>
          )}

          {isPregnant !== 'true' && isOnTreatment === 'true' && (
            <Flex mb={3} mt={2} width={1} sx={{ alignItems: 'center', color: 'text' }}>
              <FaExclamationTriangle style={{ width: 36 }} />
              <Text key="warning-pregnant" color="text" ml={2} fontSize={1}>
                <Trans>
                  Therapy can significantly change blood test results and, therefore, results of SBAS Clinical Decision Support Software.
                </Trans>
              </Text>
            </Flex>
          )}

          {error && (
            <Flex width={1} my={3}>
              <Message type="error" message={error} />
            </Flex>
          )}

          <Flex justifyContent="flex-end" mt={2}>
            <Button mr={3} onClick={handleCloseUncontrolled}>
              <Trans>Cancel</Trans>
            </Button>
            <Button onClick={handleSubmit(postFormData)} disabled={isPregnant === 'true'} isLoading={isLoading}>
              <Trans>Import</Trans>
            </Button>
          </Flex>
        </Flex>
      )}
    />
  );
}
