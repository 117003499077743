import { Trans } from '@lingui/macro';
import { useCallback, useState, useRef, useEffect } from 'react';
import { Box, Button, Text, Flex } from 'rebass';
import { updatePatient } from 'api';
import { FaExpand, FaCompress, FaRemoveFormat, FaPen } from 'react-icons/fa';
import { Label, Textarea } from '@rebass/forms';
import useGlobal from 'hooks/global';
import MarkdownViewer from './Markdown';
import { useErrorDialog } from 'components/errorDialog/ErrorDialog';

export default function PatientInfoComment({ defaultComment }) {
  const { showError } = useErrorDialog();
  const ref = useRef();
  const commentRef = useRef();

  const [globalState, globalActions] = useGlobal();

  const [isSaving, setIsSaving] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  const [comment, setComment] = useState(defaultComment?.toString());
  const [commentChanged, setCommentChanged] = useState(false);
  const [isCommentExpanded, setIsCommentExpanded] = useState(false);

  const toggleCommentExpansion = useCallback(() => {
    setIsCommentExpanded((prevState) => !prevState);
  }, []);

  const toggleEditMode = useCallback(() => {
    setIsEditMode((prevState) => !prevState);
    setTimeout(autoGrowText);
  }, []);

  const saveCommentChanges = useCallback(() => {
    setIsSaving(true);

    const action = async () => {
      try {
        const { patient } = globalState;
        const p = {
          ...patient,
          patient_id: patient.id,
          comment: comment,
        };

        const updatedPatient = await updatePatient(p);
        globalActions.setPatient(updatedPatient);
      } catch (e) {
        showError(e);
        console.log(e);
      } finally {
        setIsSaving(false);
        setCommentChanged(false);
      }
    };

    action();
  }, [comment, globalActions, globalState, showError]);

  const updateComment = useCallback((e) => {
    setCommentChanged(true);
    setComment(e.target.value);
  }, []);

  useEffect(() => {
    setComment(defaultComment);
    setTimeout(autoGrowText);
  }, [defaultComment]);

  const autoGrowText = () => {
    const element = commentRef.current;
    if (element) {
      element.style.height = '5px';
      element.style.height = element.scrollHeight + 'px';
    }
  };

  return (
    <>
      <Box
        mt={[2, 2, 2, 2, isCommentExpanded || isEditMode ? 2 : 0]}
        width={[1, 1, 1, 1, isCommentExpanded || isEditMode ? 1 : 4 / 8]}
      >
        <Label color="darkergray">
          <Flex sx={{ width: '100%' }}>
            <Box flexGrow={1}>
              <Trans>Comment</Trans>
            </Box>
            <Flex>
              <Text
                color="darkergray"
                fontSize="12px"
                variant="clickable"
                onClick={toggleEditMode}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                {isEditMode ? (
                  <>
                    <FaRemoveFormat style={{ marginRight: 4 }} />
                    <Trans>Close edit mode</Trans>
                  </>
                ) : (
                  <>
                    <FaPen style={{ marginRight: 4 }} />
                    <Trans>Open edit mode</Trans>
                  </>
                )}
              </Text>
              {!isEditMode && (
                <Text
                  color="darkergray"
                  fontSize="12px"
                  variant="clickable"
                  onClick={toggleCommentExpansion}
                  sx={{ display: 'flex', alignItems: 'center', marginLeft: '2ch' }}
                >
                  {isCommentExpanded ? (
                    <>
                      <FaCompress style={{ marginRight: 4 }} />
                      <Trans>Collapse</Trans>
                    </>
                  ) : (
                    <>
                      <FaExpand style={{ marginRight: 4 }} />
                      <Trans>Expand</Trans>
                    </>
                  )}
                </Text>
              )}
            </Flex>
          </Flex>
        </Label>
        <Text
          ref={ref}
          data-color-mode="light"
          fontSize={2}
          sx={{
            minHeight: '33px',
            maxHeight: isCommentExpanded || isEditMode ? 'unset' : '75px',
            overflow: 'auto',
            border: !isEditMode ? '1px solid #edeff3' : 'unset',
            padding: !isEditMode ? '6px' : '1px',
          }}
        >
          {isEditMode ? (
            <Textarea
              ref={commentRef}
              value={comment}
              onChange={updateComment}
              style={{ resize: 'none', minHeight: '125px', overflow: 'hidden' }}
              onInput={autoGrowText}
            />
          ) : (
            <MarkdownViewer markdown={comment} />
          )}
        </Text>
        {commentChanged && (
          <Button isLoading={isSaving} onClick={saveCommentChanges} mt={2}>
            <Trans>Save</Trans>
          </Button>
        )}
      </Box>
    </>
  );
}
