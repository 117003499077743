import { Flex, Box, Text } from 'rebass';
import VideoPlayer from './VideoPlayer';
import { Trans } from '@lingui/macro';

export const Showcase = () => (
  <Flex variant="container" alignItems="center" justifyContent="center" width="100%" maxWidth="100%">
    <Text color="text" fontSize={4} my={4}>
      <Trans>Introductory video - SBAS Software</Trans>
    </Text>
    <Box width={[1, 1, 1 / 2]} mb={5}>
      <VideoPlayer
        onVideoEnd={() => {}}
        url="https://sbas-bucket.fra1.cdn.digitaloceanspaces.com/SBAS_Software/Introductory_video/SBAS_Introductory_video_2023_web.mp4"
      />
    </Box>
  </Flex>
);

export default Showcase;
