import { ModelImage } from './modelImage/ModelImage';
import { Flex, Button, Box } from 'rebass';
import Loader from 'components/common/Loader';

export default function ModelButton({
  handleOpen,
  model,
  tightVersion,
  disabled,
  isLoading,
  isFree,
}) {
  return (
    <Button
      variant={tightVersion ? 'textOnly' : 'primary'}
      disabled={disabled}
      onClick={handleOpen}
      sx={
        tightVersion
          ? {
              m: 0,
              p: 2,
              color: 'primary',
              background: 'unset',
              width: '100%',
              textAlign: 'left',
              cursor: 'pointer',
            }
          : { m: 2, bg: isFree ? 'orange' : undefined }
      }
    >
      <Flex alignItems="center" justifyContent="space-between" flexDirection={''}>
        <Flex>
          {isLoading && !tightVersion && (
            <Box pr={2}>
              <Loader />
            </Box>
          )}
          {tightVersion && <ModelImage modelCode={model.code} />}
          {model.name}
        </Flex>
      </Flex>
    </Button>
  );
}
