import { Flex, Box, Text, Button } from 'rebass';
import { FaCopy, FaTimes } from 'react-icons/fa';
import { withRouter } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import { duplicateBloodTest, getBloodTests } from 'api';
import { DateFormatDistance } from 'components/common/DateFormat';
import DateFormat from 'components/common/DateFormat';
import ConfirmationModal from 'components/common/ConfirmationModal';
import { GoLock, GoFile } from 'react-icons/go';
import useGlobal from 'hooks/global';
import { useErrorDialog } from 'components/errorDialog/ErrorDialog';

export const BloodTest = ({ handleBloodTestSelect, test, match, index, history, handleDelete, handleDuplicate }) => {
  const { showError } = useErrorDialog();
  const [, globalActions] = useGlobal();

  const onDuplicateBloodTest = async () => {
    try {
      handleDuplicate();

      await duplicateBloodTest({
        patient_id: match.params.id,
        blood_test_id: test.id,
      });

      const bloodTests = await getBloodTests(match.params.id);
      globalActions.setBloodTests(bloodTests);
    } catch (e) {
      console.log(e);
      showError(e);
    }
  };

  const availableReports = test.reports.filter((report) => report.status === 'completed');

  return [
    <Flex key={index} variant="row" sx={{ paddingTop: '12px', paddingBottom: '12px' }}>
      <Box color="text" width={['100%', '100%', '100%', '70%', '70%']}>
        <Text variant="clickable" onClick={() => handleBloodTestSelect(test)} mr={3} fontSize={4}>
          {test.status === 'completed' ? (
            <Box mr={2} sx={{ display: 'inline-block' }} color="darkergray">
              <GoLock style={{ marginBottom: '-3px' }} />
            </Box>
          ) : (
            <Box mr={2} sx={{ display: 'inline-block' }} color="darkergray">
              <GoFile style={{ marginBottom: '-3px' }} />
            </Box>
          )}
          <Text width={['90%', 'auto']} sx={{ display: 'inline-block' }}>
            {test.name}
          </Text>{' '}
          <Text color="darkergray" sx={{ display: 'inline-block' }} fontSize={[2]}>
            {test.blood_test_date && (
              <Text color="darkergray" sx={{ display: 'inline-block' }} fontSize={2}>
                - <DateFormat value={test.blood_test_date} />
                {' -'}
              </Text>
            )}{' '}
            <Text sx={{ display: 'inline-block' }}>
              {' '}
              <Trans>{' Parameters: '}</Trans> {test.parameters_count}
            </Text>
            {' - '}
            <Text color="darkergray" sx={{ display: 'inline-block' }}>
              <Trans>Updated</Trans> <DateFormatDistance from={new Date(test.modified)} to={new Date()} />{' '}
              <Trans>ago</Trans>
            </Text>{' '}
          </Text>
        </Text>
        <Flex key="reports" width={['100%', '100%', '100%', '80%']} pl={0}>
          {availableReports.length > 0 && (
            <Box fontSize={14}>
              <Box sx={{ display: 'inline-block' }}>
                {availableReports.map((report, index) => (
                  <Button
                    variant="small"
                    key={index}
                    fontWeight={500}
                    onClick={() => history.push(`/patient/${match.params.id}/blood-test/${test.id}/${report.id}`)}
                  >
                    {report.result?.info?.product?.model?.name}
                  </Button>
                ))}
              </Box>
            </Box>
          )}
        </Flex>
      </Box>
      <Flex color="text" variant="patient.actions" height="100%" justifyContent="flex-end" mt={[3, 3, 3, 1, 1]}>
        <Box mr={3} color="darkergray" sx={{ display: 'inline-block', alignSelf: 'flex-start' }} fontSize={3}>
          <Text
            color="darkergray"
            fontSize={1}
            variant="clickable"
            onClick={() => onDuplicateBloodTest()}
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            <FaCopy title="Duplicate" style={{ marginRight: 8 }} />
            <Trans>Duplicate</Trans>
          </Text>
        </Box>
        <Box>
          <ConfirmationModal
            trigger={(handleOpen) => (
              <Text
                color="darkergray"
                fontSize={1}
                variant="clickable"
                onClick={() => handleOpen()}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <FaTimes title="Trash" style={{ marginRight: 8 }} />
                <Trans>Delete</Trans>
              </Text>
            )}
            handleConfirm={() => handleDelete(test.id)}
            message={<Trans>{`Do you really want to delete ${test.name}?`}</Trans>}
          />
        </Box>
      </Flex>
    </Flex>,
  ];
};

export default withRouter(BloodTest);
