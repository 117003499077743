import { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getJwt } from 'utils/localstorage';
import useGlobal from 'hooks/global';
import TopNav from 'components/common/TopNav';
import Footer from 'components/common/Footer';
import { Flex } from 'rebass';
import { getUser } from 'api';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import TermsConfirm from 'components/TermsFaq/TermsConfirm';
import VideoIntro from 'components/common/VideoIntro';
import { getSubscriptionStatusFromUser } from '../Dropdown';
import { useErrorDialog } from 'components/errorDialog/ErrorDialog';

export async function activateLanguage(locale) {
  const { messages } = await import(`locales/${locale}/messages`);
  i18n.load(locale, messages);
  i18n.activate(locale);
}

activateLanguage('en');

// User fetch hook
export const useUser = () => {
  const { showError } = useErrorDialog();
  const [globalState, globalActions] = useGlobal();
  const [isLoading, handleLoading] = useState(true);
  const { user } = globalState;

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const user = await getUser(); // get user
        activateLanguage(user.report_language || 'en');
        await globalActions.setUser(user);
      } catch (e) {
        showError(e);
        console.log(e);
      } finally {
        handleLoading(false);
      }
    };
    if (!globalState.user.email && getJwt()) {
      fetchUser();
    } else {
      handleLoading(false);
    }
  }, [globalActions, globalState.user.email, showError]);

  return [{ isLoading, user }];
};

// Render routes with top navigation, content and footer
export const RenderRoute = ({ props, user }) => {
  return (
    <Route {...props}>
      <I18nProvider i18n={i18n}>
        <Flex justifyContent="space-between" flexDirection="column" width="100%" alignItems="center" minHeight="100vh">
          <Flex flexDirection="column" width="100%" alignItems="center" sx={{ flex: '0 0 auto' }}>
            <TopNav user={user} />
            {props.children}
          </Flex>
          <Footer />
        </Flex>
      </I18nProvider>
    </Route>
  );
};

/**
 * @function ProtectedRoute - Route accesible only with token and user object
 */
export const ProtectedRoute = (props) => {
  const [{ isLoading, user }] = useUser();
  // User authenticated
  if (getJwt() && user.email) {
    // TODO: Optimize for this exceptions
    // Terms & Condtions exception
    if (user && user.terms_confirmed === false) {
      return <TermsConfirm {...props} user={user} />;
    }

    const subscription = getSubscriptionStatusFromUser(user);

    const subscriptionIsActive = subscription?.status === 'active';
    const pathStartsWithPricing = props.path.startsWith('/pricing');

    // User selects the subscription plan and makes a payment
    if (!subscriptionIsActive) {
      // WE WANT TO FORCE RENDER (BUY SUBSCRIPTION) BEFORE VIDEO QUIZ
      if (pathStartsWithPricing) {
        return <RenderRoute props={props} user={user} />;
      } else {
        return <Redirect to="/pricing" />;
      }
    }

    // Terms & Condtions exception
    if (user && user.video_viewed === false) {
      return <VideoIntro {...props} user={user} />;
    }
    // Render all protected views
    return <RenderRoute props={props} user={user} />;
  } else if (!isLoading) {
    // Guest user
    // All other routes are redirected to login
    return <Redirect to="/login" />;
  } else return null;
};

/**
 * @function PublicRoute - public routes restricted if already logged in
 */
export const PublicRoute = (props) => {
  const [{ isLoading, user }] = useUser();

  if (getJwt() && user.email) {
    // User authenticated, page visit not allowed redirect
    return <Redirect to="/" />;
  } else if (!isLoading) {
    // Signup with top navigation and footer
    if (props.path.startsWith('/signup') || props.path === '/training') {
      return <RenderRoute props={props} user={user} />;
    }
    // Login case without topnav and footer
    return <Route {...props}>{props.children}</Route>;
  } else return null;
};
