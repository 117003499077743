import { useCallback, useLayoutEffect, useState } from 'react';

export const useIsOverflow = (ref, callback) => {
  const [isOverflow, setIsOverflow] = useState(undefined);

  const trigger = useCallback(() => {
    const { current } = ref;

    if (current) {
      const hasOverflow = current.scrollHeight > current.clientHeight;

      setIsOverflow(hasOverflow);

      callback?.(hasOverflow);
    }
  }, [ref, callback]);

  useLayoutEffect(() => {
    trigger();
  }, [trigger]);

  return { isOverflow, trigger };
};
