import { useEffect, useState, useRef } from 'react';
import { Box, Flex, Text } from 'rebass';
import { Label, Input, Select } from '@rebass/forms';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import { Trans } from '@lingui/macro';
import sortBy from 'lodash/sortBy';

import ConfirmationModal from 'components/common/ConfirmationModal';
import SearchParameter from './SearchParameter';
import Button from 'components/common/Button';
import useGlobal from 'hooks/global';
import { updateBloodTestParameter, getBloodTestParameters } from 'api';
import { getReferenceValues } from 'utils/parameters';
import { useErrorDialog } from 'components/errorDialog/ErrorDialog';

const AddParameter = ({ match, defaultConvention, disabled }) => {
  const { showError } = useErrorDialog();
  const {
    register,
    handleSubmit,
    setValue,
    setFocus,
    getValues,
    formState: { errors },
  } = useForm({ shouldFocusError: false });
  const [globalState, globalActions] = useGlobal();

  const [isSubmitLoading, handleSubmitLoading] = useState(false);
  const [isOpened, handleOpened] = useState(false);
  const [showWarning, handleWarning] = useState();
  const [currentConventionType, handleCurrentConventionType] = useState(defaultConvention);
  const [showConfirmation, handleShowConfirmation] = useState(false);

  const [searchTerm, handleSearch] = useState('');
  const [selectedParam, setSelectedParam] = useState({});
  const [selectedUnit, setSelectedUnit] = useState(undefined);
  const [referenceValues, handleReference] = useState({ minRef: '/', maxRef: '/' });

  /**
   * @function onSelect handles the dropdown paramater click
   * @param selectedParam is the selected blood parameter
   */
  const onSelect = (selectedParam) => {
    handleWarning(null);
    // prefill the search field with the full parameter name
    handleSearch(selectedParam.name);

    // reset value field if anything was in there, retriger validation
    setValue('value', null); // reset value

    // close the dropdown
    handleOpened(false);

    // get default unit, depends on defaultConvention
    const unit = find(
      selectedParam.units,
      (item) => findIndex(item.convention_type, (convType) => convType === defaultConvention) !== -1,
    );

    // save the selectedParam in local state
    setSelectedParam(() => {
      const newParam = {
        ...selectedParam,
        defaultUnit: unit,
      };

      return newParam;
    });

    setTimeout(() => {
      setSelectedUnit(unit.name);
      setValue('unit', unit.name, { shouldValidate: true });
      setValue('searchParameters', selectedParam.name, { shouldValidate: true });
    });

    // set reference values
    const refValues = getReferenceValues(unit, globalState.patient.gender);
    handleReference(refValues);

    setFocus('value');
  };

  /**
   * @function onUnitChange when the unit changes we have to find/update the reference min/max values
   * @param selectedUnitName the selected unit
   * @param units optional param, if not passed we search the local state
   */
  const onUnitChange = (selectedUnitName, units) => {
    let allUnits = units || selectedParam.units;

    // find the correct unit in the array
    const unitData = find(allUnits, (unit) => unit.name === selectedUnitName);
    const refValues = getReferenceValues(unitData, globalState.patient.gender);
    handleReference(refValues);
    setSelectedUnit(selectedUnitName);

    // validate value againts the newly select unit min/max
    const inputValue = getValues('value');
    validate(inputValue, unitData.min, unitData.max, refValues);
  };

  /**
   * Confirmation dialog if value is out of bounds
   */
  const onSubmit = async (data) => {
    if (showWarning?.outOfBounds) {
      handleShowConfirmation(true);
    } else if (showWarning?.invalidValue) {
      // do nothing
    } else {
      submit(data);
    }
  };

  const handleConfirm = () => {
    const values = getValues();
    try {
      submit(values);
    } catch (e) {
      showError(e);
      console.log(e);
    }
  };

  /**
   * @function submit handles the AddParameter form submision
   * @param data an object { code, value, unit }
   */
  const submit = async (data) => {
    try {
      // show button loader
      handleSubmitLoading(true);
      // add patient_id and blood_test_id params needed for api call, saved in the url
      data = {
        ...data,
        unit: getValues().unit,
        value: data.value.replace(',', '.'),
        code: selectedParam.code,
        patient_id: match.params.id,
        blood_test_id: match.params.bloodTestId,
      };
      // api call
      await updateBloodTestParameter(data);
      const res = await getBloodTestParameters(match.params.id, match.params.bloodTestId);
      globalActions.setSavedParameters(res);
      // hide button loader
      handleSubmitLoading(false);
      // reset form
      handleSearch(''); // reset search field
      setSelectedParam({}); // reset units
      setSelectedUnit(undefined);
      setValue('value', null); // reset value
      setValue('unit', undefined);
      handleOpened(false);
      handleWarning(null);
      handleReference({ minRef: '/', maxRef: '/' });
      setFocus('searchParameters');
    } catch (e) {
      handleSubmitLoading(false);
      showError(e);
      throw e;
    }
  };

  // async value field validtion, check if out of boundaries
  const validate = (val, min, max, refValues) => {
    // refValues is passed on unit change because state is not refreshed then yet
    const minRef = (refValues && refValues.minRef) || referenceValues.minRef;
    const maxRef = (refValues && refValues.maxRef) || referenceValues.maxRef;
    const absMax = max || referenceValues.max;
    const absMin = min || referenceValues.min;
    const valFormat = Number.parseFloat(val.replace(',', '.'));
    if (selectedParam.code) {
      if (minRef != null && maxRef == null && valFormat < absMax && valFormat >= minRef) {
        handleWarning(null);
      } else if (val != null && !/^[0-9]+([,.][0-9]+)?$/.test(val)) {
        handleWarning({ invalidValue: true });
      } else if (valFormat > absMax || valFormat < absMin) {
        handleWarning({ outOfBounds: true });
      } else if (valFormat > maxRef || valFormat < minRef) {
        handleWarning({ refs: true });
      } else {
        handleWarning(null);
      }
    }
  };

  // dropdown list of params
  // filter the already saved and the one that match the search term
  const filteredParams = globalState.parameters
    .filter((param) => {
      if (param.is_deprecated) {
        return false;
      }

      if (globalState.savedParameters.find((s) => s.code === param.code)) {
        return false;
      }

      if (param.name.toLowerCase().includes(searchTerm.toLowerCase())) {
        return true;
      } else {
        return false;
      }
    })
    .sort((a, b) => {
      const la = a.name.toLowerCase();
      const lb = b.name.toLowerCase();
      const term = searchTerm.toLowerCase();

      if (la.startsWith(term) && lb.startsWith(term)) {
        return la.localeCompare(lb);
      } else if (la.startsWith(term)) {
        return -1;
      } else if (lb.startsWith(term)) {
        return 1;
      } else {
        // return 0; // return original sort from 'server'
        // return la.localeCompare(lb); // return srt by alphabetic
        // return la.indexOf(term) - lb.indexOf(term); // sort by startOfIndex by server sort
        return la.indexOf(term) - lb.indexOf(term) + la.localeCompare(lb); // sort by start of index and alphabetic
      }
    });

  // save the already added ones add a custom saved property, for warning display
  const addedParams = sortBy(
    globalState.parameters
      .filter(
        (param) =>
          findIndex(globalState.savedParameters, (saved) => saved.code === param.code) !== -1 &&
          param.name.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1,
      )
      .map((item) => ({ ...item, saved: true })),
    'name',
  );

  useEffect(() => {
    if (currentConventionType !== defaultConvention) {
      handleSearch(''); // reset search field
      setSelectedParam({}); // reset units
      setSelectedUnit(undefined);
      setValue('value', null); // reset value
      handleOpened(false);
      handleWarning(false);
      handleReference({ minRef: '/', maxRef: '/' });
      handleCurrentConventionType(defaultConvention);
    }
  }, [currentConventionType, defaultConvention, setValue]);

  useEffect(() => {
    if (errors.searchParameters) {
      setFocus('searchParameters');
    } else if (errors.value) {
      setFocus('value');
    }
  }, [errors.value, errors.searchParameters, setFocus]);

  const searchParamContainerRef = useRef();

  const selectedParamUnits = filterDuplicatedUnits(selectedParam.units);

  return (
    <Flex
      key="form"
      as="form"
      variant="fluid"
      autoComplete="off"
      onSubmit={handleSubmit(onSubmit)}
      pt={3}
      sx={{
        borderTop: '1px solid',
        borderColor: 'darkgray',
        opacity: disabled ? '0.3' : '1',
        pointerEvents: disabled ? 'none' : 'all',
      }}
    >
      <Box pr={[0, 0, 0, 0, 3]} width={[1, 1, 1, 1, 3 / 7]}>
        <div ref={searchParamContainerRef}>
          <Label>
            <Trans>Parameter or examination group</Trans>
          </Label>
          <SearchParameter
            filteredParams={[...filteredParams, ...addedParams]}
            handleOpened={handleOpened}
            handleSearch={handleSearch}
            isOpened={isOpened}
            onSelect={onSelect}
            searchTerm={searchTerm}
            errors={errors}
            register={register}
            width={searchParamContainerRef.current && searchParamContainerRef.current.offsetWidth}
          />
        </div>
      </Box>
      <Box pr={[0, 3, 3, 3, 3]} width={[1, 1 / 2, 1 / 2, 1 / 2, 1 / 8]}>
        <Label color={showWarning ? 'orange' : 'text'}>
          {showWarning?.outOfBounds ? (
            <Trans>Value out of boundaries!</Trans>
          ) : showWarning?.invalidValue ? (
            <Trans>Invalid input!</Trans>
          ) : (
            <Trans>Value</Trans>
          )}
        </Label>
        <Input
          className={showWarning ? 'error-important' : errors.value ? 'error' : ''}
          type="text"
          {...register('value', {
            required: true,
            onChange: (e) => validate(e.target.value),
          })}
          onFocus={() => handleOpened(false)}
        />
      </Box>
      <Box pr={[0, 0, 0, 0, 3]} width={[1, 1 / 2, 1 / 2, 1 / 2, 1 / 8]}>
        <Label>
          <Trans>Unit</Trans>
        </Label>
        <Select
          key={selectedParam.code}
          className={errors.unit ? 'error' : ''}
          {...register('unit', {
            required: true,
            onChange: (e) => {
              onUnitChange(e.target.value);
            },
          })}
          value={selectedUnit}
        >
          {selectedParamUnits?.map((unit, i) => (
            <option key={i + selectedParam.code} value={unit.name}>
              {unit.name}
            </option>
          ))}
          {selectedParamUnits == null && <option disabled />}
        </Select>
      </Box>
      <Box width={[1, 1, 1, 1, 1 / 7]}>
        <Label color={showWarning && showWarning.refs ? 'orange' : 'text'}>
          <Trans>Reference value</Trans>
        </Label>
        <Box color="text" sx={{ display: 'flex', alignItems: 'center', height: 50 }}>
          <Text>Min:</Text>
          <Text px={1} fontWeight="bold" color="primary">
            {referenceValues.minRef || '0'}
          </Text>
          <Text ml={3}>Max:</Text>
          <Text px={1} fontWeight="bold" color="primary">
            {referenceValues.maxRef ?? '/'}
          </Text>
        </Box>
      </Box>
      <Flex width={[1, 1, 1, 1, 'auto']} sx={{ flexGrow: 1 }} justifyContent="flex-end" alignItems="flex-end">
        <ConfirmationModal
          open={showConfirmation}
          handleClose={() => handleShowConfirmation(false)}
          handleConfirm={handleConfirm}
          message={<Trans>This parameter value is out of bounds, do you really want to save it?</Trans>}
        />
        <Button type="submit" isLoading={isSubmitLoading} mt={[1, 1, 1, 1, 3]} disabled={showWarning?.invalidValue}>
          <Trans>Add parameter</Trans>
        </Button>
      </Flex>
    </Flex>
  );
};

export default withRouter(AddParameter);

export function filterDuplicatedUnits(units) {
  if (!units) {
    return units;
  }

  //  group by name
  const grouped = units.reduce((acc, unit) => {
    const unitName = unit.name;
    if (!acc[unitName]) {
      acc[unitName] = [];
    }
    acc[unitName].push(unit);
    return acc;
  }, {});

  // if there is a unit with SI, return only that one, otherwise return all
  const filtered = Object.values(grouped).map((units) => {
    const unitWithSI = units.find((unit) => unit.convention_type.includes('SI'));

    if (unitWithSI) {
      return [unitWithSI];
    }

    return units;
  });

  // flatten the array
  const result = filtered.flat();
  return result;
}
