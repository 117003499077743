import { useState, useRef, Fragment } from 'react';
import { Box, Text } from 'rebass';
import { Input } from '@rebass/forms';
import { Trans } from '@lingui/macro';

const SearchParameter = ({
  filteredParams,
  handleOpened,
  handleSearch,
  isOpened,
  onSelect,
  searchTerm,
  width,
  errors,
  register,
}) => {
  const [activeSuggestion, handleActiveSuggestion] = useState(0);

  // Event fired when the user presses a key down
  const onKeyDown = (e) => {
    // On enter key, update the input and close suggestions
    if (e.keyCode === 13 && filteredParams[activeSuggestion]) {
      onSelect(filteredParams[activeSuggestion]);
      handleActiveSuggestion(0);
      handleOpened(false);
    }

    // User pressed the up arrow, decrement the index
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      handleActiveSuggestion(activeSuggestion - 1);
      autoSuggest.current.scrollTop -= 51;
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredParams.length) {
        return;
      }

      handleActiveSuggestion(activeSuggestion + 1);
      if (activeSuggestion > 3) {
        autoSuggest.current.scrollTop += 51;
      }
    }
  };

  const handleOnSelect = (param) => {
    onSelect(param);
    handleActiveSuggestion(0);
    autoSuggest.current.scrollTop = 0;
  };

  const handleSearchAndOpen = (e) => {
    if (e.target && e.target.value !== '' && !isOpened) {
      handleOpened(true);
    } else if (e.target.value === '') {
      handleOpened(false);
    }

    handleSearch(e.target.value);
  };

  const autoSuggest = useRef(null);

  return (
    <Box>
      {isOpened && ( // overlay
        <Box
          onClick={() => handleOpened(false)}
          sx={{
            position: 'fixed',
            zIndex: 0,
            height: '100%',
            width: '100%',
            top: 0,
            left: 0,
            bottom: 0,
          }}
        />
      )}
      <Input
        className={errors.searchParameters ? 'error' : ''}
        {...register('searchParameters', { required: true })}
        value={searchTerm}
        onChange={(e) => handleSearchAndOpen(e)}
        placeholder="Search parameters"
        onKeyDown={onKeyDown}
      />
      {isOpened && (
        <Box
          ref={autoSuggest}
          sx={{
            bg: 'white',
            border: '1px solid',
            borderColor: 'gray',
            display: 'block',
            maxHeight: 300,
            position: 'absolute',
            width: width,
            zIndex: 900,
            overflowY: 'scroll',
          }}
        >
          {filteredParams.length > 0 ? (
            filteredParams.map((param, index) => (
              <Box
                key={index}
                onClick={() => handleOnSelect(param)}
                p={3}
                sx={{
                  backgroundColor: activeSuggestion === index && 'lightgray',
                  opacity: param.saved ? '0.6' : '1',
                  '&:hover': {
                    bg: 'lightgray',
                    cursor: 'pointer',
                  },
                }}
              >
                <Param name={param.name} searchedText={searchTerm} colorize />
                {param.saved && (
                  <>
                    &nbsp;
                    <Text color="orange" sx={{ display: 'inline-block' }}>
                      <Trans>- Already selected</Trans>
                    </Text>
                  </>
                )}
              </Box>
            ))
          ) : (
            <Box p={3}>
              <Trans>No results found</Trans>
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
};

export default SearchParameter;

function Param({ name, colorize, searchedText }) {
  if (!colorize) {
    return name;
  }

  const regex = new RegExp(searchedText, 'gi');
  const matches = [...name.matchAll(regex)];

  return (
    <>
      {matches.map((match, i, a) => {
        const currMatchIndex = match.index ?? 0; // nullish coalesce to ignore "undefined", but matches def. exists (cause of upper verfication)
        const prevMatchIndex = matches[i - 1]?.index ?? 0; // nullish coalesce to ignore "undefined", but matches def. exists (cause of upper verfication)

        return (
          <Fragment key={i}>
            {i === 0 && <>{name.substr(0, currMatchIndex)}</>}
            {i > 0 && (
              <>
                {name.substr(
                  prevMatchIndex + searchedText.length,
                  currMatchIndex - prevMatchIndex - searchedText.length,
                )}
              </>
            )}
            <span
              style={{
                color: '#f77b13',
                fontWeight: '500',
                // color: 'white',
                // backgroundColor: '#D2D2D2',
                borderRadius: '2px',
                padding: '0px',
              }}
            >
              {name.substr(currMatchIndex ?? 0, searchedText.length)}
            </span>
            {i === a.length - 1 && <>{name.substr(currMatchIndex + searchedText.length)} </>}
          </Fragment>
        );
      })}
    </>
  );
}
