import { Link } from 'react-router-dom';
import Logo from 'assets/sba-logo.svg';
import { Flex, Image } from 'rebass';

import Dropdown from 'components/common/Dropdown';

const TopNav = ({ user }) => {
  return (
    <Flex
      variant="topNav"
      alignItems="center"
      sx={{
        flexDirection: ['row', 'row', 'row'],
        flex: '0 0 auto',
        flexFlow: 'row wrap',
      }}
      px={3}
      pt={3}
      pb={0}
    >
      <Flex width={'50%'}>
        <Link to="/">
          <Image src={Logo} width={['100%', 'auto']} maxWidth="340px" />
        </Link>
      </Flex>
      <Flex width={'50%'} fontSize={[1, 2]} sx={{ justifyContent: 'flex-end' }} textAlign="right">
        {user?.email && <Dropdown user={user} />}
      </Flex>
    </Flex>
  );
};

export default TopNav;
