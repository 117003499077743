import { Flex, Text } from 'rebass';

export function SimpleTable({ data, cols }) {
  return (
    <Flex sx={{ justifyContent: 'center', justifyItems: 'center', width: '100%' }}>
      <Flex
        mr={2}
        ml={1}
        fontSize={1}
        flexDirection="column"
        sx={{
          border: '1px solid',
          borderColor: 'gray',
          borderRadius: 4,
          flexGrow: 1,
          textAlign: 'left',
          maxWidth: '670px',
        }}
      >
        <Flex>
          {(cols || []).map((col, ci) => (
            <Flex key={ci} alignItems="center" justifyContent="flex-start" p={2} width={col.width ?? '100%'}>
              <Text fontWeight="bold" color="text">
                {col.label}
              </Text>
            </Flex>
          ))}
        </Flex>

        {(data || []).map((d, di) => (
          <Flex
            key={di}
            bg={di % 2 === 0 ? '#f4f8fb' : 'white'}
            sx={{
              borderColor: 'gray',
              minHeight: 48,
              fontSize: '13px',
            }}
          >
            {(cols || []).map((col, ci) => (
              <Flex key={ci} alignItems="center" justifyContent="flex-start" p={2} width={col.width ?? '100%'}>
                {col.value(d)}
              </Flex>
            ))}
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
}
