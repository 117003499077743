import Loader from 'components/common/Loader';
import useGlobal from 'hooks/global';
import { getAllParameters, getBloodTest, getUser } from 'api';
import { useCallback, useEffect, useState, useRef } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import ReportsHeader from 'components/Reports/BloodTestHeader';
import ReportsContainer from 'components/Reports/ReportsContainer';
import BloodTest from 'components/BloodTest';
import { useInterval } from 'hooks/utils';
import { Box, Flex } from 'rebass';
import AvailableModels from 'components/Reports/AvailableModels/AvailableModels';
import { useErrorDialog } from 'components/errorDialog/ErrorDialog';

export default function BloodTestReportContent() {
  const { showError } = useErrorDialog();
  const match = useRouteMatch();
  const { bloodTestId, id: patientId } = useParams();

  const [{ bloodTest, patient }, globalActions] = useGlobal();
  const [isLoading, setIsLoading] = useState(true);

  const [availableModelsRenderKey, setAvailableModelsRenderKey] = useState(0);
  const processingReports = useRef(
    (bloodTest.reports || []).filter((item) => item.status === 'processing').map((item) => item.id),
  );

  const updateBloodTest = useCallback(
    async (refreshAvailableModelsIfNeeded) => {
      try {
        const bloodTest = await getBloodTest(patientId, bloodTestId);
        await globalActions.setPatientBloodTest(bloodTest);

        if (refreshAvailableModelsIfNeeded) {
          const newProccessingReports = (bloodTest.reports || [])
            .filter((item) => item.status === 'processing')
            .map((item) => item.id);

          if (
            newProccessingReports.length !== processingReports.current.length ||
            newProccessingReports.some((id) => !processingReports.current.includes(id)) ||
            processingReports.current.some((id) => !newProccessingReports.includes(id))
          ) {
            setAvailableModelsRenderKey((prev) => prev + 1);
          }
        }

        const user = await getUser();
        await globalActions.setUser(user);
      } catch (e) {
        showError(e);
        console.log(e);
      }
    },
    [globalActions, bloodTestId, patientId, showError],
  );

  useEffect(() => {
    const fetchBloodTestAndAllParams = async () => {
      try {
        const bloodTest = await getBloodTest(patientId, bloodTestId);
        globalActions.setPatientBloodTest(bloodTest);

        const parameters = await getAllParameters();
        globalActions.setParameters(parameters);

        setIsLoading(false);
      } catch (e) {
        showError(e);
        console.log(e);
      }
    };

    fetchBloodTestAndAllParams();
  }, [globalActions, bloodTestId, patientId, showError]);

  useInterval(() => {
    if (bloodTest.reports) {
      processingReports.current = (bloodTest.reports || [])
        .filter((item) => item.status === 'processing')
        .map((item) => item.id);

      if (processingReports.current.length > 0) {
        updateBloodTest(true);
      }
    }
  }, 4000);

  if (isLoading) {
    return <Loader big text="Loading blood test" />;
  }

  const hasReports = (bloodTest.reports ?? []).length > 0;
  return (
    <>
      {hasReports ? (
        <>
          <ReportsHeader bloodTest={bloodTest} patient={patient} />

          <Flex variant="bloodTestReport.container">
            <Flex variant="container">
              <Box py={4} sx={{ textAlign: 'center' }}>
                <Box mb={4}>
                  <AvailableModels key={availableModelsRenderKey} match={match} testHasReports />
                </Box>
                <ReportsContainer bloodTest={bloodTest} />
              </Box>
            </Flex>
          </Flex>
        </>
      ) : (
        <BloodTest />
      )}
    </>
  );
}
