import { Flex, Box, Heading } from 'rebass';
import { Label, Select } from '@rebass/forms';
import { Trans } from '@lingui/macro';
import { updateUser } from 'api';
import useGlobal from 'hooks/global';
import { activateLanguage } from 'components/common/ProtectedRoute';
import { useErrorDialog } from 'components/errorDialog/ErrorDialog';

const OtherSettings = () => {
  const { showError } = useErrorDialog();
  const [globalState, globalActions] = useGlobal();
  const { user } = globalState;

  const updateLanguage = async (e) => {
    try {
      const language = e.target.value;
      activateLanguage(language);

      const updatedUser = await updateUser({
        report_language: language,
      });
      globalActions.setUser(updatedUser);
      globalActions.resetData(); // clear state
      localStorage.setItem('lang', language);
    } catch (e) {
      console.log(e);
      showError(e);
    }
  };

  const updateConvType = async (e) => {
    try {
      const convType = e.target.value;
      const updatedUser = await updateUser({
        convention_type: convType,
      });
      globalActions.setUser(updatedUser);
      globalActions.resetData(); // clear state
    } catch (e) {
      console.log(e);
      showError(e);
      throw e;
    }
  };

  return (
    <Box p={3} width={[1, 1, 1, 1, 3 / 5]} minHeight={500}>
      <Heading mb={3}>
        <Trans>Settings</Trans>
      </Heading>
      <Flex width={1}>
        <Box width={1}>
          <Box width={[1, 1, 1, 1 / 2, 1 / 2]}>
            <Label>
              <Trans>Language </Trans>
            </Label>
            <Select placholder="Units" onChange={updateLanguage} defaultValue={user.report_language}>
              <option value="en">English</option>
              {process.env.NODE_ENV === 'development' && (
                <>
                  <option value="fr">Française</option>
                </>
              )}
              <option value="sl">Slovenščina</option>
            </Select>
          </Box>
          <Box width={[1, 1, 1, 1 / 2, 1 / 2]}>
            <Label>
              <Trans>Units convention type</Trans>
            </Label>
            <Select placholder="Units" onChange={updateConvType} defaultValue={user.convention_type}>
              <option value="Conv">CONV</option>
              <option value="SI">SI</option>
            </Select>
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default OtherSettings;
