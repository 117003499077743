import styles from './header.module.css';

export function Header() {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.heading}>Pricing</div>
        <div className={styles.subheading}>
          To use SBAS web application, choose one of the following subscriptions.
        </div>
      </div>
    </>
  );
}
