import { useState, useRef, useCallback, useEffect, createContext, useContext } from 'react';
import { Flex, Box, Text, Image } from 'rebass';
import { withRouter } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { Trans } from '@lingui/macro';
import { FaAngleUp, FaAngleDown } from 'react-icons/fa';
import useGlobal from 'hooks/global';
import { DateFormatDistance } from 'components/common/DateFormat';
import { getReportDetails } from 'api';
import { parseUDI } from 'utils';
import { parse, format } from 'date-fns';
import { LoaderSmall } from 'components/common/Loader';
import FactoryFull from 'assets/iso_symbols/factory-full.png';
import FactoryEmpty from 'assets/iso_symbols/factory-empty.png';
import IconUDI from 'assets/iso_symbols/UDI.png';
import IconCI from 'assets/iso_symbols/consult-instruction.png';
import IconIVD from 'assets/iso_symbols/IVD.png';
import IconCE from 'assets/iso_symbols/CE.png';
import IconCD1304 from 'assets/iso_symbols/ce1304-md.png';
import IconEcRep from 'assets/iso_symbols/ec-rep.png';
import ReportParamDescriptionContainer from './ReportParamDescription/ReportParamDescriptionContainer';
import Graph from './Graph';
import { Recommendation } from './Recommendation/Recommendation';
import { useErrorDialog } from 'components/errorDialog/ErrorDialog';
import { DownloadPdf } from './DownloadPdf/DownloadPdf';
import { Subdiagnosis } from './Subdiagnosis/Subdiagnosis';
import { Symptoms } from './Symptoms/Symptoms';

const scrollToRef = (ref) =>
  ref.current && ref.current.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' }); // General scroll to element function

/**
 * @function Report, is an accordion, used for display report info and graph
 * @param report  sba report
 * @param match   match object contains information about how a <Route path> matched the URL.
 */
const Report = ({ report, match, openedByDefault }) => {
  const { showError } = useErrorDialog();
  const [isLoading, handleLoading] = useState(true);
  const [open, toggleReport] = useState(openedByDefault || false);
  const [isNew, toggleIsNew] = useState(false);
  const [reportDetails, handleReportDetails] = useState({});
  const myRef = useRef(report.model);
  const executeScroll = async () => scrollToRef(myRef);
  const isCompleted = report.status === 'completed';
  const isProcessing = report.status === 'processing';
  const uncompleted = report.status === 'uncompleted';
  const udiString = reportDetails?.result?.info?.product?.version;
  const udiObj = udiString && parseUDI(udiString);
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal();
  const [selectedIcdId, setSelectedIcdId] = useState(undefined);

  // Open a report and scroll to it, change the url
  const openAndScrollTo = useCallback(() => {
    toggleReport(true);
    setTimeout(() => executeScroll(), 200);
  }, []);

  // Fetch report data
  const fetchReportDetails = useCallback(async () => {
    try {
      const icds = await getReportDetails(match.params.id, match.params.bloodTestId, report.id);
      handleReportDetails(icds);
    } catch (e) {
      console.log(e);
      showError(e);
    } finally {
      handleLoading(false);
    }
  }, [match, report.id, showError]);

  useEffect(() => {
    // Fetch report details
    if (isEmpty(reportDetails) && report.status === 'completed') {
      fetchReportDetails();

      // If a report ID is present in the URL
      if (match.params.reportId === report.id) {
        openAndScrollTo();
      }
    }

    if (!isNew && report.status === 'processing') {
      toggleIsNew(true);
    }

    if (isNew && isCompleted) {
      openAndScrollTo();
      toggleIsNew(false);
    }
  }, [report, match, openAndScrollTo, isNew, isCompleted, reportDetails, fetchReportDetails, isLoading]);

  if (!open || isLoading) {
    return (
      <Flex
        ref={myRef}
        onClick={() => (isCompleted && toggleReport(true)) || {}}
        variant={uncompleted ? 'reports.uncompletedReport' : 'reports.closedReport'}
      >
        <Flex width={[1, 1, 5 / 8]} alignItems="center">
          {!isLoading && isCompleted && (
            <Text sx={{ display: 'inherit' }} color="darkgray">
              <FaAngleDown fontSize="1.6rem" style={{ marginRight: 8 }} />
            </Text>
          )}
          <Text variant="inline" color={isCompleted ? 'orange' : 'darkgray'} fontSize={18}>
            <Text variant="inline" fontSize={4}>
              {report.result?.info?.product?.model?.name}
            </Text>
            {isProcessing && <LoaderSmall text={<Trans>Processing...</Trans>} dark inline />}
            {uncompleted && (
              <Text pl={2} variant="inline">
                <Trans>(Uncompleted)</Trans>
              </Text>
            )}
          </Text>

          <Text pl={2} sx={{ display: 'inline-block', paddingTop: '6px' }} fontSize={12} color="darkgray">
            <Trans>Report ID</Trans>: {report.id}
          </Text>
        </Flex>

        <Box width={[1, 1, 3 / 8]} py={[2, 2, 0]} textAlign={['left', 'left', 'right']} alignSelf="center">
          <Text sx={{ display: 'inline-block' }} color="primary">
            <Trans>Created:</Trans> <DateFormatDistance from={new Date(report.created)} to={new Date()} />{' '}
            <Trans>ago</Trans>
          </Text>
        </Box>
      </Flex>
    );
  } else {
    const showExplanation =
      globalState.user?.enable_report_explanations && reportDetails.result?.results?.[0]?.explanations?.length > 0;
    const showRecommendation =
      globalState.user?.enable_report_recommendations &&
      reportDetails.result?.results?.[0]?.recommendations?.length > 0;
    const showSymptoms =
      globalState.user?.enable_report_symptoms &&
      (reportDetails.result?.results?.[0]?.manifestations?.comorbidities?.length > 0 ||
        reportDetails.result?.results?.[0]?.manifestations?.signs?.length > 0 ||
        reportDetails.result?.results?.[0]?.manifestations?.symptoms?.length > 0);
    const showDiagnosis =
      globalState.user?.enable_report_subdiagnoses && reportDetails.result?.results?.[0]?.subcategories?.length > 0;

    return (
      <ReportIcd.Provider value={{ selectedIcdId, setSelectedIcdId }}>
        <Flex ref={myRef} variant="reports.openedReport">
          <Flex onClick={() => toggleReport(false)} sx={{ cursor: 'pointer' }}>
            <Box width={[1 / 2]}>
              <Flex color="primary" textAlign="left" fontWeight={400} alignItems="center">
                <Text sx={{ display: 'inherit' }} color="darkgray">
                  <FaAngleUp fontSize="1.6rem" style={{ marginRight: 8 }} />
                </Text>
                <Text variant="inline" color="orange" fontSize={4}>
                  {report?.result?.info?.product?.model?.name}
                </Text>
                <Text pl={2} sx={{ display: 'inline-block', paddingTop: '6px' }} fontSize={12} color="darkgray">
                  <Trans>Report ID</Trans>: {report.id}
                </Text>
              </Flex>
            </Box>
            <Flex alignItems="center" justifyContent="flex-end" color="primary" width={[1 / 2]}>
              <Trans>Created:</Trans> <DateFormatDistance from={new Date(report.created)} to={new Date()} />{' '}
              <Trans>ago</Trans>
            </Flex>
          </Flex>
          {reportDetails?.result?.info?.research_use_only && (
            <Text sx={{ mt: 3, textAlign: 'left', ml: 2 }} color="orange" fontSize={3}>
              <Trans>For educational and research purposes only (not for clinical use)</Trans>
            </Text>
          )}
          <Box textAlign="center" pt={3}>
            <Graph reportDetails={reportDetails} />
          </Box>
          <Text p={2} fontSize={12} color="darkgray" textAlign="center">
            <Trans>Model ver.</Trans>: {reportDetails?.result?.info?.product?.model?.revision}
          </Text>
          <Box
            variant="inline"
            mt={2}
            mb={showExplanation || showRecommendation || showDiagnosis || showSymptoms ? 4 : undefined}
          >
            <DownloadPdf match={match} report={report} showError={showError} />
          </Box>

          {showExplanation && (
            <ReportParamDescriptionContainer reportDetails={reportDetails} userIsStaff={globalState.user.is_staff} />
          )}

          {showRecommendation && (
            <Recommendation reportDetails={reportDetails} userIsStaff={globalState.user.is_staff} />
          )}

          {showSymptoms && <Symptoms reportDetails={reportDetails} />}

          {showDiagnosis && <Subdiagnosis reportDetails={reportDetails} />}

          {udiObj && (
            <>
              <Box
                display="flex"
                flexDirection="row"
                color="text"
                justifyContent="space-between"
                pt={'12px'}
                mt={4}
                mb={1}
                sx={{ borderTop: '1px solid', textAlign: 'left', borderColor: 'text' }}
              >
                <Box flex={1} sx={{ fontSize: 11 }}>
                  <Box display="flex" flexDirection="row" alignItems="center" mb={2}>
                    <Image src={FactoryFull} width={32} />
                    <Box display="flex" flexDirection="column" ml={2}>
                      <Text>Smart Blood Analytics Swiss SA</Text>
                      <Text>
                        Höschgasse 25, 8008 Zürich, <Trans>Swiss</Trans>
                      </Text>
                    </Box>
                  </Box>
                  {udiObj.isValid && (
                    <Box display="flex" flexDirection="row" alignItems="center">
                      <Image src={FactoryEmpty} width={32} />
                      <Box display="flex" flexDirection="column" ml={2}>
                        <Text>{format(parse(udiObj.productId.date, 'yyMMdd', new Date()), 'yyyy-MM-dd')}</Text>
                      </Box>
                    </Box>
                  )}
                </Box>
                <Box display="flex" flexDirection="column" flex={2} sx={{ fontSize: 13 }}>
                  <Text mb={1} textAlign="center">
                    <Trans>Blood test results interpretation using artificial intelligence</Trans> •{' '}
                    {reportDetails.result?.info?.product?.name} • {udiObj.productId.version}
                  </Text>
                  {udiObj.isValid && (
                    <Box display="flex" flexDirection="row" alignItems="center" justifyContent="center">
                      <Box mr={1}>
                        <Image src={IconUDI} width={28} />
                      </Box>
                      <Text variant="text" mb={'3px'}>
                        {udiString}
                      </Text>
                    </Box>
                  )}
                  <Box display="flex" flexDirection="row" justifyContent="center" alignItems="center" mb={1}>
                    <Image src={IconCI} height={16} ml={1} mr={'3px'} />
                    <Text fontSize={11}>www.smartbloodanalytics.com</Text>
                  </Box>
                </Box>
                <Box display="flex" flexDirection="column" flex={1}>
                  <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center" mb={2}>
                    <Image src={IconEcRep} height={31} mr={1} />
                    <Box sx={{ fontSize: 11 }}>
                      <Text>Smart Blood Analytics d.o.o.</Text>
                      <Text>Župančičeva ulica 18,</Text>
                      <Text>
                        1000 Ljubljana, <Trans>Slovenia</Trans>
                      </Text>
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center" mb={1}>
                    <Compliance data={reportDetails.result?.info?.product?.compliance} />
                  </Box>
                </Box>
              </Box>
              <Text mb={1} textAlign="center" color="text" sx={{ fontSize: 13 }}>
                © {new Date().getFullYear()} Smart Blood Analytics Swiss SA. <Trans>All Rights Reserved</Trans>.
              </Text>
            </>
          )}
        </Flex>
      </ReportIcd.Provider>
    );
  }
};

export default withRouter(Report);

const ReportIcd = createContext();
export const useReportIcd = () => useContext(ReportIcd);

function Compliance(props) {
  const compliances = (props.data || []).reduce((pv, cv) => {
    pv[cv.label] = true;
    return pv;
  }, {});

  if (compliances['CE 1304 MD']) {
    return <Image src={IconCD1304} height={22} />;
  }

  if (compliances['CE IVD']) {
    return (
      <>
        <Image src={IconCE} height={16} />
        <Image src={IconIVD} height={22} ml={1} />
      </>
    );
  }

  return null;
}
