import { Fragment, useState } from 'react';
import { Box, Heading } from 'rebass';
import Parameter from './Parameter';
import { GoChevronDown } from 'react-icons/go';
import useGlobal from 'hooks/global';
import { withRouter } from 'react-router-dom';
import { getBloodTestParameters } from 'api';
import ConfirmationModal from 'components/common/ConfirmationModal';
import { Trans } from '@lingui/macro';
import { useErrorDialog } from 'components/errorDialog/ErrorDialog';

const ParameterGroup = ({ match, name, savedParameters, isLocked }) => {
  const { showError } = useErrorDialog();
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal();
  const [viewGroup, handleToggle] = useState(true);
  const [loading, handleLoading] = useState(false);

  const toggleView = async () => {
    handleToggle(!viewGroup);
    handleLoading(true);

    if (!viewGroup) {
      try {
        const res = await getBloodTestParameters(match.params.id, match.params.bloodTestId);
        globalActions.setSavedParameters(res);
      } catch (e) {
        showError(e);
        console.log(e);
      } finally {
        handleLoading(false);
      }
    }
  };

  const [deleteParam, setDeleteParam] = useState({
    onConfirm: undefined,
  });

  return (
    <Fragment>
      {name !== 'All' && (
        <Box key="group" variant="bloodTest.parametersGroup" onClick={() => toggleView()}>
          <Heading color="primary" fontWeight={400} sx={{ textTransform: 'capitalize' }}>
            {name}
          </Heading>

          {!viewGroup && (
            <Box color="primary" pt={1} fontSize={24} style={{ marginTop: -27, float: 'right' }}>
              <GoChevronDown />
            </Box>
          )}
        </Box>
      )}
      {!loading && viewGroup && (
        <>
          {!isLocked && (
            <ConfirmationModal
              open={deleteParam.onConfirm != null}
              handleConfirm={deleteParam.onConfirm}
              handleClose={() => setDeleteParam({ isOpen: false, onConfirm: undefined })}
              message={<Trans>Do you really want to delete this parameter?</Trans>}
            />
          )}
          {savedParameters.map((savedParameter, index) => (
            <Parameter
              onDelete={setDeleteParam}
              isLocked={isLocked}
              key={savedParameter.code}
              savedParameter={savedParameter}
              index={index}
            />
          ))}
        </>
      )}
    </Fragment>
  );
};

export default withRouter(ParameterGroup);
