import { useState } from 'react';
import { Heading, Box, Flex, Text, Image } from 'rebass';
import { Input, Label } from '@rebass/forms';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Logo from 'assets/sba-logo.svg';
import SbaGraph from 'assets/sba-graph-2.png';

import { renderError } from 'utils';
import { activateUser } from 'api';
import Button from 'components/common/Button';
import Message from 'components/common/Message';

const ActivateUser = ({ match, history }) => {
  const [isLoading, handleIsLoading] = useState(false);
  const [error, handleOnError] = useState('');
  const [success, handleOnSuccess] = useState('');
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();

  const onSubmit = async (data) => {
    handleIsLoading(true);
    handleOnError('');

    try {
      await activateUser({ ...data, invite_token: match.params.token });
      handleOnSuccess('Account created successfully! You will be redirected to the sign in screen in 5 seconds.');
      setTimeout(() => history.push('/login'), 5000);
    } catch (e) {
      handleOnError(renderError(e));
    } finally {
      setTimeout(() => handleIsLoading(false), 1000);
    }
  };

  return (
    <Flex
      variant="container"
      alignItems="center"
      justifyContent="flex-start"
      width="100%"
      sx={{ height: '100vh' }}
      bg="lightgray"
      maxWidth="100%"
    >
      <Image m={5} src={Logo} alignSelf="center" width={300} />
      <Box variant="card">
        <Heading as="h3" color="primary" pb={1} textAlign="center">
          Set your password
        </Heading>
        <Text textAlign="center" color="text" p={3}>
          and create your account
        </Text>
        <Text my={3} color="text">
          The password must be at least 10 characters long, not entirely numeric or too common.
        </Text>
        <Box as="form" mt={3} onSubmit={handleSubmit(onSubmit)}>
          <Heading color="text" mb={3}></Heading>
          <Label>Password</Label>
          <Input
            className={errors.password ? 'error' : ''}
            type="password"
            {...register('password', { required: true })}
          />
          {errors.password && (
            <Text py={2} fontSize={1} color="orange">
              New password is required
            </Text>
          )}
          <Label>Repeat password</Label>
          <Input
            className={errors.password_repeat ? 'error' : ''}
            type="password"
            {...register('password_repeat', {
              validate: {
                required: (value) => value !== '',
                equal: (value) => value === getValues().password,
              },
            })}
          />
          {errors.password_repeat && errors.password_repeat.type === 'required' && (
            <Text py={2} fontSize={1} color="orange">
              New password is required
            </Text>
          )}
          {errors.password_repeat && errors.password_repeat.type === 'equal' && (
            <Text py={2} fontSize={1} color="orange">
              Passwords don't match
            </Text>
          )}
          <Flex justifyContent="flex-end">
            <Button type="submit" mt={4} mb={4} isLoading={isLoading} disabled={success !== ''}>
              Create account
            </Button>
          </Flex>
          {error && <Message type="error" message={error} />}
          {success && <Message type="ok" message={success} />}
        </Box>
      </Box>
      <Flex justifyContent="center" p={4}>
        <Image m={4} width={['80%', '50%', '30%']} alignSelf="center" src={SbaGraph} />
      </Flex>
    </Flex>
  );
};

export default withRouter(ActivateUser);
