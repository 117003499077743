import BloodTest from 'components/BloodTest';
import DateFormat from 'components/common/DateFormat';
import { Flex, Box, Heading, Text } from 'rebass';
import { Trans } from '@lingui/macro';
import { ageAtTimeOfTest } from 'utils';
import { GoLock } from 'react-icons/go';
import { FaAngleUp, FaRegListAlt } from 'react-icons/fa';
import { useState } from 'react';

export default function ReportsHeader({ bloodTest, patient }) {
  const [viewParams, handleParams] = useState(false);

  if (!bloodTest || !patient) {
    return null;
  }

  return (
    <>
      <Flex
        sx={{
          borderBottom: viewParams ? '1px solid' : 'none',
          borderColor: 'lightgray',
          paddingBottom: viewParams ? 3 : 0,
          marginBottom: viewParams ? 3 : 0,
        }}
      >
        <Box width={1 / 2} sx={{ textAlign: 'left' }}>
          <Heading color="primary" fontWeight={400}>
            {bloodTest.name}{' '}
            {bloodTest.status === 'completed' && (
              <Box color="darkgray" sx={{ display: 'inline-block', lineHeight: '22px', verticalAlign: 'bottom' }}>
                <GoLock />
              </Box>
            )}
            <Text fontWeight={400} pt={2} fontSize={16} color="text">
              <Trans>Test date:</Trans> <DateFormat value={bloodTest.blood_test_date} /> -{' '}
              <Trans>Patient age at the time of test:</Trans>{' '}
              {ageAtTimeOfTest(patient.birth_year, patient.birth_date, bloodTest.blood_test_date)} <Trans>years</Trans>
            </Text>
          </Heading>
        </Box>
        <Text
          onClick={() => handleParams(!viewParams)}
          mt={2}
          width={1 / 2}
          textAlign="right"
          variant="clickable"
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          {!viewParams ? <Trans>View Blood Test</Trans> : <Trans>Hide Blood Test</Trans>}
          {!viewParams && <FaRegListAlt size="1.6em" style={{ marginLeft: '8px' }} />}
          {viewParams && <FaAngleUp size="1.6em" style={{ marginLeft: '8px' }} />}
        </Text>
      </Flex>
      {viewParams && (
        <Box mb={4}>
          <BloodTest compact={true} />
        </Box>
      )}
    </>
  );
}
