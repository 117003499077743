import { Flex, Box, Text } from 'rebass';
import { GoChevronDown } from 'react-icons/go';
import { Link } from 'react-router-dom';
import { Trans } from '@lingui/macro';

import useGlobal from 'hooks/global';
import { removeJwt } from 'utils/localstorage';
import { FaCogs, FaSignOutAlt } from 'react-icons/fa';
import { Menu, MenuItem } from '@szhsin/react-menu';

const Dropdown = ({ user }) => {
  const [, globalActions] = useGlobal();

  const handleLogout = () => {
    // Note: if you don't clear the global state here, check ProtectedRoute back button
    removeJwt();
    globalActions.reset();
  };

  return (
    <Menu
      align="end"
      direction="bottom"
      css={{ margin: 0 }}
      gap={10}
      menuStyle={{ padding: 0, borderRadius: '8px', overflow: 'hidden' }}
      menuButton={({ open }) => (
        <Flex sx={{ cursor: 'pointer' }}>
          <Box pr={1} fontSize={3}>
            <Text color="primary">
              {user.first_name} {user.last_name}
            </Text>
            <UsersPlan user={user} />
          </Box>
          <Box color={open ? 'primary' : 'darkgray'} sx={{ marginTop: '-4px' }}>
            <GoChevronDown size="2em" />
          </Box>
        </Flex>
      )}
    >
      <MenuItem style={{ minWidth: '180px' }}>
        <Link
          style={{ textDecoration: 'none', width: '100%', height: '100%', padding: '16px 0', display: 'block' }}
          to="/settings/profile"
        >
          <Flex justifyContent={'center'} sx={{ alignItems: 'center' }}>
            <FaCogs style={{ margin: '0 2ch' }} />
            <div style={{ textAlign: 'left', flexGrow: 1 }}>
              <Trans>Settings</Trans>
            </div>
          </Flex>
        </Link>
      </MenuItem>
      <MenuItem>
        <Link
          onClick={() => handleLogout()}
          style={{
            textDecoration: 'none',
            width: '100%',
            height: '100%',
            padding: '16px 0',
            display: 'block',
            borderTop: '1px solid #edeff3',
          }}
          to="/login"
        >
          <Flex justifyContent={'center'} sx={{ alignItems: 'center' }}>
            <FaSignOutAlt style={{ margin: '0 2ch' }} />
            <div style={{ textAlign: 'left', flexGrow: 1 }}>
              <Trans>Logout</Trans>
            </div>
          </Flex>
        </Link>
      </MenuItem>
    </Menu>
  );
};

export default Dropdown;

function UsersPlan({ user }) {
  const subscription = getSubscriptionStatusFromUser(user);
  const available_billing_events = getSubscriptionBillingEvents(subscription, 'N/A');

  const planName = subscription?.plan_name || 'N/A';
  const isContract = planName.toString().toLowerCase() === 'contract';

  return (
    <Text fontSize={1} sx={{ opacity: 0.4 }}>
      Plan: <span style={{ fontWeight: 'bold' }}> {planName}</span>
      {!isContract && (
        <>
          <span> / </span>
          <span>
            <Trans>Remaining billing events</Trans>: {available_billing_events}
          </span>
        </>
      )}
    </Text>
  );
}

export function getOrganizationFromUser(user) {
  const org = (user.organisations || []).find((or) => or.is_default)?.organisation;
  // org.subscription.status = 'test';

  return org;
}

export function getSubscriptionStatusFromUser(user) {
  const defaultSubscription = getOrganizationFromUser(user)?.subscription;

  if (defaultSubscription?.status !== 'active') {
    return null;
  }

  return defaultSubscription;
}

export function getSubscriptionBillingEvents(subscription, coalesceValue) {
  return subscription?.num_total_billing_events != null && subscription?.num_used_billing_events != null
    ? (subscription?.num_total_billing_events || 0) - (subscription?.num_used_billing_events || 0)
    : coalesceValue;
}
