import { Button, Box } from 'rebass';
import Loader, { LoaderSmall } from 'components/common/Loader';

const ButtonWithLoader = (props) => (
  <Button sx={{ display: 'flex', alignItems: 'center' }} {...props} disabled={props.isLoading || props.disabled}>
    {props.isLoading && (
      <Box pr={3}>
        <Loader />
      </Box>
    )}
    <Box>{props.children}</Box>
  </Button>
);

export const ButtonWide = (props) => (
  <Button
    {...props}
    disabled={props.isLoading || props.disabled}
    sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
  >
    {props.isLoading && <LoaderSmall />}
    {!props.isLoading && props.children}
  </Button>
);

export default ButtonWithLoader;
