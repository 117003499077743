import { Fragment } from 'react';
import { Flex } from 'rebass';
import { FaArrowRight } from 'react-icons/fa';

const TableRow = ({
  reportResult,
  handleClickedId,
  hoveredId,
  handleMouseEnter,
  handleMouseLeave,
  svgGraphType,
  isSelected,
  colorize,
  visualizationColor,
}) => {
  const setSelection = () => {
    handleClickedId(reportResult.code || null);
  };

  return (
    <Fragment>
      <Flex
        onMouseEnter={svgGraphType === 'binary' ? undefined : () => handleMouseEnter(reportResult.code)}
        onMouseLeave={svgGraphType === 'binary' ? undefined : () => handleMouseLeave(reportResult.code)}
        onClick={svgGraphType === 'binary' ? undefined : setSelection}
        bg={colorize ? '#f4f8fb' : 'white'}
        sx={{
          // borderTop: '1px solid',
          borderColor: 'gray',
          cursor: svgGraphType === 'binary' ? undefined : 'pointer',
          boxShadow: hoveredId === reportResult.code ? '0px 2px 6px #333' : 'none',
          outline: hoveredId === reportResult.code ? '1px solid #333' : 'none',
          zIndex: hoveredId === reportResult.code ? '99' : 'auto',
        }}
      >
        <Flex width="10%" sx={{ minHeight: 48 }} bg={visualizationColor} justifyContent="center">
          <Flex alignItems="center" color="white" height="100%" sx={{ filter: 'drop-shadow(1px 1px 2px #000)' }}>
            {svgGraphType === 'binary' ? null : <>{isSelected ? <FaArrowRight /> : null}</>}
          </Flex>
        </Flex>
        <Flex
          alignItems="center"
          justifyContent="center"
          p={2}
          width={1 / 10}
          {...(svgGraphType === 'binary' ? { fontSize: '13px' } : {})}
        >
          {reportResult.code}
        </Flex>
        {svgGraphType !== 'binary' && (
          <>
            <Flex alignItems="center" justifyContent="center" py={2} width={1.25 / 10}>
              {reportResult.ranking_factor}
            </Flex>
            <Flex alignItems="center" justifyContent="center" py={2} width={1.25 / 10}>
              {reportResult.parameter_evidence}
            </Flex>
          </>
        )}
        <Flex
          alignItems="center"
          fontSize="13px"
          sx={{ textAlign: 'left' }}
          p={2}
          width={svgGraphType !== 'binary' ? 5 / 10 : 8 / 10}
        >
          {reportResult.description}
        </Flex>
      </Flex>
    </Fragment>
  );
};

export default TableRow;
