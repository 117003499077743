import { Flex, Box, Text } from 'rebass';

import TableRow from './TableRow';
import { Trans } from '@lingui/macro';

const Table = ({
  reportDetails,
  handleClickedId,
  selectedId,
  hoveredId,
  handleMouseEnter,
  handleMouseLeave,
  svgGraphType,
}) => {
  return (
    <Flex
      mr={2}
      ml={1}
      fontSize="16px"
      flexDirection="column"
      sx={{ border: '1px solid', borderColor: 'gray', borderRadius: 4, flexGrow: 1 }}
    >
      <Flex fontSize="14px">
        <Box width="10%" />

        <Flex alignItems="center" justifyContent="center" p={2} width={1 / 10}>
          <Text fontWeight="bold" color="text">
            {svgGraphType === 'binary' ? <Trans>Code</Trans> : <Trans>ICD</Trans>}
          </Text>
        </Flex>
        {svgGraphType !== 'binary' && (
          <>
            <Flex alignItems="center" justifyContent="center" py={2} width={1.25 / 10}>
              <Text fontWeight="bold" color="text" sx={{ wordSpacing: '100000px' }}>
                <Trans>Ranking Factor</Trans>
              </Text>
            </Flex>
            <Flex alignItems="center" justifyContent="center" py={2} width={1.25 / 10}>
              <Text fontWeight="bold" color="text" sx={{ wordSpacing: '100000px' }}>
                <Trans>Parameter Evidence</Trans>
              </Text>
            </Flex>
          </>
        )}

        <Flex alignItems="center" p={2} width={svgGraphType !== 'binary' ? 5 / 10 : 8 / 10} sx={{ textAlign: 'left' }}>
          <Text fontWeight="bold" color="text">
            <Trans>Description</Trans>
          </Text>
        </Flex>
      </Flex>

      {reportDetails?.result?.results?.map((val, i) => (
        <TableRow
          svgGraphType={svgGraphType}
          key={i}
          reportResult={val}
          handleClickedId={handleClickedId}
          hoveredId={hoveredId}
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
          isSelected={val.code === selectedId}
          colorize={i % 2 === 0}
          visualizationColor={reportDetails?.result?.visualization?.legend?.[i]?.color}
        />
      ))}
    </Flex>
  );
};

export default Table;
