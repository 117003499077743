import { Box, Flex } from 'rebass';
import { FaExclamation } from 'react-icons/fa';

const style = {
  width: '32px',
  height: '30px',
  fontSize: 18,
  p: 1,
  color: 'white',
  textAlign: 'center',
};

const ValueWarning = ({ value, min, max }) => {
  if (value === '' || value === null || (value != null && !/^[0-9]+([,.][0-9]+)?$/.test(value))) {
    return (
      <Flex bg="red" sx={style} fontSize={16} alignItems="center" justifyContent="center">
        <FaExclamation />
      </Flex>
    );
  }

  if (min === max && min !== Number(value)) {
    return (
      <Box bg="orange" sx={style}>
        H
      </Box>
    );
  }

  if (max && value > max) {
    return (
      <Box bg="orange" sx={style}>
        H
      </Box>
    );
  }

  if (min && value < min) {
    return (
      <Box bg="orange" sx={style}>
        L
      </Box>
    );
  }

  return null;
};

export default ValueWarning;
