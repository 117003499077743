import { useState } from 'react';
import { Header } from './related/header/header';
import Interval from './related/interval/Interval';
import Products from './related/products/Products';
import { ExistingBilling } from './related/existingBilling/ExistingBilling';

export function PricingTable({ data }) {
  const [interval, setInterval] = useState('month');
  const products = (data || [])
    .reduce((pv, product) => {
      const productPrice = product.prices.find((price) => price.recurring?.interval === interval);

      if (productPrice) {
        product._price = productPrice;
        pv.push(product);
      }

      return pv;
    }, [])
    .reverse();

  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', maxWidth: '1000px', margin: 'auto' }}>
      <Header />
      <ExistingBilling />
      <Interval selected={interval} setSelected={(newValue) => setInterval(newValue)} />

      <Products products={products} />
    </div>
  );
}
