import { Flex } from 'rebass';
import { Textarea } from '@rebass/forms';

const TextareaWrap = ({ className, name, register, registerProps, rows, value, onChange, defaultValue }) => {
  const reg = register(name, registerProps);

  return (
    <Flex variant="textareaWrap">
      <Textarea
        className={className}
        name={name}
        rows={rows || 4}
        value={value}
        ref={reg.ref}
        onChange={(e) => {
          reg.onChange(e);
          onChange?.(e);
        }}
        defaultValue={defaultValue}
      />
    </Flex>
  );
};

export default TextareaWrap;
