import { Trans } from '@lingui/macro';
import { differenceInYears } from 'date-fns';

export const renderError = (e) => {
  if (Array.isArray(e.email) && e.email.length === 1 && e.email[0] === 'user with this email already exists.') {
    return <div>An account with this e-mail already exists.</div>;
  }

  if (typeof e === 'object') {
    const filter = ['details', 'messages'];
    const errorKeys = Object.keys(e); // {password: ["", ""]} => [password]

    let messages = [];
    errorKeys.forEach((ek) => {
      if (!filter.includes(ek)) {
        const message = e[ek];

        if (Array.isArray(message)) {
          messages = [...messages, ...message];
        } else {
          messages.push(message);
        }
      }
    });

    return (
      <ul
        style={{
          margin: 0,
          padding: '10px',
          textAlign: messages.length > 1 ? 'left' : 'center',
          listStyleType: messages.length > 1 ? 'disc' : 'none',
        }}
      >
        {messages.map((msg, i) => {
          return <li key={i}>{msg}</li>;
        })}
      </ul>
    );
  } else {
    return <div>{e}</div>;
  }
};

export const RenderGender = ({ gender }) => {
  if (gender === undefined) return null;

  return gender === 'M' ? <Trans>male</Trans> : <Trans>female</Trans>;
};

export const getGender = (gender, lang) => {
  if (gender === undefined) return null;

  const genderName = {
    en: {
      M: 'male',
      F: 'female',
    },
    sl: {
      M: 'moški',
      F: 'ženska',
    },
  };

  return genderName[lang][gender];
};

export const calculateAge = (birth_year, birth_date, age_on_date) => {
  const compareDate = age_on_date ? new Date(age_on_date) : new Date();

  if (birth_date) {
    const birthDateParsed = new Date(birth_date);
    const years = differenceInYears(compareDate, birthDateParsed);
    return `${years}`;
  }

  if (!birth_year) {
    return '/';
  }

  const years = compareDate.getFullYear() - birth_year;
  return `${years}`;
};

export const ageAtTimeOfTest = (birthYear, birthDate, bloodTestDate) => {
  if (!bloodTestDate) {
    return '/';
  }

  const bloodTestYear = new Date(bloodTestDate).getFullYear();

  let age = 0;

  if (birthYear) {
    age = bloodTestYear - birthYear;
  } else if (birthDate) {
    age = bloodTestYear - new Date(birthDate).getFullYear();
  }

  if (age > 0) {
    return `${age}`;
  }

  return '/';
};

/**
 * Destructs a UDI string and returns a UDI object.
 * Example of a valid UDI string: '(01)7649996089014(11)210701(8012)V1.2.1'
 * @param {string} udiString - medical device UDI string.
 */
export const parseUDI = (udiString) => {
  if (!udiString || typeof udiString !== 'string') throw new Error('Missing UDI string.');
  if (typeof udiString !== 'string') throw new Error('Input is not of type string.');
  const udiArray = [];
  let c = 0;
  udiString
    .split(/([()])/)
    .filter(Boolean)
    .forEach((e) => (e === '(' ? c++ : e === ')' ? c-- : udiArray.push(e)));

  if (udiArray.length !== 6) {
    return {
      isValid: false,
      productId: {
        date: '',
        version: udiString,
      },
    };
  }

  return {
    isValid: true,
    productId: {
      date: udiArray[3],
      version: udiArray[5],
    },
  };
};

export const downloadJsonFile = ({ filename, content }) => {
  const body = document.body;
  const a = document.createElement('a');
  a.href = URL.createObjectURL(
    new Blob([JSON.stringify(content, null, 2)], {
      type: 'text/plain',
    }),
  );
  a.setAttribute('download', filename);
  body.appendChild(a);
  a.click();
  body.removeChild(a);
};

export const pickJsonFile = (onValidJsonFileContent, onInvalidJsonFileContent) => {
  const body = document.body;
  const input = document.createElement('input');
  input.accept = 'application/JSON';
  input.type = 'file';

  input.onchange = (e) => {
    const reader = new FileReader();
    reader.onload = (re) => {
      try {
        let obj = JSON.parse(re.target.result);
        onValidJsonFileContent?.(obj);
      } catch (error) {
        onInvalidJsonFileContent?.(e.target.files[0].name);
      }
    };
    reader.readAsText(e.target.files[0]);
  };

  body.appendChild(input);
  input.click();
  body.removeChild(input);
};
