import orderBy from 'lodash/orderBy';
import Report from './Report';

const Reports = ({ reports, canOpenFirstByDefault }) => {
  const sortedReports = orderBy(reports, 'created', 'desc');
  return sortedReports.map((report, index) => (
    <Report openedByDefault={canOpenFirstByDefault && index === 0} key={report.id} report={report} />
  ));
};

export default Reports;
