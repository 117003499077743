import smoothscroll from 'smoothscroll-polyfill';
import { ThemeProvider } from 'emotion-theming';
import { Router, Switch } from 'react-router-dom';
import theme from './theme.js';

// components
import { ProtectedRoute, PublicRoute } from 'components/common/ProtectedRoute';
import GAPageView from 'components/common/Analytics/GAPageView';

import Login from 'components/Login';
import Signup from 'components/Signup';
import Training from 'components/Training';
import TrainingVideoQuiz from 'components/Training/TrainingVideoQuiz';

import Settings from 'components/Settings';
import AddPatient from 'components/AddPatient';
import Dashboard from 'components/Dashboard';
import Patient from 'components/Patient';
import BloodTestReport from 'components/BloodTestReport';
import ScrollTop from 'components/common/ScrollTop';
import ActivateUser from 'components/ActivateUser';
import ResetPassword from 'components/ResetPassword';
import SetPassword from 'components/ResetPassword/SetPassword';
import Showcase from 'components/common/VideoIntro/Showcase';
import NotFoundPage from 'components/common/NotFoundPage/NotFoundPage';

import './App.css';
import '@szhsin/react-menu/dist/index.css';
import Pricing from 'components/Pricing/index.jsx';
import ErrorDialogProvider from 'components/errorDialog/ErrorDialog.js';
import { EventEmitter } from 'utils/eventEmitter.js';

smoothscroll.polyfill();

export const AppEvt = new EventEmitter();

// Root App container
const App = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <ErrorDialogProvider>
        <Router history={props.history}>
          <ScrollTop>
            <GAPageView />
            <Switch>
              {/* Public routes */}
              <PublicRoute exact path="/login">
                <Login />
              </PublicRoute>
              <PublicRoute exact path="/signup">
                <Signup />
              </PublicRoute>
              <PublicRoute exact path="/signup/:organizationId?">
                <Signup />
              </PublicRoute>
              <PublicRoute exact path="/training">
                <Training />
              </PublicRoute>
              <PublicRoute exact path="/training/:uid/:token">
                <TrainingVideoQuiz />
              </PublicRoute>
              <PublicRoute exact path="/activate-password/:token">
                <ActivateUser />
              </PublicRoute>
              <PublicRoute exact path="/reset-password">
                <ResetPassword />
              </PublicRoute>
              <PublicRoute exact path="/password/reset/confirm/:uid/:token">
                <SetPassword />
              </PublicRoute>
              {/* Protected routes */}
              <ProtectedRoute exact path="/">
                <Dashboard />
              </ProtectedRoute>
              <ProtectedRoute path="/pricing">
                <Pricing />
              </ProtectedRoute>
              <ProtectedRoute path="/add-patient">
                <AddPatient />
              </ProtectedRoute>
              <ProtectedRoute exact path="/patient/:id">
                <Patient />
              </ProtectedRoute>
              <ProtectedRoute exact path="/patient/:id/blood-test/:bloodTestId/:reportId?">
                <BloodTestReport />
              </ProtectedRoute>
              <ProtectedRoute path="/settings/">
                <Settings />
              </ProtectedRoute>
              <ProtectedRoute path="/video/">
                <Showcase />
              </ProtectedRoute>

              <NotFoundPage />
            </Switch>
          </ScrollTop>
        </Router>
      </ErrorDialogProvider>
    </ThemeProvider>
  );
};

export default App;
