import { withRouter, Link } from 'react-router-dom';
import { Box, Flex, Text } from 'rebass';
import { IoIosArrowForward } from 'react-icons/io';
import useGlobal from 'hooks/global';
import { Trans, t } from '@lingui/macro';
import { FaUndoAlt } from 'react-icons/fa';

const styleLink = {
  textDecoration: 'none',
  fontSize: 18,
  color: '#495e85',
};

const styleSpacer = {
  width: 30,
  fontSize: 20,
  opacity: 0.3,
  marginBottom: '-3px',
};

// Patients list > link2 > link3
const BreadcrumbLinks = ({ links, showPatientsList }) => (
  <Flex flexDirection={'row'} py={3} sx={{ borderBottom: '1px solid', borderColor: 'gray' }}>
    <Flex sx={{ flexGrow: '1' }}>
      {links.map((link, index) => [
        <Link key={index} style={styleLink} to={link.path}>
          <Text variant="clickable">{link.name}</Text>
        </Link>,
        index !== links.length - 1 && <IoIosArrowForward key={`spacer-${index}`} style={styleSpacer} />,
      ])}
    </Flex>
    {showPatientsList && (
      <Box>
        <Link style={styleLink} to="/">
          <Text variant="clickable" sx={{ display: 'flex' }}>
            <FaUndoAlt style={{ marginRight: '1ch' }} />
            <Trans>Patients list</Trans>
          </Text>
        </Link>
      </Box>
    )}
  </Flex>
);

const Breadcrumbs = ({ match }) => {
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal();
  const uid = globalState.patient.uid || '';
  const name = globalState.bloodTest.name || '';

  const baseLink = {
    name: t`Patients list`,
    path: '/',
  };

  let links = [];

  switch (match.path) {
    case '/':
      links = [baseLink];
      break;

    case '/pricing':
      links = [{ name: t`Pricing`, path: match.url }];
      break;

    case '/add-patient':
      links = [baseLink, { name: t`Add patient`, path: match.url }];
      break;

    case '/patient/:id':
      links = [baseLink, { name: t`Patient - ${uid}`, path: match.url }];
      break;

    case '/patient/:id/blood-test/:bloodTestId':
    case '/patient/:id/blood-test/:bloodTestId/:reportId?':
      links = [
        baseLink,
        { name: t`Patient - ${uid}`, path: `/patient/${match.params.id}` },
        { name: name, path: match.url },
      ];
      break;

    case '/settings/':
      links = [{ name: t`Settings`, path: match.url }];
      break;

    default:
      break;
  }

  return <BreadcrumbLinks links={links} showPatientsList={match.path === '/settings/'} />;
};

export default withRouter(Breadcrumbs);
