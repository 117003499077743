import { Trans } from '@lingui/macro';
import { Checkbox, Label, Select } from '@rebass/forms';
import { useCallback, useState } from 'react';
import { Box, Flex, Text } from 'rebass';
import ParamContribution from '../ReportParamDescriptionCard/paramContribution';
import { CustomCardSettings } from '../ReportParamDescriptionCard';

export default function ReportParamDescriptionCardBinary({
  disease1,
  disease2,
  explanations,
  allDiseasesMaxContribution,
  userIsStaff,
}) {
  const [showNanParams, setShowNanParams] = useState(false);
  const [noOfParams, setNoOfParams] = useState(10);

  const onSelectChange = useCallback((el) => {
    setNoOfParams(el.target.value);
  }, []);

  const toggleShowNanParams = useCallback(() => {
    setShowNanParams((prevValue) => !prevValue);
  }, []);

  const contributions = showNanParams ? explanations : explanations.filter((d) => d.value != null);

  return (
    <div>
      <Box style={{ position: 'sticky', top: 0, background: 'linear-gradient(white,white 90%, #ffffffcc)' }}>
        <Flex p={2} pt={0}>
          <Text textAlign={'right'} mr={2} width="50%" sx={{ color: disease1.color }}>
            {disease1.name}
          </Text>
          <Flex ml={2} width="50%">
            <Text flexGrow={1} textAlign={'left'} sx={{ color: disease2.color }}>
              {disease2.name}
            </Text>

            {userIsStaff && (
              <CustomCardSettings>
                <Label htmlFor="no_of_paramas">
                  <Trans>Parameters:</Trans>
                </Label>
                <Select
                  name="no_of_paramas"
                  value={noOfParams}
                  style={{
                    padding: 3,
                  }}
                  onChange={onSelectChange}
                >
                  {[5, 10, 20, 40, 80].map((value) => (
                    <option key={value}>{value}</option>
                  ))}
                  <option value={Number.MAX_VALUE}>∞</option>
                </Select>

                <Label alignItems={'center'} width="auto">
                  <Checkbox name="showNanParams" checked={showNanParams} onChange={toggleShowNanParams} />
                  <Trans>Show not measured parameters</Trans>
                </Label>
              </CustomCardSettings>
            )}
          </Flex>
        </Flex>
      </Box>
      {contributions.slice(0, noOfParams).map((p, i) => (
        <ParamContribution
          param={p}
          key={i}
          maxContribution={allDiseasesMaxContribution}
          alternativeColor1={disease1.color}
          alternativeColor2={disease2.color}
        />
      ))}
    </div>
  );
}
