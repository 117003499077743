import MicroModal from 'react-micro-modal';
import 'react-micro-modal/dist/index.css';
import { Flex, Text, Heading } from 'rebass';
import { Trans } from '@lingui/macro';
import Button from 'components/common/Button';
import { Link } from 'react-router-dom';
import { FaExternalLinkAlt } from 'react-icons/fa';

const NoRemaininEventsModal = ({ open, handleClose }) => {
  return (
    <MicroModal
      open={open}
      handleClose={handleClose}
      // eslint-disable-next-line react/no-children-prop
      children={(handleCloseUncontrolled) => (
        <Flex width={400} flexDirection={'column'}>
          <Heading mb={3} color="text">
            <Trans>You have reached your billing events quota.</Trans>
          </Heading>
          <Text color="text" mb={4}>
            <Trans>You do not have any remaining billing events. Please upgrade your subscription plan.</Trans>
          </Text>
          <Flex justifyContent="space-between" alignItems={'center'}>
            <div>
              <Link to="/pricing">
                <div style={{ display: 'flex', gap: '0.5ch' }}>
                  <FaExternalLinkAlt />
                  <Trans>Go to pricing</Trans>
                </div>
              </Link>
            </div>
            <Button onClick={handleClose ? handleClose : handleCloseUncontrolled}>
              <Trans>Close</Trans>
            </Button>
          </Flex>
        </Flex>
      )}
    />
  );
};

export default NoRemaininEventsModal;
