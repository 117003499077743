import { createStripeCheckout } from 'api';
import styles from './Products.module.css';
import { useState } from 'react';
import Loader from 'components/common/Loader';
import { Button, Flex } from 'rebass';
import { useErrorDialog } from 'components/errorDialog/ErrorDialog';

export default function Products({ products }) {
  const { showError } = useErrorDialog();
  const [loadingPriceId, setLoadingPriceId] = useState(undefined);

  const goToCheckout = async (priceId) => {
    try {
      setLoadingPriceId(priceId);
      const { url } = await createStripeCheckout(priceId);
      window.location.href = url;
    } catch (e) {
      console.log(e);
      showError(e);
    }
  };

  return (
    <div className={styles.container}>
      {products.map((p, i) => (
        <Product key={i} product={p} goToCheckout={goToCheckout} loadingPriceId={loadingPriceId} />
      ))}
    </div>
  );
}

function Product({ product, goToCheckout, loadingPriceId }) {
  const productPrice = product._price;

  if (!productPrice) {
    return null;
  }

  const goCheckout = async () => {
    goToCheckout(productPrice.id);
  };

  return (
    <div className={styles.productContainer}>
      <div className={styles.productNameContainer}>
        <div className={styles.productName}>{product.name}</div>
      </div>
      <p className={styles.productDescription}>{product.description}</p>
      <p className={StyleSheet.productPriceContainer}>
        <span className={styles.productPriceValue}>
          <span className={styles.productPriceCurrency}>{productPrice.currency}</span> {productPrice.unit_amount / 100}
        </span>
        &nbsp;
        <span className={styles.productPriceInterval}>/{productPrice.recurring?.interval}</span>
      </p>
      <Button
        // className={styles.productBuyButton}
        onClick={goCheckout}
        disabled={loadingPriceId != null}
        variant="orange"
      >
        <Flex alignItems="center" justifyContent="space-between" sx={{ gap: '2ch' }}>
          {loadingPriceId === productPrice.id && <Loader />}
          {product.type === 'service' ? 'Subscribe' : 'Buy'}
        </Flex>
      </Button>

      {/* <p className={styles.productDescription}>{productPrice.nickname}</p> */}
      <div className={styles.productDescription}>
        <ProductDescription meta={product.metadata} />
      </div>
    </div>
  );
}

function ProductDescription({ meta }) {
  const getHumanReadable = (key, value) => {
    switch (key) {
      case 'num_billing_events':
        return `${value} billing events*`;

      case 'subscription_term': {
        switch (value) {
          case 'annual':
            return 'Annual licence';

          case 'monthly':
            return 'Cancel anytime';

          default:
            return `${key}: ${value}`;
        }
      }

      default:
        return `${key}: ${value}`;
    }
  };

  const items = Object.entries(meta).reduce(
    (pv, [mKey, mValue]) => {
      pv.push({ key: mKey, label: getHumanReadable(mKey, mValue) });

      if (mKey === 'subscription_term') {
        switch (mValue) {
          case 'annual':
            pv.push({ key: '_duration', label: 'Duration 1 year' });
            break;

          case 'monthly':
            pv.push({ key: '_duration', label: 'Duration 1 month' });
            break;
        }
      }

      return pv;
    },
    [
      { key: 'fullFunctionality', label: 'Full functionality access to the web app' },
      { key: 'mnualOrAuto', label: 'Manual blood parameter input or automatic upload where available' },
      { key: 'videoTraining', label: 'Video training' },
      { key: 'customerSupport', label: 'Customer support' },
    ],
  );

  return (
    <ul role="list" className={styles.productMetaContainer}>
      {items.map((i) => (
        <ProductDescriptionItem key={i.key} label={i.label} />
      ))}
    </ul>
  );
}

function ProductDescriptionItem({ label }) {
  return (
    <li className={styles.productMetaItem}>
      <ProductMetaItemBullet /> {label}
    </li>
  );
}

function ProductMetaItemBullet() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      className={styles.productMetaItemBullet}
    >
      <path
        fillRule="evenodd"
        d="M16.704 4.153a.75.75 0 01.143 1.052l-8 10.5a.75.75 0 01-1.127.075l-4.5-4.5a.75.75 0 011.06-1.06l3.894 3.893 7.48-9.817a.75.75 0 011.05-.143z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}
