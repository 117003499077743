import { Trans } from '@lingui/macro';
import MicroModal from 'react-micro-modal';
import { Box, Button, Flex, Heading, Image, Text } from 'rebass';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import bugImageSrc from './bugImage.svg';
import { createContext, useCallback, useState, useContext } from 'react';
import { IoMdClose, IoMdRefresh } from 'react-icons/io';

const DialogContext = createContext();

export const useErrorDialog = () => {
  return useContext(DialogContext);
};

export default function ErrorDialogProvider({ children }) {
  const [state, setState] = useState({
    message: undefined,
    shown: false,
  });

  const showError = useCallback((msg) => {
    let message = 'Unknown error';

    try {
      message = typeof msg === 'object' ? JSON.stringify(msg) : msg?.toString?.();
    } catch (_error) {
      // do nothing, leave the default message
    }

    setState({
      message,
      shown: true,
    });
  }, []);

  return (
    <DialogContext.Provider value={{ showError }}>
      <MicroModal
        openInitially={false}
        open={state.shown}
        overrides={{
          Dialog: {
            style: { maxWidth: '800px', width: '100%' },
          },
        }}
        // eslint-disable-next-line react/no-children-prop
        children={() => (
          <I18nProvider i18n={i18n}>
            <Flex flexDirection={'column'} alignItems={'center'} sx={{ color: '#495e85' }}>
              <Image mb={3} p={3} src={bugImageSrc} maxWidth={'200px'} />
              <Heading mt={2}>
                <Trans>Something went wrong</Trans>
              </Heading>
              <Text my={2}>
                <Trans>
                  If the problem persists, please contact us at{' '}
                  <a href="mailto:support@sba-swiss.com">support@sba-swiss.com</a>.
                </Trans>
              </Text>

              {process.env.NODE_ENV === 'development' && (
                <Box
                  mx={2}
                  my={2}
                  p={2}
                  sx={{
                    border: '1px solid #edeff3',
                    textAlign: 'center',
                    maxHeight: '130px',
                    overflow: 'scroll',
                    borderRadius: '6px',
                    minWidth: '100%',
                    minHeight: '100px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <>{state.message}</>
                </Box>
              )}

              <Flex justifyContent={'space-between'} mt={2}>
                <Button
                  mx={2}
                  onClick={() => {
                    window.location.reload();
                  }}
                  variant="orange"
                >
                  <Flex alignItems={'center'}>
                    <IoMdRefresh />
                    &nbsp;
                    <Trans>Reload page</Trans>
                  </Flex>
                </Button>

                <Button
                  mx={2}
                  onClick={() => {
                    setState({
                      message: undefined,
                      shown: false,
                    });
                  }}
                >
                  <Flex alignItems={'center'}>
                    <IoMdClose />
                    &nbsp;
                    <Trans>Close</Trans>
                  </Flex>
                </Button>
              </Flex>
            </Flex>
          </I18nProvider>
        )}
      />
      {children}
    </DialogContext.Provider>
  );
}
