import { Trans } from '@lingui/macro';
import { FaDownload } from 'react-icons/fa';
import { Box, Flex, Heading, Text } from 'rebass';
import DateFormat from 'components/common/DateFormat';
import useGlobal from 'hooks/global';

export default function Documents() {
  const [{ user }] = useGlobal();

  return (
    <Box p={3} width={[1, 1, 1, 1, 3 / 5]} minHeight={500}>
      <Heading mb={3}>
        <Trans>Documents</Trans>
      </Heading>
      <DocumentsTable documents={user.documents || []} />
    </Box>
  );
}

function DocumentsTable({ documents }) {
  if (documents.length === 0) {
    return <Trans>You have no documents.</Trans>;
  }

  return (
    <>
      <Flex py={2} color="darkergray" sx={{ borderBottom: '1px solid', borderColor: 'gray' }}>
        <Box width={4 / 7}>
          <Trans>Document</Trans>
        </Box>
        <Box width={2 / 7}>
          <Trans>Type</Trans>
        </Box>
        <Box width={1 / 7}>
          <Trans>Created</Trans>
        </Box>
      </Flex>

      {documents.map((doc, docIndex) => (
        <Flex key={docIndex} py={2} sx={{ borderBottom: '1px solid', borderColor: 'gray' }}>
          <Box width={4 / 7}>
            <a href={doc.url} target="_blank" rel="noreferrer" download>
              <Text variant="clickable" alt={doc.description}>
                <FaDownload style={{ verticalAlign: 'text-top' }} /> &nbsp;
                {doc.description}
              </Text>
            </a>
          </Box>
          <Box width={2 / 7}>
            <DocumentType docType={doc.type} />
          </Box>
          <Box width={1 / 7}>
            <DateFormat value={doc.created} />
          </Box>
        </Flex>
      ))}
    </>
  );
}

function DocumentType({ docType }) {
  switch (docType) {
    case 'certificate':
      return <Trans>certificate</Trans>;

    case 'invoice':
      return <Trans>invoice</Trans>;

    case 'contract':
      return <Trans>contract</Trans>;

    default:
      return '/';
  }
}
