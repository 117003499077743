import { useCallback, useEffect, useState } from 'react';
import { Heading, Flex, Box, Text } from 'rebass';
import { withRouter } from 'react-router-dom';
import { FaFileImport, FaUser } from 'react-icons/fa';
import { Trans } from '@lingui/macro';

import { getBloodTests, createBloodTest, deleteBloodTest } from 'api';
import Breadcrumbs from 'components/common/Breadcrumbs';
import PatientInfo from 'components/common/PatientInfo';
import BloodTests from './BloodTests';
import Button from 'components/common/Button';
import Loader from 'components/common/Loader';
import useGlobal from 'hooks/global';
import ImportBloodTestDialog from './ImportBloodTestDialog/ImportBloodTestDialog';
import { useErrorDialog } from 'components/errorDialog/ErrorDialog';

/**
 * @function Patient
 * Show patient related info and blood tests
 * Dispatch createBloodTest on "Add blood test" button press
 * @param  match    match object contains information about how a <Route path> matched the URL.
 * @param  history  session route history and route utility methods
 */
const Patient = ({ match, history }) => {
  const { showError } = useErrorDialog();
  const [isLoadingCreate, handleCreateLoading] = useState(false);

  const [isLoading, handleLoading] = useState(true);
  const [globalState, globalActions] = useGlobal();
  const bloodTests = globalState.bloodTests || [];

  // Create blood test
  const handleCreate = useCallback(async () => {
    try {
      handleCreateLoading(true);
      const { id } = await createBloodTest(match.params.id);
      handleCreateLoading(false);

      history.push(`/patient/${match.params.id}/blood-test/${id}`);
    } catch (e) {
      console.log(e);
      showError(e);
      throw e;
    }
  }, [match.params.id, history, showError]);

  // Delete blood test
  const handleDelete = async (bloodTestId) => {
    try {
      await deleteBloodTest(match.params.id, bloodTestId);
      const bloodTestList = bloodTests.filter((item) => item.id !== bloodTestId);
      globalActions.setBloodTests(bloodTestList);
    } catch (e) {
      console.log(e);
      showError(e);
    }
  };

  useEffect(() => {
    const fetchBloodTests = async () => {
      try {
        handleLoading(true);
        const bloodTests = await getBloodTests(match.params.id);
        globalActions.setBloodTests(bloodTests);

        handleLoading(false);
      } catch (e) {
        console.log(e);
        showError(e);
      }
    };

    fetchBloodTests();
  }, [globalActions, handleCreate, match.params.id, showError]);

  return (
    <Flex variant="container" pb={6}>
      <Breadcrumbs />
      <PatientInfo />
      {isLoading ? (
        <Loader big />
      ) : (
        <Flex pb={3} sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
          <Flex alignItems="center">
            <Box sx={{ display: 'inline-block' }} mr={2} fontSize={4} mt={1} color="text">
              <FaUser />
            </Box>
            <Heading color="text">
              <Trans>Patient's blood tests</Trans>
            </Heading>
          </Flex>
          <Flex>
            {globalState.user.enable_blood_test_import && (
              <ImportBloodTestDialog
                gender={globalState.user}
                showDialog={(openDialog) => (
                  <Button variant="primary" mr={3} onClick={() => openDialog()} disabled={isLoadingCreate}>
                    <FaFileImport style={{ height: '0.8em', marginRight: '1ch' }} />
                    <Trans>Import blood test</Trans>
                  </Button>
                )}
              />
            )}

            <Button variant="orange" onClick={() => handleCreate()} isLoading={isLoadingCreate}>
              <Trans>Add blood test</Trans>
            </Button>
          </Flex>
        </Flex>
      )}
      {!isLoading && bloodTests.length === 0 && (
        <Text color="darkergray" sx={{ borderTop: '1px solid', borderColor: 'gray' }} py={3}>
          <Trans>Add a blood test to generate SBAS reports.</Trans>
        </Text>
      )}
      {!isLoading && <BloodTests bloodTests={bloodTests} handleDelete={handleDelete} />}
    </Flex>
  );
};

export default withRouter(Patient);
