import { useState, useCallback } from 'react';
import { DayPicker } from 'react-day-picker';
import { Box, Text } from 'rebass';
import { Input } from '@rebass/forms';
import { API_DATE_FORMAT } from 'constants/date';
import { dateFormat } from 'components/common/DateFormat';
import { FaTimes } from 'react-icons/fa';
import { format } from 'date-fns';
import './DatePicker.style.css';

import en from 'date-fns/locale/en-GB';
import sl from 'date-fns/locale/sl';

import { parseISO } from 'date-fns';

export default function DatePicker({
  defaultValue,
  over18,
  disableFuture,
  returnDate,
  name,
  register,
  registerProps,
  setFormValue,
  errors,
  supportsDelete,
  onChange,
  maxYears,
}) {
  const [isOpened, setIsOpened] = useState(false);
  const [selectedDate, setSelectedDate] = useState(defaultValue ? parseISO(defaultValue) : null);
  const [selectedDateString, setSelectedDateString] = useState(defaultValue ? dateFormat(defaultValue) : '');

  const lang = localStorage.getItem('lang') || 'en';

  const onDayChange = useCallback(
    (newSelectedDate) => {
      setSelectedDate(newSelectedDate);
      setIsOpened(false);
      setSelectedDateString(newSelectedDate ? dateFormat(newSelectedDate.toISOString()) : '');

      if (newSelectedDate) {
        const formatDate = format(newSelectedDate, API_DATE_FORMAT);
        setFormValue?.(name, formatDate, { shouldValidate: true });
        if (returnDate) {
          returnDate(formatDate);
        }
      } else {
        setFormValue?.(name, undefined, { shouldValidate: true });
        if (returnDate) {
          returnDate(undefined);
        }
      }

      setTimeout(() => onChange?.());
    },
    [name, returnDate, setFormValue, onChange],
  );

  const reg = register?.(name, registerProps);
  const today = new Date();

  return (
    <div>
      {isOpened && ( // overlay
        <Box
          onClick={() => setIsOpened(false)}
          sx={{ position: 'absolute', zIndex: 0, height: '100%', width: '100%', top: 0, left: 0 }}
        />
      )}
      <div style={{ position: 'relative' }}>
        <Input
          className={errors ? 'error' : ''}
          type="text"
          onFocus={() => setIsOpened(true)}
          value={selectedDateString}
          readOnly
          ref={reg?.ref}
        />
        {supportsDelete && selectedDateString && (
          <Text
            color="darkergray"
            fontSize="16px"
            variant="clickable"
            onClick={() => {
              onDayChange(undefined);
            }}
            sx={{ display: 'inline-box', position: 'absolute', margin: '1.2em 0.6em', top: 0, right: 0 }}
          >
            <FaTimes style={{ marginRight: 4 }} />
          </Text>
        )}
      </div>

      {isOpened && (
        <DayPicker
          mode="single"
          selected={selectedDate}
          onSelect={onDayChange}
          defaultMonth={
            selectedDate == null && over18 ? new Date().setFullYear(today.getFullYear() - 18) : selectedDate
          }
          captionLayout="dropdown"
          fromYear={today.getFullYear() - (maxYears ?? 100)}
          toYear={today.getFullYear() - (over18 ? 18 : 0)}
          locale={lang === 'en' ? en : sl}
          disabled={[
            disableFuture ? { after: new Date() } : {},
            over18 ? { after: new Date().setFullYear(today.getFullYear() - 18) } : {},
          ]}
        />
      )}
    </div>
  );
}
