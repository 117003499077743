import { useEffect, useState } from 'react';
import { LoaderSmall } from '../Loader';
import { getCountries } from 'api';
import { Flex, Text } from 'rebass';
import { useErrorDialog } from 'components/errorDialog/ErrorDialog';

const SharedData = {};

export function getSharedData(dataKey) {
  return SharedData[dataKey];
}

function LoadWrapper({ children }) {
  return (
    <Flex my={6} flexDirection={'column'} justifyItems={'center'} alignItems={'center'}>
      {children}
      <Text color="text" my={2}>
        Loading...
      </Text>
    </Flex>
  );
}

export function RequiredData({ dataKeys, loaderIsDark, loaderIsVeryBig, children }) {
  const { showError } = useErrorDialog();
  const [state, setState] = useState('loading');

  useEffect(() => {
    const fetchData = async () => {
      try {
        for (let index = 0; index < dataKeys.length; index++) {
          const dKey = dataKeys[index];

          if (!SharedData[dKey]) {
            switch (dKey) {
              case 'COUNTRIES':
                {
                  const countries = await getCountries();
                  SharedData[dKey] = countries.sort((a, b) => a.name.localeCompare(b.name));
                }
                break;

              default:
                console.error('Unknown SharedData key!');
                showError('Unknown SharedData key!');
                break;
            }
          }
        }

        setState('loaded');
      } catch (e) {
        showError(e);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (state === 'loading') {
    if (loaderIsVeryBig) {
      return (
        <LoadWrapper>
          <LoaderSmall dark={loaderIsDark} />
        </LoadWrapper>
      );
    }

    return <LoaderSmall dark={loaderIsDark} />;
  }

  return <>{children}</>;
}
