import { Trans } from '@lingui/macro';
import { getPDF } from 'api';
import { useCallback, useState } from 'react';
import { FaDownload } from 'react-icons/fa';
import { Text } from 'rebass';

export function DownloadPdf({ match, report, showError }) {
  const [isDownloading, handleDownload] = useState(false);

  // Fetch report as a PDF file and push to browser's download stack
  const fetchPDF = useCallback(async () => {
    try {
      handleDownload(true);
      const resp = await getPDF(match.params.id, match.params.bloodTestId, report.id);
      const pdfName = resp.headers?.['file-name'] || `SBAS_${report.name}_${report.id}.pdf`;
      const url = window.URL.createObjectURL(new Blob([resp.data ?? resp]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', pdfName);
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      console.log(e);
      showError(e);
    } finally {
      handleDownload(false);
    }
  }, [match, report.id, report.name, showError]);

  return (
    <>
      {isDownloading ? (
        <div className="loadingSpinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      ) : (
        <Text variant="clickable" onClick={fetchPDF} mr={3}>
          <span style={{ verticalAlign: 'bottom' }}>
            <FaDownload fontSize="0.9em" />
          </span>{' '}
          <span>
            <Trans>Download PDF</Trans>
          </span>
        </Text>
      )}
    </>
  );
}
