import { Flex, Text } from 'rebass';
import { Trans } from '@lingui/macro';
import { FaExclamationTriangle } from 'react-icons/fa';
import ReportIcon from 'assets/report-icon.png';
import Reports from './Reports';

export default function ReportsContainer({ bloodTest }) {
  if (!bloodTest || bloodTest.reports.length === 0) {
    return null;
  }

  return (
    <Flex flexDirection="column" sx={{ flex: '0 0 auto' }}>
      <Text color="primary" fontWeight={300} fontSize={24} sx={{ alignItems: 'center' }}>
        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <img alt="report-icon" width={36} src={ReportIcon} style={{ paddingRight: 8 }} />
          <Trans>Generated reports</Trans>
        </span>
      </Text>
      {bloodTest.is_on_treatment && (
        <Text fontSize={2} color="orange" mt={3}>
          <FaExclamationTriangle style={{ marginBottom: '-2px' }} />{' '}
          <Trans>Patient was on treatment at the time of test</Trans>
        </Text>
      )}
      <Flex py={4} flexDirection="column" alignItems="center">
        <Reports reports={bloodTest.reports} canOpenFirstByDefault={bloodTest.reports.length === 1} />
      </Flex>
    </Flex>
  );
}
