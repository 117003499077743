import { useState } from 'react';
import { Flex, Box, Text, Heading } from 'rebass';
import { Input, Label } from '@rebass/forms';
import { useForm } from 'react-hook-form';
import { Trans } from '@lingui/macro';

import { setPassword } from 'api';
import { renderError } from 'utils';
import Button from 'components/common/Button';
import Message from 'components/common/Message';

const ChangePassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();
  const [apiResponse, handleResponse] = useState({ message: '', type: '' });
  const [isLoading, handleLoading] = useState(false);

  const onSubmit = async (data) => {
    handleLoading(true);
    try {
      await setPassword(data);
      handleResponse({ message: 'Password changed succesfully', type: 'ok' });
      handleLoading(false);
    } catch (e) {
      handleLoading(false);
      handleResponse({ message: renderError(e), type: 'error' });
    }
  };

  return (
    <Box p={3} width={1} minHeight={500}>
      <Heading mb={3}>
        <Trans>Change your password</Trans>
      </Heading>
      <Text my={3}>
        <Trans>The password must be at least 10 characters long, not entirely numeric or too common.</Trans>
      </Text>

      <Flex width={[1, 1, 1, 1, 2 / 5]}>
        <Box width={1} as="form" onSubmit={handleSubmit(onSubmit)}>
          <Label>
            <Trans>Current password</Trans>
          </Label>
          <Input
            className={errors.current_password ? 'error' : ''}
            {...register('current_password', { required: true })}
            type="password"
          />
          {errors.current_password && (
            <Text py={2} fontSize={1} color="orange">
              Current password is required
            </Text>
          )}
          <Label>
            <Trans>New password</Trans>
          </Label>
          <Input
            className={errors.new_password ? 'error' : ''}
            {...register('new_password', { required: true })}
            type="password"
          />
          {errors.new_password && (
            <Text py={2} fontSize={1} color="orange">
              New password is required
            </Text>
          )}
          <Label>
            <Trans>Repeat New password</Trans>
          </Label>
          <Input
            className={errors.new_password_repeat ? 'error' : ''}
            {...register('new_password_repeat', {
              validate: {
                required: (value) => value !== '',
                equal: (value) => value === getValues().new_password,
              },
            })}
            type="password"
          />
          {errors.new_password_repeat && errors.new_password_repeat.type === 'required' && (
            <Text py={2} fontSize={1} color="orange">
              <Trans>New password is required</Trans>
            </Text>
          )}
          {errors.new_password_repeat && errors.new_password_repeat.type === 'equal' && (
            <Text py={2} fontSize={1} color="orange">
              <Trans>Passwords don't match</Trans>
            </Text>
          )}

          <Flex justifyContent="flex-end">
            <Button type="submit" mt={4} mb={4} variant="primary" isLoading={isLoading}>
              <Trans>Change password</Trans>
            </Button>
          </Flex>
          {apiResponse && <Message type={apiResponse.type} message={apiResponse.message} />}
        </Box>
      </Flex>
    </Box>
  );
};

export default ChangePassword;
