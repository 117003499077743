import { Flex, Box, Text, Image } from 'rebass';
import sbaGraph from 'assets/sba-graph-2.png';
import { Trans } from '@lingui/macro';
import { Link } from 'react-router-dom';

import faqPDF from 'assets/FAQ_January_2024.pdf';
import userManual from 'assets/SBAS-MD-IFU - Instructions for use_V12.2 - MDR_CUSTOMERS.pdf';
import VBuserManual from 'assets/VB-MD-IFU_-_Instructions_for_use_CUSTOMER_V4.2.pdf';
import useGlobal from 'hooks/global';
import { getOrganizationFromUser } from './Dropdown';
import { useEffect, useState } from 'react';
import { getCountryGroup } from 'api';
import { RequiredData, getSharedData } from './SharedData/SharedData';
import { openDoc } from 'components/Signup/terms/TermAndPolicy';
import { LoaderSmall } from './Loader';
import { useErrorDialog } from 'components/errorDialog/ErrorDialog';

export default function Footer() {
  const [globalState] = useGlobal();
  const { user } = globalState;
  const isAuthenticated = user && user.email;

  return (
    <Flex flexDirection="column" bg="primary" alignItems="center" width="100%" sx={{ flex: '0 0 auto' }}>
      <Flex
        color="white"
        justifyContent="center"
        width="100%"
        //  height={300}
        variant="container"
      >
        <Flex width={1} variant="fluid" mb={3}>
          {isAuthenticated && (
            <>
              <Box width={[1, 1 / 2, 1 / 2, 1 / 3, 1 / 3]} px={2}>
                <Text
                  py={1}
                  color="primaryLight"
                  fontSize={2}
                  sx={{
                    fontStyle: 'oblique',
                    fontVariant: 'all-small-caps',
                    fontWeight: 'bold',
                  }}
                >
                  <Trans>Additional info</Trans>
                </Text>

                <Box>
                  <Text variant="clickable" py={1} fontSize={2}>
                    <a href={userManual} target="_blank" rel="noopener noreferrer">
                      <Trans>SBAS Software User Manual</Trans>
                    </a>
                  </Text>
                </Box>

                <Box>
                  <Text variant="clickable" py={1} fontSize={2}>
                    <a href={VBuserManual} target="_blank" rel="noopener noreferrer">
                      <Trans>Virus vs. Bacteria Instructions For Use</Trans>
                    </a>
                  </Text>
                </Box>

                <Box>
                  <Text variant="clickable" py={1} fontSize={2}>
                    <Link to="/video">
                      <Trans>Introductory video - SBAS Software</Trans>
                    </Link>
                  </Text>
                </Box>

                <Box>
                  <Text variant="clickable" py={1} fontSize={2}>
                    <a href={faqPDF} target="_blank" rel="noopener noreferrer">
                      <Trans>Frequently asked questions</Trans>
                    </a>
                  </Text>
                </Box>

                <Box>
                  <Text variant="clickable" py={1} fontSize={2}>
                    <a href="https://smartbloodanalytics.com/en/pricing" target="_blank" rel="noopener noreferrer">
                      <Trans>Pricing</Trans>
                    </a>
                  </Text>
                </Box>
              </Box>
              <Box width={[1, 1 / 2, 1 / 2, 1 / 3, 1 / 3]} px={2}>
                <Text
                  py={1}
                  color="primaryLight"
                  fontSize={2}
                  sx={{
                    fontStyle: 'oblique',
                    fontVariant: 'all-small-caps',
                    fontWeight: 'bold',
                  }}
                >
                  <Trans>Legal info</Trans>
                </Text>
                <TermsAndPolicy user={user} />
              </Box>
            </>
          )}

          <Box
            width={isAuthenticated ? [1, 1, 1, 1 / 3, 1 / 3] : [1]}
            p={3}
            sx={{ textAlign: !isAuthenticated ? 'center' : 'left' }}
          >
            <Image src={sbaGraph} m={0} sx={{ maxWidth: 'min(100%, 400px)' }} />
          </Box>
        </Flex>
        <Text fontSize={1} px={2} sx={{ textAlign: !isAuthenticated ? 'center' : 'left' }}>
          © {new Date().getFullYear()} <Trans>SMART BLOOD ANALYTICS SWISS SA. ALL RIGHTS RESERVED</Trans>
        </Text>
      </Flex>
    </Flex>
  );
}

function TermsAndPolicy({ user }) {
  return (
    <RequiredData dataKeys={['COUNTRIES']}>
      <TermsAndPolicyContent user={user} />
    </RequiredData>
  );
}

function TermsAndPolicyContent({ user }) {
  const { showError } = useErrorDialog();
  const country = getOrganizationFromUser(user)?.country;
  const countries = getSharedData('COUNTRIES');

  const [state, setState] = useState({
    isLoading: true,
    terms: undefined,
    policy: undefined,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        let countryDef = countries.find((c) => c.name === country);

        if (!countryDef) {
          countryDef = countries.find((c) => c.name === 'Switzerland');
        }

        if (countryDef) {
          const countryGroup = await getCountryGroup(countryDef.country_group_id);

          setState({
            isLoading: false,
            terms: countryGroup.terms_and_conditions,
            policy: countryGroup.privacy_policy,
          });
        } else {
          setState({ isLoading: false, terms: undefined, policy: undefined });
        }
      } catch (e) {
        showError(e);
        console.log(e);
      }
    };

    setState({ isLoading: true, terms: undefined, policy: undefined });
    fetchData();
  }, [country, countries, showError]);

  if (state.isLoading) {
    return <LoaderSmall />;
  }

  return (
    <>
      <Box>
        {state.terms && (
          <Text
            variant="clickable"
            color="white"
            py={1}
            fontSize={2}
            onClick={() => openDoc(state.terms, 'Terms & Conditions')}
          >
            <Trans>Terms & Conditions</Trans>
          </Text>
        )}
      </Box>
      <Box>
        {state.policy && (
          <Text
            variant="clickable"
            color="white"
            py={1}
            fontSize={2}
            mb={5}
            onClick={() => openDoc(state.policy, 'Privacy Policy')}
          >
            <Trans>Privacy policy</Trans>
          </Text>
        )}
      </Box>
    </>
  );
}
