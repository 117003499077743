import { RecommendationCard } from './RecommendationCard';
import { useReportIcd } from '..';

export function RecommendationContent({ reportDetails, userIsStaff }) {
  const diseases = reportDetails.result?.results;
  const { selectedIcdId, setSelectedIcdId } = useReportIcd();

  let selected = diseases.findIndex((d) => d.code === selectedIcdId);

  if (selected === -1) {
    selected = 0;
  }

  const prev = () => setSelectedIcdId(diseases[selected - 1].code);
  const next = () => setSelectedIcdId(diseases[selected + 1].code);

  return (
    <RecommendationCard
      diseaseColor={reportDetails.result?.visualization?.legend?.[selected]?.color}
      disease={diseases[selected]}
      selectedIndex={selected}
      diseaseCount={diseases.length}
      prev={prev}
      next={next}
      userIsStaff={userIsStaff}
    />
  );
}
