import { Box, Flex, Heading } from 'rebass';
import { CustomCardButton } from '../ReportParamDescription/ReportParamDescriptionCard';
import { Trans } from '@lingui/macro';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { DiseaseColorBox } from '../ReportParamDescription/ReportParamDescriptionCard/DiseaseColorBox';
import { SimpleTable } from '../_common/SimpleTable';

export function SymptomsCard({ selectedIndex, diseaseCount, prev, next, disease, diseaseColor }) {
  return (
    <>
      <Box
        style={{
          position: 'sticky',
          top: 0,
          background: 'linear-gradient(white,white 90%, #ffffffcc)',
          marginBottom: '1em',
        }}
      >
        <Flex justifyContent={'space-between'} alignItems={'baseline'}>
          <CustomCardButton
            text={<Trans>Previous</Trans>}
            icon={<FaAngleLeft style={{ verticalAlign: 'sub' }} />}
            disabled={selectedIndex === 0}
            onClick={prev}
          />

          <Flex flexDirection={'column'}>
            <Heading p={2} color="orange">
              <DiseaseColorBox color={diseaseColor} />
              {disease.code} - {disease.description}
            </Heading>
          </Flex>

          <CustomCardButton
            text={<Trans>Next</Trans>}
            iconIsRight
            icon={<FaAngleRight style={{ verticalAlign: 'sub' }} />}
            disabled={selectedIndex === diseaseCount - 1}
            onClick={next}
          />
        </Flex>
      </Box>

      <Flex flexWrap={'wrap'} fontSize={1}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', justifyContent: 'center', width: '100%' }}>
          {(disease?.manifestations?.symptoms || []).length > 0 && (
            <SimpleTable
              data={disease.manifestations.symptoms}
              cols={[
                {
                  label: <Trans>Symptoms</Trans>,
                  value: (row) => row,
                },
              ]}
            />
          )}

          {(disease?.manifestations?.signs || []).length > 0 && (
            <SimpleTable
              data={disease.manifestations.signs}
              cols={[
                {
                  label: <Trans>Signs</Trans>,
                  value: (row) => row,
                },
              ]}
            />
          )}

          {(disease?.manifestations?.comorbidities || []).length > 0 && (
            <SimpleTable
              data={disease.manifestations.comorbidities}
              cols={[
                {
                  label: <Trans>Comorbidities</Trans>,
                  value: (row) => row,
                },
              ]}
            />
          )}
        </div>
      </Flex>
    </>
  );
}
