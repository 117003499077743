import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { sl } from 'date-fns/locale';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';
import { API_DATE_FORMAT } from 'constants/date';

const DateFormat = ({ value, detailed }) => {
  const currentLang = localStorage.getItem('lang') || 'en';

  if (value === undefined || value === null) {
    return null;
  }

  let dateFormat = 'dd.MM.yyyy';
  if (detailed) {
    dateFormat += ' HH:mm:ss';
  }

  if (currentLang === 'sl') {
    return format(new Date(parseISO(value)), dateFormat, { locale: sl });
  } else {
    return format(new Date(parseISO(value)), dateFormat);
  }
};

export const dateFormat = (value, detailed) => DateFormat({ value, detailed });

export const DateFormatDistance = ({ from, to }) => {
  const currentLang = localStorage.getItem('lang') || 'en';

  if (!from || !to || isNaN(from.getTime()) || isNaN(to.getTime())) {
    return '/';
  }

  if (currentLang === 'sl') {
    return formatDistanceStrict(from, to, { locale: sl });
  } else {
    return formatDistanceStrict(from, to);
  }
};

export const parseLocalizedDate = (value) => {
  if (!value) {
    return null;
  }
  const pattern = /(\d{2})\.(\d{2})\.(\d{4})/;
  return format(new Date(value.replace(pattern, '$3-$2-$1')), API_DATE_FORMAT);
};

export default DateFormat;
