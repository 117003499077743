import { useState, useEffect, useCallback } from 'react';
import { Flex, Box, Text } from 'rebass';
import { Label } from '@rebass/forms';
import { withRouter } from 'react-router-dom';
import { Trans } from '@lingui/macro';
import { FaFileExport, FaTimes } from 'react-icons/fa';

import { LoaderSmall } from 'components/common/Loader';
import { getPatient, deletePatient, exportPatient } from 'api';
import { RenderGender, calculateAge, downloadJsonFile } from 'utils';

import useGlobal from 'hooks/global';
import ConfirmationModal from 'components/common/ConfirmationModal';
import PatientInfoComment from './PatientInfoComment';
import { useErrorDialog } from 'components/errorDialog/ErrorDialog';

/**
 * @function PatientInfo
 * Show patient related info
 * Fetch patient if globalState.patient is empty and ID exists in url
 * @param  match    match object contains information about how a <Route path> matched the URL.
 */
const PatientInfo = ({ match, history }) => {
  const { showError } = useErrorDialog();
  const [isDeleting, handleIsDeleting] = useState(false);
  const [isLoadingExport, setIsLoadingExport] = useState(false);

  const [globalState, globalActions] = useGlobal();
  const { uid, birth_year, birth_date, gender, comment } = globalState.patient;

  useEffect(() => {
    const fetchPatient = async () => {
      try {
        const patient = await getPatient(match.params.id);
        globalActions.setPatient(patient);
      } catch (e) {
        showError(e);
        console.log(e);
      }
    };

    if (match.params.id && globalState.patient.id !== match.params.id && !isDeleting) {
      fetchPatient();
    }
  }, [globalActions, globalState.patient.id, match.params.id, isDeleting, showError]);

  // Delete patient
  const handlePatientDelete = async () => {
    handleIsDeleting(true);
    // eslint-disable-next-line no-useless-catch
    try {
      await deletePatient(globalState.patient.id);
      globalActions.setPatient({});
      history.replace('/');
    } catch (e) {
      showError(e);
      console.log(e);
      throw e;
    }
  };

  const handleExportPatient = useCallback(async () => {
    try {
      setIsLoadingExport(true);
      const patientContent = await exportPatient(match.params.id);
      downloadJsonFile({ content: patientContent, filename: `${globalState.patient.uid}.json` });
      setIsLoadingExport(false);
    } catch (e) {
      showError(e);
      console.log(e);
    }
  }, [match.params.id, globalState.patient.uid, showError]);

  return (
    <Flex variant="fluid" color="text" pt={3} pb={4}>
      <Box width={[1, 1, 1, 1, 4 / 8]}>
        <Flex variant="fluid">
          <Box mb={[2, 2, 2, 2, 0]} pr={3} width={['100%', '100%', '46%', '34%']}>
            <Label color="darkergray">
              <Trans>Patient</Trans>
            </Label>
            <Text fontSize={[14, 14, 14, 14, 16]}>{uid}</Text>
          </Box>
          <Box pr={3} width={[1 / 3, 1 / 3, '20%', '24%', '24%']}>
            <Label color="darkergray">
              <Trans>Age</Trans>
            </Label>
            <Text fontSize={[14, 14, 14, 14, 16]}>
              {calculateAge(birth_year, birth_date)} <Trans>years</Trans>
            </Text>
          </Box>
          <Box pr={3} width={[1 / 3, 1 / 3, '24%', '28%', '28%']}>
            <Label color="darkergray">
              <Trans>Date of birth</Trans>
            </Label>
            <Text fontSize={[14, 14, 14, 14, 16]}>{birth_year ?? birth_date}</Text>
          </Box>
          <Box width={[1 / 3, 1 / 3, '10%', '14%', '14%']}>
            <Label color="darkergray">
              <Trans>Gender</Trans>
            </Label>
            <Text fontSize={[14, 14, 14, 14, 16]}>
              <RenderGender gender={gender} />
            </Text>
          </Box>
          <Box pr={3} width={1}>
            <Text variant="clickable" mt={2} fontSize={2} color="darkergray">
              <ConfirmationModal
                trigger={(handleOpen) =>
                  !isDeleting ? (
                    <Text
                      color="darkergray"
                      fontSize="12px"
                      variant="clickable"
                      onClick={() => handleOpen()}
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      <FaTimes style={{ marginRight: 4 }} />
                      <Trans>Delete patient</Trans>
                    </Text>
                  ) : null
                }
                handleConfirm={handlePatientDelete}
                message={<Trans>{`Do you really want to delete patient ${uid}?`}</Trans>}
              />
            </Text>
            {globalState.patient.id && globalState.user.is_staff && (
              <Text
                color="darkergray"
                fontSize="12px"
                variant={!isLoadingExport ? 'clickable' : ''}
                onClick={isLoadingExport ? null : handleExportPatient}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <FaFileExport style={{ marginRight: 4 }} />
                <Trans>Export patient</Trans>
                {isLoadingExport && (
                  <>
                    &nbsp;
                    <LoaderSmall
                      dark
                      style={{
                        height: '2em',
                        width: '2em',
                      }}
                    />
                  </>
                )}
              </Text>
            )}
          </Box>
        </Flex>
      </Box>

      <PatientInfoComment defaultComment={comment} />
    </Flex>
  );
};

export default withRouter(PatientInfo);
