import { useRef, useState } from 'react';
import { Flex, Box, Heading, Text } from 'rebass';
import { Label, Input, Select } from '@rebass/forms';
import { useForm } from 'react-hook-form';
import { Trans } from '@lingui/macro';

import { getUser, updateUser } from 'api';
import Button from 'components/common/Button';
import DatePicker from 'components/common/DatePicker';
import Message from 'components/common/Message';
import useGlobal from 'hooks/global';
import { parseLocalizedDate } from 'components/common/DateFormat';
import { RequiredData, getSharedData } from 'components/common/SharedData/SharedData';
import { useErrorDialog } from 'components/errorDialog/ErrorDialog';

const SettingsWrapper = () => {
  return (
    <RequiredData dataKeys={['COUNTRIES']} loaderIsDark loaderIsVeryBig>
      <SettingsContent />
    </RequiredData>
  );
};

const SettingsContent = () => {
  const { showError } = useErrorDialog();
  const countries = useRef(getSharedData('COUNTRIES'));
  const [isLoading, handleLoading] = useState(false);
  const [message, handleMessage] = useState(null);
  const [globalState, globalActions] = useGlobal();

  const {
    register,
    handleSubmit,
    setValue: setFormValue,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    handleMessage(null);
    handleLoading(true);
    // TODO unify on API birthday and birth_date
    try {
      await updateUser({
        ...data,
        birthday: parseLocalizedDate(data.birthday),
      });
      handleMessage(<Trans>User info saved successfully.</Trans>);
      const user = await getUser(); // get user
      await globalActions.setUser(user);
    } catch (e) {
      console.log(e);
      showError(e);
    } finally {
      handleLoading(false);
    }
  };

  return (
    <Box p={3} width={[1, 1, 1, 1, 3 / 5]} minHeight={500}>
      <Heading mb={3}>
        <Trans>User information</Trans>
      </Heading>
      <Box as="form" onSubmit={handleSubmit(onSubmit)}>
        <Label>
          <Trans>Email</Trans> *
        </Label>
        <Input
          {...register('email', { required: true })}
          defaultValue={globalState.user.email}
          className={errors.email ? 'error' : ''}
        />
        {errors.email && (
          <Text py={2} fontSize={1} color="orange">
            Field is required
          </Text>
        )}
        <Flex>
          <Box pr={3} width={1 / 2}>
            <Label>
              <Trans>First Name</Trans> *
            </Label>
            <Input
              {...register('first_name', { required: true })}
              defaultValue={globalState.user.first_name}
              className={errors.first_name ? 'error' : ''}
            />
            {errors.first_name && (
              <Text py={2} fontSize={1} color="orange">
                Field is required
              </Text>
            )}
          </Box>

          <Box width={1 / 2}>
            <Label>
              <Trans>Last Name</Trans> *
            </Label>
            <Input
              {...register('last_name', { required: true })}
              defaultValue={globalState.user.last_name}
              className={errors.last_name ? 'error' : ''}
            />
            {errors.last_name && (
              <Text py={2} fontSize={1} color="orange">
                Field is required
              </Text>
            )}
          </Box>
        </Flex>

        <Flex>
          <Box pr={3} width={1 / 2}>
            <Label>
              <Trans>Birthdate</Trans> *
            </Label>
            <DatePicker
              name="birthday"
              register={register}
              registerProps={{ required: true }}
              defaultValue={globalState.user.birthday}
              over18
              setFormValue={setFormValue}
              errors={errors.birthday}
            />
            {errors.birthday && (
              <Text py={2} fontSize={1} color="orange">
                <Trans>Field is required</Trans>
              </Text>
            )}
          </Box>

          <Box width={1 / 2}>
            <Label name="country">
              <Trans>Country</Trans> *
            </Label>
            <Select
              defaultValue={globalState.user.country}
              {...register('country', { required: true })}
              className={errors.country ? 'error' : ''}
            >
              {countries.current.map((country, index) => (
                <option key={index} value={country.code}>
                  {country.name}
                </option>
              ))}
            </Select>
            {errors.country && (
              <Text py={2} fontSize={1} color="orange">
                <Trans>Field is required</Trans>
              </Text>
            )}
          </Box>
        </Flex>

        <Flex justifyContent="flex-end">
          <Button my={3} isLoading={isLoading}>
            <Trans>Save</Trans>
          </Button>
        </Flex>
        {message && (
          <Flex width={1}>
            <Message type="ok" message={message} />
          </Flex>
        )}
      </Box>
    </Box>
  );
};

export default SettingsWrapper;
