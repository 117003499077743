import { Checkbox } from '@rebass/forms';
import { getCountryGroup } from 'api';
import { LoaderSmall } from 'components/common/Loader';
import { getSharedData } from 'components/common/SharedData/SharedData';
import { useErrorDialog } from 'components/errorDialog/ErrorDialog';
import { useEffect, useRef, useState } from 'react';
import { useWatch } from 'react-hook-form';
import { Box, Flex, Text } from 'rebass';

export const openDoc = (document, title) => {
  let child = window.open('about:blank', 'myChild');
  child.document.write(document);
  child.document.title = title;
  child.document.close();
};

export function TermAndPolicy({ control, register, setFormValue, countryProperty }) {
  const { showError } = useErrorDialog();
  const countries = useRef(getSharedData('COUNTRIES'));
  const [state, setState] = useState({
    isLoading: false,
    terms: undefined,
    policy: undefined,
  });

  const country = useWatch({
    control,
    name: countryProperty,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const countryDef = countries.current.find((c) => c.code === country);
        const countryGroup = await getCountryGroup(countryDef.country_group_id);

        setState({
          isLoading: false,
          terms: countryGroup.terms_and_conditions,
          policy: countryGroup.privacy_policy,
        });
      } catch (e) {
        console.log(e);
        showError(e);
      }
    };

    setFormValue('termsOfUse', false);

    if (country) {
      setState({ isLoading: true, terms: undefined, policy: undefined });
      fetchData();
    } else {
      setState({ isLoading: false, terms: undefined, policy: undefined });
    }
  }, [country, setFormValue, showError]);

  return (
    <>
      <Checkbox
        id="termsOfUse"
        {...register('termsOfUse', { required: true })}
        disabled={state.isLoading || (!state.policy && !state.terms)}
      />
      <Flex alignItems={'center'} sx={{ gap: '1ch' }}>
        {state.isLoading && <LoaderSmall dark style={{ fontSize: '2px' }} />}
        <Box>
          I have read and agree to the
          <Text
            variant={state.terms && 'clickable'}
            style={{ margin: '0 4px', display: 'inline', borderBottom: 'solid 1px' }}
            onClick={!state.terms ? undefined : () => openDoc(state.terms, 'Terms & Conditions')}
          >
            Terms & Conditions
          </Text>
          <span>and</span>
          <Text
            variant={state.policy && 'clickable'}
            style={{ margin: '0 4px', display: 'inline', borderBottom: 'solid 1px' }}
            onClick={!state.policy ? undefined : () => openDoc(state.policy, 'Privacy Policy')}
          >
            Privacy Policy
          </Text>
        </Box>
      </Flex>
    </>
  );
}
