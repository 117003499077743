import { useEffect, useState } from 'react';
import { Box, Flex, Image, Text } from 'rebass';
import { Label, Checkbox } from '@rebass/forms';
import { Link } from 'react-router-dom';
import Logo from 'assets/sba-logo.svg';
import { useForm } from 'react-hook-form';
import { confirmViewed, getCountries, getCountryGroup } from 'api';
import useGlobal from 'hooks/global';
import { LoaderSmall } from 'components/common/Loader';
import { getUser } from 'api';
import { getSharedData } from 'components/common/SharedData/SharedData';
import { getOrganizationFromUser } from 'components/common/Dropdown';
import { renderError } from 'utils';
import { openDoc } from 'components/Signup/terms/TermAndPolicy';
import Message from 'components/common/Message';
import { useErrorDialog } from 'components/errorDialog/ErrorDialog';

const TermsConfirm = ({ user }) => {
  const { showError } = useErrorDialog();
  const { register, getValues } = useForm();
  const [isLoading, handleLoading] = useState(false);
  const [, globalActions] = useGlobal();

  const confirm = async () => {
    handleLoading(true);

    try {
      await confirmViewed({ type: 'terms' });

      const user = await getUser(); // get user
      await globalActions.setUser(user);
    } catch (e) {
      console.log(e);
      showError(e);
    } finally {
      handleLoading(false);
    }
  };

  const onCheckboxChange = () => {
    const { terms, privacy } = getValues();
    if (terms & privacy) {
      confirm();
    }
  };

  return (
    <Flex
      variant="container"
      alignItems="center"
      justifyContent="center"
      width="100%"
      bg="lightgray"
      minHeight="100vh"
      maxWidth="100%"
    >
      <Box variant="card" textAlign="left">
        <Link to="/">
          <Image mb={4} src={Logo} />
        </Link>
        <Text color="primary">
          Dear {user.first_name} {user.last_name}, <br />
          <br />
          We updated our Terms and Conditions and Privacy Policy. <br />
          <br />
          Please check the new terms on the following links:
        </Text>
        <TermsAndPolicy register={register} onCheckboxChange={onCheckboxChange} />
        <Text color="primary">
          Check the agree boxes to continue with the use of our application.
          <br />
          <br />
          Thank you. {isLoading && <LoaderSmall dark inline />}
        </Text>
      </Box>
    </Flex>
  );
};

export default TermsConfirm;

function TermsAndPolicy({ register, onCheckboxChange }) {
  const [globalState] = useGlobal();
  const { user } = globalState;
  const [state, setState] = useState({
    isLoading: true,
  });

  useEffect(() => {
    const fetch = async () => {
      try {
        let countries = getSharedData('COUNTRIES');

        if (!countries) {
          countries = await getCountries();
        }

        const country = getOrganizationFromUser(user)?.country;

        let countryDef = countries.find((c) => c.name === country);

        if (!countryDef) {
          countryDef = countries.find((c) => c.name === 'Switzerland');
        }

        const countryGroup = await getCountryGroup(countryDef.country_group_id);

        setState({
          isLoading: false,
          data: countryGroup,
          error: undefined,
          countries,
        });
      } catch (error) {
        setState({
          isLoading: false,
          data: undefined,
          error: renderError(error),
          countries: undefined,
        });
      }
    };

    fetch();
  }, [user]);

  if (state.isLoading) {
    return (
      <Box my={3}>
        <LoaderSmall dark />
      </Box>
    );
  }

  if (state.error) {
    return (
      <Box my={3}>
        <Message type="error" message={state.error} />
      </Box>
    );
  }

  return (
    <ul style={{ paddingLeft: '16px', marginTop: '8px' }}>
      <li>
        <Text
          variant="clickable"
          // color="white"
          py={1}
          style={{ margin: '0 4px', fontSize: 18, fontWeight: 'bold', textDecoration: 'underline' }}
          fontSize={2}
          onClick={() => openDoc(state.data.terms_and_conditions, 'Terms & Conditions')}
        >
          Terms & Conditions
        </Text>

        <Label py={0} mt={2} sx={{ alignItems: 'center' }}>
          <Checkbox id="terms" {...register('terms', { onChange: onCheckboxChange })} />I agree with the new Terms and
          Conditions
        </Label>
      </li>
      <li style={{ marginTop: 16 }}>
        <Text
          variant="clickable"
          // color="white"
          py={1}
          style={{ margin: '0 4px', fontSize: 18, fontWeight: 'bold', textDecoration: 'underline' }}
          fontSize={2}
          onClick={() => openDoc(state.data.privacy_policy, 'Privacy Policy')}
        >
          Privacy Policy
        </Text>
        <Label py={0} mt={2} sx={{ alignItems: 'center' }}>
          <Checkbox id="privacy" {...register('privacy', { onChange: onCheckboxChange })} />I agree with the new Privacy
          Policy
        </Label>
      </li>
    </ul>
  );
}
