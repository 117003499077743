import { Trans } from '@lingui/macro';
import { Box, Flex, Text } from 'rebass';
import { ParamContributionLine } from './paramContributionLine';

export default function ParamContribution({ param, maxContribution, alternativeColor1, alternativeColor2 }) {
  return (
    <Box p={2} marginBottom={1}>
      <Flex width="100%">
        <Box width="50%">
          <ParamContributionLine
            rtl={true}
            max={maxContribution}
            value={param.value < 0 ? param.value : 0}
            color={param.isNaN ? 'mediumgray' : alternativeColor1 ?? 'orange'}
          />
        </Box>
        <Box width="50%">
          <ParamContributionLine
            max={maxContribution}
            value={param.value >= 0 ? param.value : 0}
            color={param.isNaN ? 'mediumgray' : alternativeColor2 ?? 'blue'}
          />
        </Box>
      </Flex>

      <Text color={param.isNaN ? 'darkergray' : 'black'} fontSize={13} lineHeight={'1em'} p={2}>
        <span style={{ fontWeight: 'lighter' }}>{param.description}</span>
        {/* {param.value?.toFixed(3)} */}
        {param.isNaN && (
          <span style={{ fontWeight: 'lighter', fontStyle: 'oblique' }}>
            &nbsp; [<Trans>Not measured parameter</Trans>]
          </span>
        )}
      </Text>
    </Box>
  );
}
