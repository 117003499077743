import { Flex, Box } from 'rebass';

const styles = {
  base: {
    backgroundColor: 'darkgray',
    display: 'block',
    height: '5px',
    position: 'absolute',
    width: '100%',
  },
  min: {
    color: 'darkergray',
    fontSize: '12px',
    height: '5px',
    marginLeft: '-18px',
    marginTop: '10px',
    position: 'absolute',
    textAlign: 'center',
    width: '36px',
  },
  interval: {
    backgroundColor: '#a6bcdc',
    height: '5px',
    position: 'absolute',
    // zIndex: 999,
  },
  max: {
    color: 'darkergray',
    fontSize: '12px',
    height: '5px',
    marginRight: '-18px',
    marginTop: '10px',
    position: 'absolute',
    textAlign: 'center',
    width: '36px',
  },
  value: {
    borderRadius: 2,
    display: 'block',
    height: '16px',
    position: 'absolute',
    top: '-6px',
    width: '4px',
    // zIndex: 9999,
  },
};

const renderWithoutMax = (min, value, showWarning) => {
  let scale = Math.abs(min),
    leftRef = 0, // default offset
    valuePosition = ((value - min) / scale) * 100;

  if (valuePosition > 95) {
    valuePosition = 95;
  }

  if (valuePosition < 0) {
    let newMin = Math.abs(valuePosition - 100) / 0.95;
    leftRef = 100 * (1 - 100 / newMin);
    leftRef = leftRef > 70 ? 70 : leftRef;
    valuePosition = 5;
  }

  return (
    <Flex px={2} width="400px" sx={{ marginTop: '-6px' }}>
      <Box sx={{ position: 'relative', width: '100%' }}>
        <Box key="base" sx={styles.base} />
        <Box
          key="min"
          sx={{
            ...styles.min,
            left: `${leftRef}%`,
          }}
        >
          {min}
        </Box>
        <Box
          key="interval"
          sx={{
            ...styles.interval,
            left: `${leftRef}%`,
            right: 0,
          }}
        />
        <Box
          kev="value"
          sx={{
            ...styles.value,
            left: `${valuePosition}%`,
            backgroundColor: showWarning ? 'orange' : 'primary',
          }}
        />
      </Box>
    </Flex>
  );
};

const renderSingleRef = (min, value, showWarning) => {
  let scale = Math.abs(min),
    leftRef = 0, // default offset
    valuePosition = ((value - min) / scale) * 100;

  if (valuePosition > 95) {
    valuePosition = 95;
  }

  if (valuePosition < 0) {
    const newMin = Math.abs(valuePosition - 100) / 0.95;
    leftRef = 100 * (1 - 100 / newMin);
    leftRef = leftRef > 70 ? 70 : leftRef;
    valuePosition = 5;
  }

  return (
    <Flex px={2} width="400px" sx={{ marginTop: '-6px' }}>
      <Box sx={{ position: 'relative', width: '100%' }}>
        <Box key="base" sx={styles.base} />
        <Box
          key="min"
          sx={{
            ...styles.min,
            left: `${leftRef}%`,
          }}
        >
          {min}
        </Box>
        <Box
          kev="value"
          sx={{
            ...styles.value,
            left: `${valuePosition}%`,
            backgroundColor: showWarning ? 'orange' : 'primary',
          }}
        />
      </Box>
    </Flex>
  );
};

const Range = ({ min, max, value, showWarning }) => {
  if (min === null) {
    min = 0;
  }

  if (max === null) {
    return renderWithoutMax(min, value, showWarning);
  }

  if (min === max) {
    return renderSingleRef(min, value, showWarning);
  }

  const valueIsValid = value !== '';
  if (!valueIsValid) {
    value = min + (max - min) / 2;
  }

  let scale = Math.abs(max - min),
    rightRef = 0, // default offset
    leftRef = 0, // default offset
    valuePosition = ((value - min) / scale) * 100;

  if (valuePosition > 100) {
    let newMax = valuePosition / 0.95;
    rightRef = 100 * (1 - 100 / newMax);
    rightRef = rightRef > 70 ? 70 : rightRef;
    valuePosition = 95;
  }
  if (valuePosition < 0) {
    let newMin = Math.abs(valuePosition - 100) / 0.95;
    leftRef = 100 * (1 - 100 / newMin);
    leftRef = leftRef > 70 ? 70 : leftRef;
    valuePosition = 5;
  }

  return (
    <Flex px={2} width="400px" sx={{ marginTop: '-6px' }}>
      <Box sx={{ position: 'relative', width: '100%' }}>
        <Box key="base" sx={styles.base} />
        <Box
          key="min"
          sx={{
            ...styles.min,
            left: `${leftRef}%`,
          }}
        >
          {min}
        </Box>
        <Box
          key="interval"
          sx={{
            ...styles.interval,
            left: `${leftRef}%`,
            right: `${rightRef}%`,
          }}
        />
        <Box
          key="max"
          sx={{
            ...styles.max,
            right: `${rightRef}%`,
          }}
        >
          {max}
        </Box>
        {valueIsValid && (
          <Box
            kev="value"
            sx={{
              ...styles.value,
              left: `${valuePosition}%`,
              backgroundColor: showWarning ? 'orange' : 'primary',
            }}
          />
        )}
      </Box>
    </Flex>
  );
};

export default Range;
