import { Trans, t } from '@lingui/macro';
import { Input, Label } from '@rebass/forms';
import { contactSupport } from 'api';
import Message from 'components/common/Message';
import TextareaWrap from 'components/common/Textarea';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Flex, Heading, Text } from 'rebass';
import Button from 'components/common/Button';
import { useLocation } from 'react-router-dom';

export default function Support() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const predefinedSubject = searchParams.get('subject');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [apiResponseStatus, setApiResponseStatus] = useState();

  const onSubmit = async (data) => {
    setIsLoading(true);
    try {
      await contactSupport(data);
      setApiResponseStatus({
        message: t`Thank you for your message. We will answer you as soon as possible.`,
        type: 'ok',
      });
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setApiResponseStatus({
        message: t`A problem has occurred. Please refresh the page and try again.`,
        type: 'error',
      });
    }
  };

  return (
    <Box p={3} width={[1, 1, 1, 1, 3 / 5]} minHeight={500}>
      <Heading mb={3}>
        <Trans>Support</Trans>
      </Heading>
      <Box width={1} as="form" onSubmit={handleSubmit(onSubmit)}>
        <Label>
          <Trans>Subject</Trans> *
        </Label>
        <Input
          className={errors.title ? 'error' : ''}
          {...register('title', {
            required: true,
            value: predefinedSubject === 'changeOrganizationCountry' ? t`Change organization country` : undefined,
          })}
        />
        {errors.title && (
          <Text py={2} fontSize={1} color="orange">
            <Trans>Subject is required</Trans>
          </Text>
        )}
        <Label>
          <Trans>Message</Trans> *
        </Label>
        <TextareaWrap rows={8} name="message" register={register} registerProps={{ required: true }} />
        {errors.message && (
          <Text py={2} fontSize={1} color="orange">
            <Trans>Message is required</Trans>
          </Text>
        )}

        <Flex justifyContent="flex-end">
          <Button my={3} isLoading={isLoading} disabled={apiResponseStatus}>
            <Trans>Send</Trans>
          </Button>
        </Flex>
        {apiResponseStatus && (
          <Flex width={1}>
            <Message type={apiResponseStatus.type} message={apiResponseStatus.message} />
          </Flex>
        )}
      </Box>
    </Box>
  );
}
