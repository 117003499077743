import globalHook from 'use-global-hook';
import update from 'immutability-helper';

const initialState = {
  user: {},
  patients: [],
  patient: {},
  bloodTest: {},
  parameters: [],
  savedParameters: [],
  blockGenerateReport: [],
  modelRequirements: undefined,
};

const actions = {
  reset: (store) => {
    store.setState({
      user: {},
      patients: [],
      patient: {},
      bloodTest: {},
      bloodTests: [],
      parameters: [],
      savedParameters: [],
      blockGenerateReport: [],
      modelRequirements: undefined,
    });
  },
  // clear state on language switch
  resetData: (store) => {
    store.setState({
      patients: [],
      patient: {},
      bloodTest: {},
      bloodTests: [],
      parameters: [],
      savedParameters: [],
      blockGenerateReport: [],
      modelRequirements: undefined,
    });
  },
  setUser: (store, user) => {
    store.setState({ user: user });
  },
  setModelRequirements: (store, modelRequirements) => {
    store.setState({ modelRequirements });
  },
  setPatients: (store, patients) => {
    store.setState({ patients });
  },
  setPatient: (store, patient) => {
    store.setState({ patient });
  },
  setPatientBloodTest: (store, bloodTest) => {
    store.setState({ bloodTest });
  },
  setBloodTests: (store, bloodTests) => {
    store.setState({ bloodTests });
  },
  pushReport: (store, report) => {
    store.setState(store.state.bloodTest.reports.push(report));
  },
  setParameters: (store, parameters) => {
    store.setState({ parameters });
  },
  setSavedParameters: (store, savedParameters) => {
    store.setState({ savedParameters });
  },
  updateSavedParameter: (store, newParam, index) => {
    const newSavedParameters = update(store.state.savedParameters, {
      [index]: { $set: newParam },
    });
    store.setState({ savedParameters: newSavedParameters });
  },
  preventGenerateReport: (store, code, disable) => {
    if (disable) {
      if (store.state.blockGenerateReport.indexOf(code) === -1) {
        let blockGenerateReport = [...store.state.blockGenerateReport];
        blockGenerateReport.push(code);
        store.setState({ blockGenerateReport });
      }
    } else if (disable === false) {
      const blockGenerateReport = store.state.blockGenerateReport.filter((item) => item !== code);
      store.setState({ blockGenerateReport });
    }
  },
  resetBlockGenerateReport: (store) => {
    store.setState({ blockGenerateReport: [] });
  },
};

const useGlobal = globalHook(initialState, actions);

export default useGlobal;
