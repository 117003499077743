import { Flex, Box } from 'rebass';
import { Input } from '@rebass/forms';
import values from 'lodash/values';
import { useForm } from 'react-hook-form';

const TwoFactorInput = (props) => {
  const regex = new RegExp('[0-9]{1}');
  const { register, getValues, setValue, setFocus } = useForm();

  const submit = () => {
    // on last digit
    const code = values(getValues()).join('');

    if (props.activate) {
      props.activate(code);
    }
    if (props.login) {
      props.twoFactorLogin(code);
    }
  };

  const focusAndDelete = (e) => {
    // focus on previous input and delete its value
    const targetId = e.target.id;
    if (targetId !== '1') {
      setFocus(`${parseInt(targetId) - 1}`);
      setValue(`${parseInt(targetId) - 1}`, '');
    }
  };

  const focusNext = (e) => {
    setFocus(`${parseInt(e.target.id) + 1}`);
  };

  const onKeyUpHandle = (e) => {
    if (e && e.target) {
      if (e.target.value !== '' && regex.test(e.target.value)) {
        focusNext(e);
      } else if (e.keyCode === 8) {
        focusAndDelete(e);
      } else {
        setValue(`${parseInt(e.target.id)}`, '');
      }
    }
  };

  const onLastDigitChange = (e) => {
    if (e && e.target) {
      if (e.target.value !== '' && regex.test(e.target.value)) {
        setTimeout(() => submit(), 200); // wait for state to update and submit
      } else {
        setValue(`${parseInt(e.target.id)}`, '');
      }
    }
  };

  const onLastDigitKeyUp = (e) => {
    if (e.keyCode === 8) {
      focusAndDelete(e);
    }
  };

  const onInputPaste = (e) => {
    try {
      const clipboardData = e.clipboardData || window.clipboardData;
      const pastedData = clipboardData.getData('Text');
      const pastedDataSplit = pastedData.split('');

      if (pastedDataSplit.length === 6 && new RegExp('^\\d+$').test(pastedData.toString())) {
        for (let index = 1; index < 7; index++) {
          setValue(`${parseInt(index)}`, pastedDataSplit[index - 1]);
        }

        setFocus('6');

        e.stopPropagation();
        e.preventDefault();

        setTimeout(() => submit(), 200); // wait for state to update and submit
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Flex as="form" width={300} flexDirection="column">
      <Flex width={1}>
        <Box pr={2}>
          <Input
            autoFocus
            maxLength="1"
            id="1"
            {...register('1')}
            onKeyUp={onKeyUpHandle}
            onPaste={onInputPaste}
            variant="twoFactorInput"
          />
        </Box>
        <Box pr={2}>
          <Input
            maxLength="1"
            id="2"
            {...register('2')}
            onKeyUp={onKeyUpHandle}
            onPaste={onInputPaste}
            variant="twoFactorInput"
          />
        </Box>
        <Box pr={3}>
          <Input
            maxLength="1"
            id="3"
            {...register('3')}
            onKeyUp={onKeyUpHandle}
            onPaste={onInputPaste}
            variant="twoFactorInput"
          />
        </Box>
        <Box pr={2}>
          <Input
            maxLength="1"
            id="4"
            {...register('4')}
            onKeyUp={onKeyUpHandle}
            onPaste={onInputPaste}
            variant="twoFactorInput"
          />
        </Box>
        <Box pr={2}>
          <Input
            maxLength="1"
            id="5"
            {...register('5')}
            onKeyUp={onKeyUpHandle}
            onPaste={onInputPaste}
            variant="twoFactorInput"
          />
        </Box>
        <Box>
          <Input
            maxLength="1"
            id="6"
            {...register('6', { onChange: onLastDigitChange })}
            onKeyUp={onLastDigitKeyUp}
            onPaste={onInputPaste}
            variant="twoFactorInput"
          />
        </Box>
      </Flex>
    </Flex>
  );
};

export default TwoFactorInput;
