/* eslint-disable quotes */
import axios from 'axios';
import { getJwt, getJwtRefresh, removeJwt, setJwt } from 'utils/localstorage';

// api env url config
const apiUrl = process.env.REACT_APP_API_URL || 'https://dev-api-webapp.sba-swiss.com';

// queue for multiple failed 401 requests
let isRefreshing = false;
let failedQueue = [];

// interceptor(middleware) on request
axios.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    const lang = localStorage.getItem('lang') || 'en';
    config.headers = {
      'Content-Type': 'application/json',
      'Content-Language': lang,
      'Accept-Language': lang,
    };

    const authToken = getJwt();
    if (authToken) {
      config.headers.Authorization = `JWT ${authToken}`;
    }

    if (!config._retry) {
      config.url = `${apiUrl}${config.url}`; // append apiUrl to all requests
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

const processQueue = (error) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve();
    }
  });

  failedQueue = [];
};

function errorHasInvalidToken(error) {
  const isInvalidToken =
    error?.response?.status === 401 &&
    error?.response?.data?.code === 'token_not_valid' &&
    error?.response?.data?.detail === 'Given token not valid for any token type';

  return isInvalidToken;
}

function tokenIsNotValid(error) {
  const responseType =
    error?.request?.responseType === 'blob' &&
    error?.response?.data instanceof Blob &&
    error?.response?.data?.type?.toString?.().includes('json')
      ? 'blobJson'
      : 'other';

  if (responseType === 'blobJson') {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        try {
          error.response.data = JSON.parse(reader.result);
          resolve(errorHasInvalidToken(error));
        } catch (e) {
          reject(e);
        }
      };

      reader.onerror = (e) => {
        reject(e);
      };

      reader.readAsText(error.response.data);
    });
  }

  return Promise.resolve(errorHasInvalidToken(error));
}

// interceptor on response
axios.interceptors.response.use(
  (res) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    if (res.headers?.['file-name']) {
      // we want to get this header, that's why we have to make an exception
      return res;
    }
    return res.data;
  },
  async (error) => {
    /**
     * Any status codes that falls outside the range of 2xx cause this function to trigger
     * An error that comes from the API, should have a response key
     * A network error, should have a message key
     */

    // Token refresh, get new acces token and retry failed requests
    const originalRequest = error.config;

    if (!originalRequest._retry && (await tokenIsNotValid(error))) {
      // retry requests have already formed urls, check request interceptor
      originalRequest._retry = true;

      // while the token is refreshing, push failed request in the queue
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then(() => {
            return axios(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      isRefreshing = true;

      // eslint-disable-next-line no-async-promise-executor
      const refreshTokenAndRetry = new Promise(async (resolve) => {
        try {
          // Get refresh token from localstorage
          const refreshToken = getJwtRefresh();
          // API Call
          const data = await jwtRefresh({ refresh: refreshToken });
          // Set new JWT token
          setJwt(data);
          processQueue();
          resolve(axios(originalRequest));
        } catch (e) {
          // on auth/refresh 401 logout
          removeJwt();
          processQueue(err);
          window.location.reload();
        } finally {
          isRefreshing = false;
        }
      });

      return refreshTokenAndRetry;
    }

    const err = error.response ? error.response.data : error.message;

    if (error && error.response && error.response.status === 401 && error.response.data.code === 'user_not_found') {
      removeJwt();
    }

    return Promise.reject(err);
  },
);

/**
 * Users endpoint
 */

export const createUser = (bodyData) => axios.post(`/users/`, bodyData);

export const createTrainingUser = ({ first_name, last_name, email, company, country, marketing_emails }) =>
  axios.post(`/users/`, {
    first_name,
    last_name,
    email,
    company,
    country,
    marketing_emails,

    type: 'api',
    reason: 'SBAS Software training',
  });

export const activateUser = ({ password, invite_token }) =>
  axios.post(`/activation-password/`, { password, invite_token });

export const getUser = () => axios.get(`/users/me/`);

export const updateUser = (data) => axios.patch(`/users/me/`, { ...data });

export const deleteUser = () => axios.delete(`/users/me/`);

export const resendActivation = ({ email }) => axios.post(`/users/resend_activation/`, { email });

export const resetEmail = ({ email }) => axios.post(`/users/reset_email/`, { email });

export const resetEmailConfirm = ({ new_email }) => axios.post(`/users/reset_email_confirm/`, { new_email });

export const resetPassword = ({ email }) => axios.post(`/users/reset_password/`, { email });

export const resetPasswordConfirm = ({ uid, token, new_password }) =>
  axios.post(`/users/reset_password_confirm/`, { uid, token, new_password });

export const setEmail = ({ new_email }) => axios.post(`/users/set_email/`, { new_email });

export const setPassword = ({ new_password, current_password }) =>
  axios.post(`/users/set_password/`, { new_password, current_password });

export const subscribeMailingList = ({ email }) => axios.post(`/subscribe/`, { email });

export const confirmViewed = ({ type }) => axios.post(`/users/confirmation/`, { type });

export const confirmTrainingVideo = ({ uid, token }) =>
  axios.post(`/users/confirmation/`, { uid, token, type: 'video' });

/**
 * Auth and 2FA endpoints
 */
export const login = ({ email, password, device_hash }) =>
  axios.post(`/auth/jwt/login/`, { email, password, device_hash });

export const jwtRefresh = ({ refresh }) => axios.post(`/auth/jwt/refresh/`, { refresh });

export const login2FA = (user_id, user_token, code, remember) =>
  axios.post(`/auth/jwt/2fa/`, { user_id, user_token, code, remember });

export const generate2FA = () => axios.post(`/users/2fa/generate/`);

export const activate2FA = (device_hash, code) => axios.post(`/users/2fa/activate/`, { device_hash, code });

export const deactivate2FA = (code) => axios.post(`/users/2fa/deactivate/`, { code });

/**
 * Persons/patients endpoints
 */
export const getPatients = () => axios.get(`/persons/`);

export const exportPatient = (patient_id) => axios.get(`/persons/${patient_id}/export/`);

export const importPatient = (patientContent) => axios.post(`/import-person/`, patientContent);

export const importPatientBloodTest = (patient_id, bloodTestContent) =>
  axios.post(`/persons/${patient_id}/import-blood-test/`, bloodTestContent);

export const createPatient = (patientData) => axios.post('/persons/', patientData);

export const deletePatient = (patient_id) => axios.delete(`/persons/${patient_id}`);

export const getPatient = (patient_id) => axios.get(`/persons/${patient_id}/`);

export const updatePatient = ({ patient_id, id, uid, birth_date, gender, comment }) =>
  axios.put(`/persons/${patient_id}/`, { id, uid, birth_date, gender, comment });

export const getBloodTests = (patient_id) => axios.get(`/persons/${patient_id}/blood-tests/`);

/**
 * @function createBloodTest
 */
export const createBloodTest = (patient_id) => axios.post(`/persons/${patient_id}/blood-tests/`);

export const validateBloodTest = (patient_id, blood_test_id) =>
  axios.post(`/persons/${patient_id}/blood-tests/${blood_test_id}/validate/`);

export const deleteBloodTest = (patient_id, blood_test_id) =>
  axios.delete(`/persons/${patient_id}/blood-tests/${blood_test_id}/`);

export const getBloodTest = (patient_id, blood_test_id) =>
  axios.get(`/persons/${patient_id}/blood-tests/${blood_test_id}/`);

/**
 * @function updateBloodTest
 * Update basic blood test info
 */
export const updateBloodTest = ({
  patient_id,
  blood_test_id,
  state,
  blood_test_date,
  convention_type,
  is_on_treatment,
  is_pregnant,
}) =>
  axios.put(`/persons/${patient_id}/blood-tests/${blood_test_id}/`, {
    state,
    blood_test_date,
    convention_type,
    is_on_treatment,
    is_pregnant,
  });

/**
 * @function duplicateBloodTest
 * Duplicate blood test
 */
export const duplicateBloodTest = ({ patient_id, blood_test_id }) =>
  axios.post(`/persons/${patient_id}/blood-tests/${blood_test_id}/duplicate/`);

/**
 * @function getBloodTestParameters
 * Get a list of  all the parameters saved on this blood_test_id
 */
export const getBloodTestParameters = (patient_id, blood_test_id) =>
  axios.get(`/persons/${patient_id}/blood-tests/${blood_test_id}/values/`);

/**
 * @function updateBloodTestParameter
 * Update a parameter value or unit
 */
export const updateBloodTestParameter = ({ patient_id, blood_test_id, code, value, unit }) =>
  axios.put(`/persons/${patient_id}/blood-tests/${blood_test_id}/values/`, {
    code,
    value,
    unit,
  });

/**
 * @function updateBloodTestParameters
 * Bulk create/update of paramaters, used for duplicating a blood test
 */
export const updateBloodTestParameters = (patient_id, blood_test_id, values) =>
  axios.put(`/persons/${patient_id}/blood-tests/${blood_test_id}/bulk-values/`, values); // [ { code, value, unit} , { … } , ... ]

/**
 * @function deleteBloodTestParameter
 * Remove parameter from blood test
 */
export const deleteBloodTestParameter = (patient_id, blood_test_id, code) =>
  axios.delete(`/persons/${patient_id}/blood-tests/${blood_test_id}/values/${code}/`);

/**
 * @function insertBloodTestEmptyParameters
 * Bulk create/update of paramaters, used for duplicating a blood test
 */
export const insertBloodTestEmptyParameters = (patient_id, blood_test_id, params) =>
  axios.put(`/persons/${patient_id}/blood-tests/${blood_test_id}/bulk-parameters/`, params); // [ { code, unit} , { … } , ... ]

/**
 * @function getAllParameters
 * Get a list of all parameters with full info group, units etc. used in search, ref values etc
 */
export const getAllParameters = () => axios.get(`/parameters/`);

/**
 * @function createReport
 * Create a report
 */
export const createReport = (patient_id, blood_test_id, code) =>
  axios.post(`/persons/${patient_id}/blood-tests/${blood_test_id}/reports/`, [
    {
      code,
      include_explanation_of_nan_parameters: true,
      top_k: 10,
      max_explanation_parameters: -1,
    },
  ]);

/**
 * @function getReportDetails
 * Fetch report icds (prognosis subgroups), used in report table data
 */
export const getReportDetails = (patient_id, blood_test_id, report_id) =>
  axios.get(`/persons/${patient_id}/blood-tests/${blood_test_id}/reports/${report_id}/`);

/**
 * @function getBillingEventsHistory
 * Get reports stats
 */
export const getBillingEventsHistory = () => axios.get(`/billing-events/`);

/**
 * @function getLineSvgChart
 */
export const getLineSvgChart = (result) => axios.post(`/svg/?path=/chart/line`, result);

/**
 * @function getPDF
 * Get PDF file as a blob
 */
export const getPDF = (patient_id, blood_test_id, report_id) =>
  axios.get(
    `/persons/${patient_id}/blood-tests/${blood_test_id}/reports/${report_id}/pdf/`,
    {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/pdf',
      },
    },
  );

export const getOrganization = () => axios.get(`/users/me/organisation`);

export const updateOrganization = (org) => axios.put(`/users/me/organisation`, org);

export const getCountries = () => axios.get('/countries');

export const getCountryGroup = (country_group_id) => axios.get(`/country-group/${country_group_id}`);

export const contactSupport = ({ title, message }) => axios.post(`/contact-support/`, { title, message });

export const getStripePricingTable = () => axios.get('/stripe/pricing-table/');

export const createStripeCheckout = (price_id) => axios.post(`/stripe/checkout-create/`, { price_id });
