import { useReportIcd } from '..';
import { SymptomsCard } from './SymptomsCard';

export function SymptomsContent({ reportDetails }) {
  const diseases = reportDetails.result?.results;
  const { selectedIcdId, setSelectedIcdId } = useReportIcd();

  let selected = diseases.findIndex((d) => d.code === selectedIcdId);

  if (selected === -1) {
    selected = 0;
  }

  const prev = () => setSelectedIcdId(diseases[selected - 1].code);
  const next = () => setSelectedIcdId(diseases[selected + 1].code);

  if (!diseases[selected]) {
    return null;
  }

  return (
    <SymptomsCard
      diseaseCount={diseases.length}
      next={next}
      prev={prev}
      selectedIndex={selected}
      disease={diseases[selected]}
      diseaseColor={reportDetails.result?.visualization?.legend?.[selected]?.color}
    />
  );
}
