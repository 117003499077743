import { useState } from 'react';
import { Flex, Box, Text, Heading } from 'rebass';
import Button from 'components/common/Button';
import { IoIosArrowForward } from 'react-icons/io';
import useGlobal from 'hooks/global';

import { generate2FA, activate2FA, deactivate2FA, getUser } from 'api';
import QRCode from 'qrcode.react';
import TwoFactorInput from './TwoFactorInput';
import Message from 'components/common/Message';
import { t } from '@lingui/macro';
import { Trans } from '@lingui/macro';
import { LoaderSmall } from 'components/common/Loader';

const TwoFactorSettings = () => {
  const [generated, setGenerated] = useState(undefined);
  const [message, setMessage] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [globalState, globalActions] = useGlobal();
  const [disableConfirm, setDisableConfirm] = useState(false);
  const has2fa = globalState.user.has_2fa;

  const generate = async () => {
    setIsLoading(true);
    setMessage({ type: '', text: '' });

    try {
      const response2fa = await generate2FA();
      setGenerated(response2fa);
    } catch (e) {
      setMessage({ type: 'error', text: e.details });
    } finally {
      setIsLoading(false);
    }
  };

  const activate = async (code) => {
    setIsLoading(true);
    setMessage({ type: '', text: '' });

    try {
      await activate2FA(generated.device_hash, code);

      const user = await getUser(); // get user
      await globalActions.setUser(user);
      setGenerated(undefined);
    } catch (e) {
      const errorMessage = t`There was problem activating 2FA`;
      setMessage({ type: 'error', text: errorMessage });
    } finally {
      setIsLoading(false);
    }
  };

  const deactivate = async (code) => {
    setMessage({ type: '', text: '' });

    try {
      await deactivate2FA(code);

      const user = await getUser(); // get user
      await globalActions.setUser(user);

      const successMessage = t`2-Factor authentication disabled`;
      setMessage({ type: 'ok', text: successMessage });
      setDisableConfirm(false);
    } catch (e) {
      if (e && e.details) {
        setMessage({ type: 'error', text: e.details });
      } else {
        const errorMessage = t`There was problem deactivating 2FA`;
        setMessage({ type: 'error', text: errorMessage });
      }
    }
  };

  return (
    <Flex width={[1, 1, 1, 1, 3 / 5]} minHeight={500}>
      <Box p={3} width={1}>
        {has2fa && (
          <Box>
            <Heading>
              <Trans>Account secured</Trans>
            </Heading>
            <Text my={3}>
              <Trans>2-Factor authentication already enabled for this account.</Trans>
            </Text>
            {!disableConfirm && (
              <Button isLoading={isLoading} disabled={isLoading} onClick={() => setDisableConfirm(true)}>
                <Trans>Disable 2FA</Trans>
              </Button>
            )}
            {disableConfirm && (
              <Box>
                <Text fontWeight="bold" mt={2} color="text">
                  <Trans>Enter the authentication code to disable 2FA</Trans>
                  {isLoading && <LoaderSmall dark inline />}
                </Text>
                <TwoFactorInput activate={deactivate} />
              </Box>
            )}
          </Box>
        )}
        {!has2fa && !generated && (
          <Box>
            <Heading>
              <Trans>Secure your account</Trans>
            </Heading>
            <Text my={3}>
              <Trans>You’ll be using a separate app to genarate security codes.</Trans>
            </Text>
            <Text my={3}>
              <IoIosArrowForward />{' '}
              <Trans>
                Download either Google Authenticator, Microsoft Authenticator, Authy or similar app for your mobile
                device
              </Trans>
            </Text>
            <Text my={3}>
              <IoIosArrowForward /> <Trans>Scan the QR code with the APP and enter the provided 6-digit code</Trans>
            </Text>
            <Button isLoading={isLoading} disabled={isLoading} onClick={generate}>
              <Trans>Setup 2FA</Trans>
            </Button>
          </Box>
        )}
        {!has2fa && generated && (
          <Box>
            <Text>
              <Trans>Use your new security app to scan the code</Trans>
            </Text>
            <Box p={4}>
              <QRCode fgColor="#1b3667" size={220} value={generated.qr} />
            </Box>
            <Text mb={1}>
              <Trans>Store your secret key safely, you will need it if you lose your device</Trans>
            </Text>
            <Text mb={4} p={3} bg="gray">
              {generated.secret}
            </Text>
            {message.type !== 'ok' && (
              <Box>
                <Text fontWeight="bold" mt={2} color="text">
                  <Trans>Enter the 6-digit authentication code provided by your device to enable 2FA</Trans>
                  {isLoading && <LoaderSmall dark inline />}
                </Text>
                <TwoFactorInput activate={activate} />
              </Box>
            )}
          </Box>
        )}
        <br />
        {message.text && <Message type={message.type} message={message.text} />}
      </Box>
    </Flex>
  );
};

export default TwoFactorSettings;
