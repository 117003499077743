import { Component } from 'react';
import ReactPlayer from 'react-player';
import posterSrc from './videoPoster.png';
import styles from './VideoPlayer.module.css';

class VideoPlayer extends Component {
  state = {
    url: this.props.url, // 'SBA_FINAL_01_web.mp4',
    playing: false,
    volume: 0.8,
    played: 0,
  };

  handleEnded = () => {
    this.props.onVideoEnd();
  };

  render() {
    const { url, playing, volume, loop } = this.state;

    return (
      <div className="app">
        <section className="section">
          <div
            className={styles.playerWrapper}
            onContextMenu={(e) => {
              e.preventDefault();
              return false;
            }}
          >
            <ReactPlayer
              ref={this.ref}
              className="react-player"
              width="100%"
              height="100%"
              controls={true}
              url={url}
              playing={playing}
              loop={loop}
              volume={volume}
              config={{
                file: {
                  attributes: {
                    poster: posterSrc,
                  },
                },
              }}
              onEnded={this.handleEnded}
              onError={(e) => console.log('onError', e)}
            />
          </div>
        </section>
      </div>
    );
  }
}

export default VideoPlayer;
