import { Fragment } from 'react';
import { Box, Flex, Text, Heading } from 'rebass';
import { Label, Radio, Select } from '@rebass/forms';
import { FaExclamationTriangle } from 'react-icons/fa';
import { Trans } from '@lingui/macro';
import DatePicker from 'components/common/DatePicker';

/**
 * A component that handles the input of basic blood test informations
 */
const AddBasicInfo = ({ register, onChange, bloodTest, gender, disabled, user, setValue, patientAge }) => {
  return (
    <Fragment>
      <Heading color="text" fontWeight={400} pb={3}>
        {bloodTest.name}
      </Heading>
      <Flex as="form" variant="fluid" mb={3}>
        <Box pr={[0, 3]} width={[1, 1 / 2, 1 / 2, 1 / 4, 1 / 5]}>
          <Label>
            <Trans>Test date</Trans>
          </Label>

          <DatePicker
            defaultValue={bloodTest.blood_test_date}
            name="blood_test_date"
            register={register}
            registerProps={{ required: true }}
            setFormValue={setValue}
            onChange={onChange}
            disableFuture
          />
        </Box>

        <Box pr={[0, 0, 0, 5, 5]} width={[1, 1 / 2, 1 / 2, 1 / 4, 1 / 7]}>
          <Label>
            <Trans>Units</Trans>
          </Label>
          <Select
            defaultValue={bloodTest.convention_type || user.convention_type}
            {...register('convention_type', { onChange })}
          >
            {bloodTest.convention_type === null && <option value=""></option>}
            <option value="SI">SI</option>
            <option value="Conv">CONV</option>
          </Select>
        </Box>

        <Box mt={[2, 2, 2, 0, 0]} pr={[0, 0, 0, 0, 2]} width={[3 / 4, 1 / 2, 1 / 2, 1 / 4, 1 / 5]}>
          <Label pb={3}>
            <Trans>Patient receiving treatment</Trans>
          </Label>
          <Flex pb={2} width={[1, 1, 1, 3 / 4, 3 / 4]}>
            <Label>
              <Radio
                defaultChecked={bloodTest.is_on_treatment === true}
                {...register('is_on_treatment', { onChange, required: true })}
                value={'true'}
              />
              <Trans>Yes</Trans>
            </Label>
            <Label>
              <Radio
                defaultChecked={bloodTest.is_on_treatment === false}
                {...register('is_on_treatment', { onChange, required: true })}
                value={'false'}
              />
              <Trans>No</Trans>
            </Label>
          </Flex>
        </Box>
        {gender === 'F' && (
          <Box mt={[2, 2, 2, 0, 0]} width={[3 / 4, 1 / 2, 1 / 2, 1 / 4, 1 / 5]}>
            <Label pb={3}>
              <Trans>Patient pregnant</Trans>
            </Label>
            <Flex pb={2} width={[1, 1, 1, 3 / 4, 3 / 4]}>
              <Label>
                <Radio
                  defaultChecked={bloodTest.is_pregnant === true}
                  {...register('is_pregnant', { onChange })}
                  value={'true'}
                />
                <Trans>Yes</Trans>
              </Label>
              <Label>
                <Radio
                  defaultChecked={bloodTest.is_pregnant === false}
                  {...register('is_pregnant', { onChange })}
                  value={'false'}
                />
                <Trans>No</Trans>
              </Label>
            </Flex>
          </Box>
        )}
        {disabled && (
          <Flex pt={3} width={[1, 1, 1, 1, 1]} sx={{ alignItems: 'center', color: 'text' }}>
            <Box color="orange">
              <FaExclamationTriangle style={{ width: 36 }} />
            </Box>
            <Text key="warning-pregnant" color="orange" ml={1}>
              <Trans>Fill the above fields first!</Trans>
            </Text>
          </Flex>
        )}
        {bloodTest.is_pregnant && (
          <Flex pt={3} width={[1, 1, 1, 1, 1]} sx={{ alignItems: 'center', color: 'text' }}>
            <FaExclamationTriangle style={{ width: 36 }} />
            <Text key="warning-pregnant" color="text" ml={2}>
              <Trans>Patient is pregnant, editing disabled.</Trans>
            </Text>
          </Flex>
        )}
        {patientAge != null && patientAge < 18 && (
          <Flex pt={3} width={[1, 1, 1, 1, 1]} sx={{ alignItems: 'center', color: 'text' }}>
            <FaExclamationTriangle style={{ width: 36 }} />
            <Text key="warning-pregnant" color="text" ml={2}>
              <Trans>
                {`Patient age at the time of the test was ${patientAge}, editing disabled.`}
              </Trans>
            </Text>
          </Flex>
        )}
        {bloodTest.is_on_treatment && (
          <Flex pt={3} width={[1, 1, 1, 1, 1]} sx={{ alignItems: 'center', color: 'text' }}>
            <FaExclamationTriangle style={{ width: 36 }} />
            <Text key="warning-pregnant" color="text" sx={{ lineHeight: '1.6rem' }} ml={2}>
              <Trans>
                Therapy can significantly change blood test results and, therefore, results of SBAS Clinical Decision
                Support Software.
              </Trans>
            </Text>
          </Flex>
        )}
      </Flex>
    </Fragment>
  );
};

export default AddBasicInfo;
