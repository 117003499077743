import { Box, Flex } from 'rebass';

export function InputFormContainer({ title, sx, ...props }) {
  return (
    <Box variant={'cardSimple'} sx={sx}>
      <Flex sx={{ borderBottom: 'solid 1px rgba(0, 0, 0, 0.05)', paddingBottom: '8px', color: 'text' }}>{title}</Flex>
      {props.children}
    </Box>
  );
}
