import { useEffect, useState } from 'react';

export function ModelImage({ modelCode }) {
  const [image, setImage] = useState(undefined);

  useEffect(() => {
    import(`./assets/${modelCode}_black.png`)
      .then((image) => {
        setImage(image.default);
      })
      .catch(() => {
        // no thing
      });
  }, [modelCode]);

  return (
    <div
      style={{
        display: 'flex',
        width: '20px',
        height: '20px',
        marginRight: '0.5em',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {image ? (
        <img
          src={image}
          alt=""
          style={{
            maxHeight: '20px',
            maxWidth: '20px',
          }}
        />
      ) : (
        <div style={{ fontWeight: 'lighter', fontSize: '0.6em' }}>{modelCode.substring(0, 3)}</div>
      )}
    </div>
  );
}
