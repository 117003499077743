import { Component } from 'react';
import { withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';

if (process.env.REACT_APP_GOOGLE_ANALYTICS_ID) {
  ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);
}

class GAPageView extends Component {
  componentDidUpdate(prevProps) {
    const { pathname } = this.props.location;
    if (this.props.location.pathname !== prevProps.location.pathname) {
      ReactGA.pageview(pathname);
    }
  }

  render() {
    return null;
  }
}

export default withRouter(GAPageView);
