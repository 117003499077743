import { useRef, useEffect } from 'react';
export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };

    let id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, [delay]);
};
