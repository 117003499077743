import { Input, Label, Select } from '@rebass/forms';
import { getSharedData } from 'components/common/SharedData/SharedData';
import { useEffect, useMemo, useRef } from 'react';
import { useWatch } from 'react-hook-form';
import { Box, Flex, Text } from 'rebass';

export function OrganizationInputForm({ register, errors, setFormValue, control }) {
  const countries = useRef(getSharedData('COUNTRIES'));
  return (
    <>
      <Flex variant="fluid">
        <Box pr={[0, 0, 0, 0, 3]} width={['100%', '100%', '100%', '100%', 1 / 2]}>
          <Label mt={2}>Organization Name *</Label>
          <Input
            className={errors.organization_name ? 'error' : ''}
            {...register('organization_name', { required: true })}
          />
          {errors.organization_name && (
            <Text py={2} fontSize={1} color="orange">
              Field is required
            </Text>
          )}
        </Box>
        <Box width={['100%', '100%', '100%', '100%', 1 / 2]}>
          <Label mt={2}>Organization Country *</Label>
          <Select
            className={errors.organization_country ? 'error' : ''}
            {...register('organization_country', { required: true })}
          >
            <option></option>
            {countries.current
              .filter((c) => c.tax_ids.length > 0)
              .map((country, index) => (
                <option key={index} value={country.code}>
                  {country.name}
                </option>
              ))}
          </Select>
          {errors.organization_country && (
            <Text py={2} fontSize={1} color="orange">
              Country is required
            </Text>
          )}
        </Box>
      </Flex>
      <Flex variant="fluid">
        <Box width={'100%'}>
          <Label mt={2}>Organization Address *</Label>
          <Input
            className={errors.organization_address_line_1 ? 'error' : ''}
            {...register('organization_address_line_1', { required: true })}
          />
          {errors.organization_address_line_1 && (
            <Text py={2} fontSize={1} color="orange">
              Field is required
            </Text>
          )}
        </Box>
      </Flex>
      <Flex variant="fluid">
        <Box width={'100%'}>
          <Label mt={2}>Organization Address Line 2</Label>
          <Input
            className={errors.organization_address_line_2 ? 'error' : ''}
            {...register('organization_address_line_2', { required: false })}
          />
          {errors.organization_address_line_2 && (
            <Text py={2} fontSize={1} color="orange">
              Field is required
            </Text>
          )}
        </Box>
      </Flex>
      <Flex variant="fluid">
        <Box pr={[0, 0, 0, 0, 3]} width={['100%', '100%', '100%', '100%', 1 / 2]}>
          <Label mt={2}>Organization Zip/Postal Code *</Label>
          <Input
            className={errors.organization_zip_postal_code ? 'error' : ''}
            {...register('organization_zip_postal_code', { required: true })}
          />
          {errors.organization_zip_postal_code && (
            <Text py={2} fontSize={1} color="orange">
              Field is required
            </Text>
          )}
        </Box>
        <Box width={['100%', '100%', '100%', '100%', 1 / 2]}>
          <Label mt={2}>Organization City *</Label>
          <Input
            className={errors.organization_city ? 'error' : ''}
            {...register('organization_city', { required: true })}
          />
          {errors.organization_city && (
            <Text py={2} fontSize={1} color="orange">
              City is required
            </Text>
          )}
        </Box>
      </Flex>

      <CountryRelatedTaxInputs
        register={register}
        errors={errors}
        countries={countries.current}
        setValue={setFormValue}
        control={control}
      />
    </>
  );
}

function CountryRelatedTaxInputs({ register, errors, countries, setValue, control }) {
  const country = useWatch({
    control,
    name: 'organization_country',
  });

  const TaxIds = useMemo(() => countries.find((c) => c.code === country)?.tax_ids || [], [country, countries]);

  useEffect(() => {
    setValue('organization_tax_id_type', TaxIds.length === 1 ? TaxIds[0].code : '');
  }, [country, setValue, TaxIds]);

  return (
    <Flex variant="fluid">
      <Box pr={[0, 0, 0, 0, 3]} width={['100%', '100%', '100%', '100%', 1 / 2]}>
        <Label mt={2}>Tax ID Type *</Label>
        <Select
          className={errors.organization_tax_id_type ? 'error' : ''}
          {...register('organization_tax_id_type', { required: true })}
        >
          {/* <option></option> */}
          {TaxIds.map((item, index) => (
            <option key={index} value={item.code}>
              {item.name}
            </option>
          ))}
        </Select>

        {errors.organization_tax_id_type && (
          <Text py={2} fontSize={1} color="orange">
            Tax ID type is required
          </Text>
        )}
      </Box>

      <TaxIdValue errors={errors} register={register} control={control} taxIds={TaxIds} />
    </Flex>
  );
}

function TaxIdValue({ errors, register, control, taxIds }) {
  const taxType = useWatch({
    control,
    name: 'organization_tax_id_type',
  });

  const placeHolder = taxIds.find((t) => t.code === taxType)?.example;

  return (
    <Box width={['100%', '100%', '100%', '100%', 1 / 2]}>
      <Label mt={2}>Tax ID Value *</Label>
      <Input
        placeholder={placeHolder}
        className={errors.organization_tax_id_value ? 'error' : ''}
        {...register('organization_tax_id_value', { required: true })}
      />
      {errors.organization_tax_id_value && (
        <Text py={2} fontSize={1} color="orange">
          Tax ID value is required
        </Text>
      )}
    </Box>
  );
}
