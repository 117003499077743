const defaults = {
  breakpoints: ['40em', '48em', '60em', '68em', '72em'],
  fontSizes: [12, 14, 16, 18, 20, 32, 48, 64],
  colors: {
    blue: '#07c',
    lightgray: '#edeff3',
    lightergray: '#f7f7f7',
    mediumgray: '#D2D2D2',
    primary: '#1b3667',
    primaryLight: '#798bac',
    orange: '#f77b13',
    lightorange: '#F7D2B3',
    darkgray: '#CED3DE',
    darkergray: '#8F9299',
    gray: '#EDEFF3',
    text: '#495e85',
    white: '#fff',
    red: '#FC2E08',
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fonts: {
    heading: 'inherit',
  },
  fontWeights: {
    body: 400,
    heading: 400,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  shadows: {
    small: '0 0 4px rgba(0, 0, 0, .125)',
    large: '0 0 24px rgba(0, 0, 0, .125)',
  },
  variants: {
    questionare: {
      label: {
        fontSize: 16,
        alignItems: 'center',
        cursor: 'pointer',
      },
    },
    fluid: {
      flexFlow: 'row wrap',
    },
    container: {
      flexDirection: 'column',
      maxWidth: 1470,
      padding: [3],
      width: '100%',
      flexGrow: 1,
      flex: '0 0 auto',
    },
    row: {
      alignItems: ['flex-start', 'flex-start', 'center'],
      borderTop: '1px solid',
      borderColor: 'gray',
      flexFlow: 'row wrap',
      justifyContent: 'space-between',
      padding: 0,
    },
    card: {
      padding: 4,
      width: ['100%', '500px'],
      alignSelf: ['center', 'center', 'center', 'center', 'center'],
      mr: [0, 0, 0, 0, 0],
      bg: 'white',
      borderRadius: 16,
      boxShadow: '0 15px 30px rgba(0,0,0,.1)',
      justifyContent: 'center',
    },
    cardSimple: {
      padding: 3,
      width: ['100%'],
      alignSelf: ['center'],
      mr: [0],
      bg: 'white',
      borderRadius: 8,
      boxShadow: '0 0px 20px rgba(0,0,0,.05)',
      justifyContent: 'center',
    },
    topNav: {
      flexDirection: 'column',
      maxWidth: 1470,
      padding: [3],
      width: '100%',
    },
    tableHead: {
      borderTop: '1px solid',
      borderColor: 'gray',
      color: 'darkergray',
      cursor: 'pointer',
      flexFlow: 'row wrap',
      py: 2,
      width: '100%',
      mt: 2,
      label: {
        cursor: 'pointer',
        fontSize: 1,
        '&:hover': {
          color: 'orange',
        },
      },
    },
    dashboard: {
      row: {
        borderTop: '1px solid',
        borderColor: 'gray',
        color: 'primary',
        cursor: 'pointer',
        flexFlow: 'row wrap',
        py: 2,
        fontSize: [2, 2, 2, 2, 2],
        '&:hover': {
          // bg: 'lightgray',
          color: 'orange',
        },
      },
    },
    reports: {
      container: {
        alignItems: 'center',
        bg: 'lightgray',
        borderTop: '1px solid',
        borderColor: 'darkgray',
        flexDirection: 'column',
        paddingBottom: 6,
        width: '100%',
        flex: '0 0 auto',
        minHeight: 700,
      },
      closedReport: {
        bg: 'white',
        border: '1px solid',
        borderColor: 'darkgray',
        cursor: 'pointer',
        flexDirection: ['column', 'column', 'row'],
        my: 2,
        mx: 2,
        p: 3,
        width: '100%',
        '&:hover': {
          bg: 'lightgray',
        },
      },
      uncompletedReport: {
        bg: 'white',
        border: '1px solid',
        borderColor: 'darkgray',
        flexDirection: ['column', 'column', 'row'],
        my: 2,
        mx: 2,
        p: 3,
        width: '100%',
      },
      openedReport: {
        flexDirection: 'column',
        bg: 'white',
        p: 3,
        my: 2,
        mx: 2,
        border: '1px solid',
        borderColor: 'darkgray',
        width: '100%',
      },
    },
    patient: {
      actions: {
        color: 'text',
        alignSelf: 'flex-start',
        'svg:hover': { color: 'orange', cursor: 'pointer' },
        width: ['100%', '100%', '100%', '30%', '30%'],
      },
      container: {
        flexDirection: 'column',
        px: 0,
        py: 2,
        textAlign: 'left',
        width: '100%',
        mb: 3,
      },
    },
    bloodTest: {
      parametersGroup: {
        py: 3,
        px: 2,
        borderBottom: '1px solid',
        borderColor: 'darkgray',
        '&:hover': {
          bg: 'lightgray',
          cursor: 'pointer',
        },
      },
      parameter: {
        alignItems: 'center',
        borderBottom: '1px solid',
        borderColor: 'gray',
        color: 'primary',
        flexFlow: 'row wrap',
        px: 2,
        py: [3, 3, 2, 2, 0],
        input: {
          display: 'inline-block',
          padding: 1,
          width: '105px',
          maxWidth: '105px',
        },
      },
    },
    bloodTestReport: {
      container: {
        borderTop: '1px solid',
        borderColor: 'darkgray',
        marginTop: 2,
      },
    },
    textareaWrap: {
      textarea: {
        borderColor: 'gray',
        borderStyle: 'solid',
        borderWidth: '1px',
        fontSize: 16,
        p: 3,
        resize: 'none',
        width: '100%',
      },
    },
  },
  text: {
    // variant
    inline: {
      display: 'inline-block',
      orange: {
        color: 'orange',
      },
    },
    clickable: {
      color: 'text',
      cursor: 'pointer',
      display: 'inline-block',
      '&:hover': { color: 'orange' },
      a: {
        color: 'white',
        cursor: 'pointer',
        display: 'inline-block',
        textDecoration: 'none',
        '&:hover': { color: 'orange' },
      },
    },
  },
  buttons: {
    // variant
    primary: {
      borderRadius: 25,
      color: 'white',
      cursor: 'pointer',
      fontWeight: 500,
      height: 50,
      py: 2,
      px: 4,
      '&:hover': {
        opacity: 0.7,
        boxShadow: 'none',
      },
      '&:disabled': {
        cursor: 'default',
        background: 'gray',
      },
      outline: 'none',
    },
    orange: {
      background: 'linear-gradient(to bottom,#ff9500 0,#f57517 100%)',
      borderRadius: 25,
      boxShadow: '0 4px 8px rgba(0,0,0,.2)',
      color: 'white',
      cursor: 'pointer',
      fontWeight: 500,
      height: 50,
      padding: 2,
      px: 4,
      outline: 'none',
      '&:hover': {
        opacity: 0.7,
        boxShadow: 'none',
      },
      '&:disabled': {
        cursor: 'default',
        background: 'gray',
      },
    },
    red: {
      background: 'linear-gradient(to bottom,#FF775E 0,#FC2E08 100%)',
      borderRadius: 25,
      boxShadow: '0 4px 8px rgba(0,0,0,.2)',
      color: 'white',
      cursor: 'pointer',
      fontWeight: 500,
      height: 50,
      padding: 2,
      px: 4,
      outline: 'none',
      '&:hover': {
        opacity: 0.7,
        boxShadow: 'none',
      },
      '&:disabled': {
        cursor: 'default',
        background: 'gray',
      },
    },
    small: {
      fontWeight: 200,
      height: 'auto',
      padding: 2,
      margin: 1,
      marginLeft: 0,
      outline: 'none',

      // border: '1px solid',
      //borderColor: 'darkerhray',
      bg: 'lightgray',
      fontSize: '12px',
      color: 'text',
      cursor: 'pointer',
      '&:hover': {
        bg: 'primary',
        color: 'white',
      },
    },
    textOnly: {
      '&:hover': {
        bg: 'lightgray',
      },
    },
  },
  forms: {
    input: {
      bg: 'white',
      borderColor: 'gray',
      padding: 3,
      lineHeight: '20px',
      fontFamily: 'Roboto, sans-serif',
      '&.error': {
        borderBottom: '1px solid',
        borderBottomColor: 'orange',
      },
      '&.error-important': {
        border: '1px solid',
        borderColor: 'orange',
      },
      '&:focus': {
        outline: 'none',
      },
    },
    twoFactorInput: {
      border: 'none',
      borderBottom: '1px solid',
      borderColor: 'darkergray',
      fontWeight: 'unset',
      outline: 'none',
      fontSize: '1.625rem',
      verticalAlign: 'top',
      textAlign: 'center',
      height: '70px',
      width: '100%',
      padding: 0,
      borderRadius: 0,
      color: 'text',
    },
    select: {
      bg: 'white',
      borderColor: 'gray',
      padding: 3,
      borderRadius: 0,
      lineHeight: '20px',
      '&.error': {
        borderBottom: '1px solid',
        borderBottomColor: 'orange',
      },
      '&:focus': {
        outline: 'none',
      },
    },
    textarea: {
      borderColor: 'gray',
      fontFamily: 'Roboto, sans-serif',
      '&.error': {
        borderBottom: '1px solid',
        borderBottomColor: 'orange',
      },
      '&:focus': {
        outline: 'none',
      },
    },

    label: {
      py: 2,
      fontSize: 14,
      color: 'text',
      '&.error': {
        svg: {
          fill: 'orange',
        },
        color: 'orange',
      },
    },
    radio: {
      cursor: 'pointer',
      bg: 'white',
      lineHeight: '16px',
    },
    checkbox: {},
  },
};

export default defaults;
