import { useState } from 'react';
import { Heading, Box, Flex, Text, Image } from 'rebass';
import { Input, Label } from '@rebass/forms';
import { withRouter } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Logo from 'assets/sba-logo.svg';
import SbaGraph from 'assets/sba-graph-2.png';
import Button from 'components/common/Button';
import Message from 'components/common/Message';
import { renderError } from 'utils';

import { resetPassword } from 'api';

/**
 * @function ResetPassword - request a password reset page
 */
const ResetPassword = () => {
  const [isLoading, handleIsLoading] = useState(false);
  const [response, handleResponse] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    handleIsLoading(true);
    handleResponse();
    try {
      await resetPassword(data);
      handleResponse({ success: 'Password reset link sent, check your inbox.' });
    } catch (e) {
      handleResponse({ error: renderError(e) });
    } finally {
      handleIsLoading(false);
    }
  };

  return (
    <Flex
      variant="container"
      alignItems="center"
      justifyContent="flex-start"
      width="100%"
      sx={{ height: '100vh' }}
      bg="lightgray"
      maxWidth="100%"
    >
      <Image m={5} src={Logo} alignSelf="center" width={300} />
      <Box variant="card">
        <Heading as="h3" color="primary" pb={1} textAlign="center">
          Reset your password
        </Heading>
        <Box as="form" mt={3} onSubmit={handleSubmit(onSubmit)}>
          <Label>Enter your user account's verified email address and we will send you a password reset link:</Label>
          <Input
            className={errors.email ? 'error' : ''}
            {...register('email', { required: true })}
            type="email"
            autoFocus
          />
          {errors.email && (
            <Text py={2} fontSize={1} color="orange">
              Email is required!
            </Text>
          )}
          <Flex justifyContent="flex-end">
            <Button type="submit" mt={4} mb={4} isLoading={isLoading}>
              Submit
            </Button>
          </Flex>
          {response && <Message message={response.error || response.success} type={response.error ? 'error' : 'ok'} />}
        </Box>
      </Box>
      <Flex justifyContent="center" p={4}>
        <Image m={4} width={['80%', '50%', '30%']} alignSelf="center" src={SbaGraph} />
      </Flex>
    </Flex>
  );
};

export default withRouter(ResetPassword);
