import { Trans, t } from '@lingui/macro';
import { Input, Label, Select } from '@rebass/forms';
import { getCountries, getOrganization, getUser, updateOrganization } from 'api';
import Loader from 'components/common/Loader';
import { useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { Box, Flex, Heading, Text } from 'rebass';
import Button from 'components/common/Button';
import { renderError } from 'utils';
import { Link } from 'react-router-dom';
import Message from 'components/common/Message';
import useGlobal from 'hooks/global';
import { getSharedData } from 'components/common/SharedData/SharedData';

export default function Organization() {
  const [, globalActions] = useGlobal();

  const [state, setState] = useState({
    isLoading: true,
    error: undefined,
    data: undefined,
    countries: undefined,
  });

  const [updateState, setUpdateState] = useState({
    isSaving: false,
    error: undefined,
    message: undefined,
  });

  useEffect(() => {
    const fetch = async () => {
      try {
        const org = await getOrganization();

        let countries = getSharedData('COUNTRIES');

        if (!countries) {
          countries = await getCountries();
        }

        setState({
          isLoading: false,
          data: org,
          error: undefined,
          countries,
        });
      } catch (error) {
        setState({
          isLoading: false,
          data: undefined,
          error: renderError(error),
          countries: undefined,
        });
      }
    };

    fetch();
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const onSubmit = async (data) => {
    setUpdateState({
      error: undefined,
      isSaving: true,
      message: undefined,
    });

    try {
      await updateOrganization({ organisation: data });
      const refreshedUser = await getUser(); // need to retrieve the new report quota
      globalActions.setUser(refreshedUser);

      setUpdateState({
        isSaving: false,
        message: t`Organization succesfully updated`,
        error: undefined,
      });
    } catch (error) {
      setUpdateState({
        isSaving: false,
        error: renderError(error),
        message: undefined,
      });
    }
  };

  if (state.isLoading) {
    return <Loader big />;
  }

  return (
    <Box p={3} width={[1, 1, 1, 1, 3 / 5]} minHeight={500}>
      <Heading mb={3}>
        <Trans>Organization information</Trans>
      </Heading>
      <Box as="form" onSubmit={handleSubmit(onSubmit)}>
        <Flex sx={{ gap: 3 }}>
          <Box width={1 / 2}>
            <Label>
              <Trans>Name</Trans> *
            </Label>
            <Input
              className={errors.name ? 'error' : ''}
              {...register('name', { required: true, value: state.data.organisation?.name })}
            />
            {errors.name && (
              <Text py={2} fontSize={1} color="orange">
                <Trans>Name is required</Trans>
              </Text>
            )}
          </Box>
          <Box width={1 / 2}>
            <Label>
              <Trans>Country</Trans>
            </Label>
            <Input value={state.data.organisation?.country} disabled />
          </Box>
        </Flex>
        <Text mt={2} fontSize={1} color="text">
          <Trans>
            If you would like to change your organization's country, please{' '}
            <Link to="/settings/support?subject=changeOrganizationCountry">contact our support team</Link> for
            assistance.
          </Trans>
        </Text>

        <Flex mt={2}>
          <Box width={1}>
            <Label>
              <Trans>Address</Trans> *
            </Label>
            <Input
              className={errors.address_line_1 ? 'error' : ''}
              {...register('address_line_1', { required: true, value: state.data.organisation?.address_line_1 })}
            />
            {errors.address_line_1 && (
              <Text py={2} fontSize={1} color="orange">
                <Trans>Address is required</Trans>
              </Text>
            )}
          </Box>
        </Flex>

        <Flex mt={2}>
          <Box width={1}>
            <Label>
              <Trans>Address complement</Trans>
            </Label>
            <Input
              className={errors.address_line_2 ? 'error' : ''}
              {...register('address_line_2', { value: state.data.organisation?.address_line_2 })}
            />
          </Box>
        </Flex>

        <Flex sx={{ gap: 3 }} mt={2}>
          <Box width={1 / 2}>
            <Label>
              <Trans>Zip/postal code</Trans> *
            </Label>
            <Input
              className={errors.zip_postal_code ? 'error' : ''}
              {...register('zip_postal_code', { required: true, value: state.data.organisation?.zip_postal_code })}
            />
            {errors.zip_postal_code && (
              <Text py={2} fontSize={1} color="orange">
                <Trans>Zip/postal is required</Trans>
              </Text>
            )}
          </Box>
          <Box width={1 / 2}>
            <Label>
              <Trans>City</Trans> *
            </Label>
            <Input
              className={errors.city ? 'error' : ''}
              {...register('city', { required: true, value: state.data.organisation?.city })}
            />
            {errors.city && (
              <Text py={2} fontSize={1} color="orange">
                <Trans>City is required</Trans>
              </Text>
            )}
          </Box>
        </Flex>

        <CountryRelatedTaxInputs
          register={register}
          errors={errors}
          orgData={state.data.organisation}
          countries={state.countries}
          control={control}
        />

        <Flex justifyContent="flex-end">
          <Button my={3} isLoading={updateState.isSaving}>
            <Trans>Save</Trans>
          </Button>
        </Flex>

        {updateState.message && (
          <Flex width={1}>
            <Message type="ok" message={updateState.message} />
          </Flex>
        )}

        {updateState.error && (
          <Flex width={1}>
            <Message type="error" message={updateState.error} />
          </Flex>
        )}
      </Box>
    </Box>
  );
}

function CountryRelatedTaxInputs({
  register,
  errors,
  control,
  orgData: { country, tax_id_type, tax_id_value },
  countries,
}) {
  const TaxIds = useMemo(() => countries.find((c) => c.code === country)?.tax_ids || [], [country, countries]);

  return (
    <Flex sx={{ gap: 3 }} mt={2}>
      <Box width={1 / 2}>
        <Label>
          <Trans>Tax ID Type</Trans> *
        </Label>
        <Select
          className={errors.tax_id_type ? 'error' : ''}
          {...register('tax_id_type', { required: true, value: tax_id_type })}
        >
          {/* <option></option> */}
          {TaxIds.map((item, index) => (
            <option key={index} value={item.code}>
              {item.name}
            </option>
          ))}
        </Select>

        {errors.tax_id_type && (
          <Text py={2} fontSize={1} color="orange">
            <Trans>Tax ID type is required</Trans>
          </Text>
        )}
      </Box>

      <TaxIdValue errors={errors} register={register} control={control} taxIds={TaxIds} tax_id_value={tax_id_value} />
    </Flex>
  );
}

function TaxIdValue({ errors, register, control, taxIds, tax_id_value }) {
  const taxType = useWatch({
    control,
    name: 'tax_id_type',
  });

  const placeHolder = taxIds.find((t) => t.code === taxType)?.example;

  return (
    <Box width={1 / 2}>
      <Label>
        <Trans>Tax ID Value</Trans> *
      </Label>
      <Input
        placeholder={placeHolder}
        className={errors.tax_id_value ? 'error' : ''}
        {...register('tax_id_value', { required: true, value: tax_id_value })}
      />
      {errors.tax_id_value && (
        <Text py={2} fontSize={1} color="orange">
          <Trans>Tax ID value is required</Trans>
        </Text>
      )}
    </Box>
  );
}
