import { useEffect, useState, useCallback } from 'react';
import { Flex, Box, Text, Button } from 'rebass';
import { Input, Select } from '@rebass/forms';
import find from 'lodash/find';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';
import debounce from 'lodash/debounce';
import findIndex from 'lodash/findIndex';
import toNumber from 'lodash/toNumber';
import { Trans } from '@lingui/macro';

import { updateBloodTestParameter, deleteBloodTestParameter, getBloodTestParameters } from 'api';
import Range from 'components/common/Range';
import ValueWarning from 'components/BloodTest/ValueWarning';
import useGlobal from 'hooks/global';
import { getReferenceValues } from 'utils/parameters';
import { useErrorDialog } from 'components/errorDialog/ErrorDialog';
import { filterDuplicatedUnits } from './AddParameter';

const Parameter = ({ savedParameter, index, match, isLocked, onDelete }) => {
  const { showError } = useErrorDialog();
  const [globalState, globalActions] = useGlobal();
  const { register, getValues } = useForm();

  const [referenceValues, handleReference] = useState({ minRef: '/', maxRef: '/', min: null, max: null });
  const [parameterInfo, handleParameterInfo] = useState({});
  const [saving, handeIsSaving] = useState(false);
  const [showBoundariesWarning, handleBoundariesWarning] = useState(false);
  const [showInvalidInput, handleInvalidInput] = useState(false);
  const [markForDelete, handleMarkForDelete] = useState(false);

  /**
   * @function initReferenceValues when the unit changes we have to find/update the reference min/max values
   * @param selectedUnit the selected unit
   * @param allUnits a list of all the possible units for this param
   */
  const initReferenceValues = useCallback(
    (selectedUnit, allUnits) => {
      // find the correct unit in the array
      const unitData = find(allUnits, (unit) => unit.name === selectedUnit);
      const refValues = getReferenceValues(unitData, globalState.patient.gender);
      handleReference(refValues);

      return unitData;
    },
    [globalState.patient.gender],
  );

  const onUnitChange = async (selectedUnit, allUnits) => {
    const unitData = initReferenceValues(selectedUnit, allUnits);
    validate(getValues('value'), unitData.min, unitData.max);
  };

  const submit = async (data) => {
    if (!isLocked) {
      data = getValues();
      data = {
        ...data,
        value: data.value.replace(',', '.'),
        code: savedParameter.code,
      };
      handeIsSaving(true);
      try {
        // api call
        await updateBloodTestParameter({
          ...data,
          patient_id: match.params.id,
          blood_test_id: match.params.bloodTestId,
        });

        const index = findIndex(globalState.savedParameters, (item) => item.code === data.code);
        if (index !== -1) {
          globalActions.updateSavedParameter(
            { code: savedParameter.code, value: toNumber(data.value), unit: data.unit, name: savedParameter.name },
            index,
          );
        }

        // remove this element from the blockGenerateReport array
        if (showBoundariesWarning) {
          handleBoundariesWarning(false);
        }
        if (showInvalidInput) {
          handleInvalidInput(false);
        }
        globalActions.preventGenerateReport(savedParameter.code, false);
      } catch (e) {
        showError(e);
        console.log(e);
      } finally {
        handeIsSaving(false);
      }
    }
  };

  // async value field validtion, check if out of boundaries
  const validate = (val, min, max) => {
    if (val != null && !/^[0-9]+([,.][0-9]+)?$/.test(val)) {
      handleInvalidInput(true);
      globalActions.preventGenerateReport(savedParameter.code, true); // disable Create report button
    } else {
      handleInvalidInput(false);
      const valFormat = Number.parseFloat(val.replace(',', '.'));

      // handle the boundaries
      if (val !== '' && valFormat <= max && valFormat >= min) {
        // valid value
        handleBoundariesWarning(false); // hide parameter button warning
        globalActions.preventGenerateReport(savedParameter.code, false); // remove parameter from prevent report array
        submit();
      } else {
        // value out of bounds
        globalActions.preventGenerateReport(savedParameter.code, true); // disable Create report button
        if (val !== '') {
          handleBoundariesWarning(true);
        }
        if (val === '') {
          handleBoundariesWarning(false);
          globalActions.preventGenerateReport(savedParameter.code, false); // remove parameter from prevent report array
        }
      }
    }
  };

  // debounced validate & submit
  const debounceValidate = debounce((val, min, max) => {
    validate(val, min, max);
  }, 400);

  // Delete single parameter
  const handleDeleteParameter = async () => {
    handleMarkForDelete(true);
    try {
      await deleteBloodTestParameter(match.params.id, match.params.bloodTestId, savedParameter.code);
      const res = await getBloodTestParameters(match.params.id, match.params.bloodTestId);
      globalActions.preventGenerateReport(savedParameter.code, false);
      globalActions.setSavedParameters(res);
    } catch (e) {
      showError(e);
      console.log(e);
    } finally {
      handleMarkForDelete(false);
    }
  };

  useEffect(() => {
    const paramInfo = find(globalState.parameters, (o) => o.code === savedParameter.code);
    if (paramInfo) {
      handleParameterInfo(paramInfo);
      initReferenceValues(savedParameter.unit, paramInfo.units);
    }
  }, [globalState.parameters, initReferenceValues, savedParameter.code, savedParameter.unit]);

  let value = getValues('value');
  let paramInputValue = value ? value.replace(',', '.') : value;

  const showWarning =
    value == '' ||
    (referenceValues.maxRef !== null && paramInputValue > referenceValues.maxRef) ||
    (referenceValues.minRef !== null && paramInputValue < referenceValues.minRef);

  const paramInputs = filterDuplicatedUnits(parameterInfo.units);

  return (
    <Flex
      bg={markForDelete ? 'darkgray' : index % 2 === 0 ? 'white' : '#fbfbfb'}
      as="form"
      variant="bloodTest.parameter"
      onSubmit={(e) => e.preventDefault()}
      color="text"
      autoComplete="off"
    >
      <Box pl={[0, 0, 0, 0, 2]} pr={[0, 0, 0, 0, 1]} width={[1, 1, 1, 1, 2 / 5]}>
        <Flex py={2} sx={{ flexDirection: 'row', alignItems: 'center' }}>
          {!isLocked && (
            <Text
              variant="clickable"
              onClick={() => onDelete({ onConfirm: handleDeleteParameter })}
              color="darkgray"
              pr={3}
              sx={{ marginBottom: '-4px' }}
            >
              <FaTimes />
            </Text>
          )}
          <Text fontSize={['14px', '16px', '16px', '16px', '18px']}>{savedParameter.name}</Text>
        </Flex>
      </Box>
      <Flex variant="fluid" width={[1, 1, 1, 1, 3 / 5]}>
        <Box p={2} width={['70%', '25%', '25%', '25%', '37%']}>
          <Flex justifyContent={['flex-end']}>
            {saving && (
              <Flex alignItems="center" sx={{ display: 'inline-block' }} fontSize={1} color="orange" pr={2}>
                <Trans>Saving...</Trans>
              </Flex>
            )}
            {!saving && !showInvalidInput && showBoundariesWarning && (
              <Button onClick={() => submit()} p={0} sx={{ height: '30px', width: '116px' }} mr={2}>
                <Trans>Confirm</Trans>
              </Button>
            )}
            <ValueWarning value={paramInputValue} max={referenceValues.maxRef} min={referenceValues.minRef} />
            <Input
              key={savedParameter.code}
              readOnly={isLocked}
              defaultValue={savedParameter.value}
              {...register('value', {
                onChange: (e) => debounceValidate(e.target.value, referenceValues.min, referenceValues.max),
              })}
              sx={{
                borderColor: showInvalidInput ? 'red' : showWarning ? 'orange' : 'gray',
                height: '30px',
                '&:focus': {
                  outline: isLocked ? 'unset' : undefined,
                },
              }}
              type="text"
              variant="bloodTest.parameter.input"
            />
          </Flex>
        </Box>
        <Box p={2} width={['30%', '15%', '15%', '15%', '15%']}>
          {!isLocked && paramInputs ? (
            <Select
              key={savedParameter.unit}
              defaultValue={savedParameter.unit}
              {...register('unit', {
                onChange: (e) => onUnitChange(e.target.value, paramInputs),
              })}
              p={1}
            >
              {paramInputs.map((unit, i) => (
                <option key={unit.name + '_' + i} value={unit.name}>
                  {unit.name}
                </option>
              ))}
            </Select>
          ) : (
            <Flex height="100%" alignItems="center">
              {savedParameter.unit}
            </Flex>
          )}
        </Box>

        <Flex width={['40%', '20%', '20%', '20%', '18%']} alignItems="center" pl={2}>
          <Text color="darkergray" variant="inline" pr={1} fontSize={1}>
            <Trans>Ref</Trans>
          </Text>{' '}
          <Text color="text" variant="inline"></Text>
          {referenceValues.minRef === referenceValues.maxRef ? (
            <>{referenceValues.minRef || '0'}</>
          ) : (
            <>
              {referenceValues.minRef || '0'} - {referenceValues.maxRef ?? '/'}
            </>
          )}
        </Flex>
        <Flex width={['60%', '40%', '40%', '40%', '30%']} alignItems="center">
          {!saving && (showBoundariesWarning || showInvalidInput) && (
            <Flex alignItems="center" width="400px">
              <Text color={showInvalidInput ? 'red' : 'orange'} fontSize={1} px={2} textAlign="center">
                {showInvalidInput ? (
                  <Trans>Invalid input!</Trans>
                ) : showBoundariesWarning ? (
                  <Trans>Value out of boundaries!</Trans>
                ) : null}
              </Text>
            </Flex>
          )}
          {(!(showBoundariesWarning || showInvalidInput) || saving) && (
            <Range
              min={referenceValues.minRef}
              max={referenceValues.maxRef}
              value={paramInputValue}
              showWarning={showWarning}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default withRouter(Parameter);
