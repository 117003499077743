import { Trans } from '@lingui/macro';
import { Link } from 'react-router-dom';
import { Flex, Text } from 'rebass';
import { RenderRoute, useUser } from '../ProtectedRoute';

export default function NotFoundPage() {
  const [{ user }] = useUser();

  const NotFoundView = (
    <>
      <Flex variant="container" my={6} fontSize={2} textAlign="center" color="text">
        <Text fontSize={6} py={3} fontWeight={'bold'}>
          #404
        </Text>
        <Text fontSize={3} py={3}>
          <Trans>Well, well, well...looks like the page you were after has gone.</Trans>
        </Text>
        <Text fontStyle={'italic'} color="text">
          <Trans>It must have taken a sick day, or perhaps it's just playing hide and seek.</Trans>
        </Text>
        <Text fontStyle={'italic'} color="text">
          <Trans>Either way, it's not here, so let's find you a healthier link to click!</Trans>
        </Text>

        <Text py={3} fontSize={2}>
          <Link to="/">
            <Trans>Home page</Trans>
          </Link>
        </Text>
      </Flex>
    </>
  );

  return (
    <RenderRoute
      props={{
        path: '*',
        children: NotFoundView,
      }}
      user={user}
    />
  );
}
