import { Box, Flex, Heading } from 'rebass';
import { CustomCardButton } from '../ReportParamDescription/ReportParamDescriptionCard';
import { Trans } from '@lingui/macro';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { DiseaseColorBox } from '../ReportParamDescription/ReportParamDescriptionCard/DiseaseColorBox';
import { SimpleTable } from '../_common/SimpleTable';

export function SubdiagnosesCard({ selectedIndex, diseaseCount, prev, next, subdiagnoses, disease, diseaseColor }) {
  return (
    <>
      <Box
        style={{
          position: 'sticky',
          top: 0,
          background: 'linear-gradient(white,white 90%, #ffffffcc)',
          marginBottom: '1em',
        }}
      >
        <Flex justifyContent={'space-between'} alignItems={'baseline'}>
          <CustomCardButton
            text={<Trans>Previous</Trans>}
            icon={<FaAngleLeft style={{ verticalAlign: 'sub' }} />}
            disabled={selectedIndex === 0}
            onClick={prev}
          />

          <Flex flexDirection={'column'}>
            <Heading p={2} color="orange">
              <DiseaseColorBox color={diseaseColor} />
              {disease.code} - {disease.description}
            </Heading>
          </Flex>

          <CustomCardButton
            text={<Trans>Next</Trans>}
            iconIsRight
            icon={<FaAngleRight style={{ verticalAlign: 'sub' }} />}
            disabled={selectedIndex === diseaseCount - 1}
            onClick={next}
          />
        </Flex>
      </Box>

      <SimpleTable
        data={subdiagnoses}
        cols={[
          {
            label: <Trans>ICD</Trans>,
            value: (row) => row.key,
            width: '8ch',
          },
          {
            label: <Trans>Description</Trans>,
            value: (row) => row.text,
          },
        ]}
      />
    </>
  );
}
