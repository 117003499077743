import { useState, useEffect, Fragment, useRef } from 'react';
import { Flex, Box, Heading, Text } from 'rebass';
import Link from 'components/common/Link';
import { Trans } from '@lingui/macro';
import { Label } from '@rebass/forms';
import { isBefore, isAfter, formatISO, subDays } from 'date-fns';
import filter from 'lodash/filter';
import { getBillingEventsHistory } from 'api';
import Loader from 'components/common/Loader';
import DatePicker from 'components/common/DatePicker';
import { dateFormat } from 'components/common/DateFormat';
import { useErrorDialog } from 'components/errorDialog/ErrorDialog';

const BillingEventsHistory = () => {
  const { showError } = useErrorDialog();
  const initialFrom = useRef(subDays(new Date(), 30));
  const initialTo = useRef(new Date());
  const [history, setHistory] = useState(null);
  const [isLoading, handleLoading] = useState(false);
  const [from, setFrom] = useState(formatISO(initialFrom.current));
  const [to, setTo] = useState(formatISO(initialTo.current));

  useEffect(() => {
    const fetch = async () => {
      handleLoading(true);

      try {
        const reportsHistory = await getBillingEventsHistory();
        const sortedReportsHistory = reportsHistory.reverse();
        setHistory(sortedReportsHistory);
      } catch (e) {
        console.log(e);
        showError(e);
      } finally {
        handleLoading(false);
      }
    };

    if (!history && !isLoading) {
      fetch();
    }
  });

  const handleFromDate = (date) => {
    setFrom(date);
  };

  const handleToDate = (date) => {
    setTo(date);
  };

  const filteredReportStats = filter(history, (report) => {
    return (
      (to ? isBefore(new Date(report.created), new Date(to)) : true) &&
      (from ? isAfter(new Date(report.created), new Date(from)) : true)
    );
  });

  return (
    <Box p={3} width={1} minHeight={500}>
      <Heading mb={3}>
        <Trans>Billing events history</Trans>
      </Heading>
      {isLoading && <Loader big />}
      {!isLoading && filteredReportStats && (
        <Fragment>
          <Box as="form" width={1 / 3} mb={2}>
            <Flex sx={{ width: 'max-content' }}>
              <div style={{ marginRight: '2ch' }}>
                <Label>
                  <Trans>Showing history from date</Trans>
                </Label>
                <DatePicker
                  name="filter_date_from"
                  key="fromDate"
                  returnDate={handleFromDate}
                  disableFuture
                  supportsDelete
                  defaultValue={formatISO(initialFrom.current)}
                />
              </div>
              <div>
                <Label>
                  <Trans>Showing history to date</Trans>
                </Label>
                <DatePicker
                  name="filter_date_to"
                  key="toDate"
                  returnDate={handleToDate}
                  disableFuture
                  supportsDelete
                  defaultValue={formatISO(initialTo.current)}
                />
              </div>
            </Flex>
          </Box>
          <Flex py={2} color="darkergray" sx={{ borderBottom: '1px solid', borderColor: 'gray' }}>
            <Box width={1 / 5}>
              <Trans>Created</Trans>
            </Box>
            <Box width={1 / 5}>
              <Trans>Patient</Trans>
            </Box>
            <Box width={1 / 4}>
              <Trans>Blood test</Trans>
            </Box>
            <Box width={1 / 5}>
              <Trans>Medical device</Trans>
            </Box>
            <Box width={1 / 5}>
              <Trans>Billing event ID</Trans>
            </Box>
          </Flex>
          {filteredReportStats.map((report, index) => (
            <Flex key={index} py={2} sx={{ borderBottom: '1px solid', borderColor: 'gray' }}>
              <Box width={1 / 5}>{dateFormat(report.created, true)}</Box>
              <Box width={1 / 5}>{report?.blood_test?.person?.name}</Box>
              <Box width={1 / 4}>
                <Link to={`/patient/${report?.blood_test?.person_id}/blood-test/${report?.blood_test?.id}`}>
                  <Text>{report?.blood_test?.name}</Text>
                </Link>
              </Box>
              <Box width={1 / 5}>{report.medical_device}</Box>
              <Box width={1 / 5}>{report.id}</Box>
            </Flex>
          ))}
        </Fragment>
      )}
    </Box>
  );
};

export default BillingEventsHistory;
