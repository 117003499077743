import MicroModal from 'react-micro-modal';
import 'react-micro-modal/dist/index.css';
import { Box, Flex, Heading, Text } from 'rebass';
import { Label } from '@rebass/forms';
import { Trans } from '@lingui/macro';
import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';
import Message from 'components/common/Message';
import Button from 'components/common/Button';
import { contactSupport } from 'api';
import { renderError } from 'utils';
import TextareaWrap from 'components/common/Textarea';

const CancelSubscriptionDialog = forwardRef(function _CancelSubscriptionDialog({ onSuccess }, ref) {
  const [state, setState] = useState({
    shown: false,
    error: null,
    isLoading: false,
  });

  useImperativeHandle(ref, () => ({
    showDialog: () => {
      setState({ error: null, isLoading: null, shown: true });
    },
  }));

  const closeDialog = useCallback(() => {
    setState({ shown: false, error: null, isLoading: false });
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const postFormData = useCallback(
    async (data) => {
      setState({ isLoading: true, error: undefined, shown: true });

      const newStateToBe = { isLoading: false, error: undefined, shown: false };

      try {
        await contactSupport({
          title: 'Cancel subscription',
          message: data.reason,
        });
        closeDialog();
        onSuccess();
      } catch (error) {
        newStateToBe.error = renderError(error);
      } finally {
        setState(newStateToBe);
      }
    },
    [onSuccess, closeDialog],
  );

  return (
    <MicroModal
      open={state.shown}
      openInitially={false}
      overrides={{
        Dialog: {
          style: { maxWidth: '600px', width: '100%' },
        },
      }}
    >
      {() => (
        <Flex flexDirection={'column'}>
          <Heading mb={3} color="text">
            <Trans>Are you sure you want to cancel your subscription?</Trans>
          </Heading>

          <Flex as="form" sx={{ flexDirection: 'column' }} autoComplete="off">
            <Box width={1} mb={2}>
              <Label>
                <Trans>Please provide a reason for canceling the subscription</Trans>{' '}
                <Box ml={1} color="orange">
                  *
                </Box>
              </Label>
              <TextareaWrap rows={5} name="reason" register={register} registerProps={{ required: true }} />
              {errors.reason && (
                <Text py={2} fontSize={1} color="orange">
                  <Trans>Field is required</Trans>
                </Text>
              )}
            </Box>
          </Flex>

          {state.error && (
            <Flex width={1} my={3}>
              <Message type="error" message={state.error} />
            </Flex>
          )}

          <Flex justifyContent="flex-end" mt={2}>
            <Button mr={3} onClick={closeDialog}>
              <Trans>Cancel</Trans>
            </Button>
            <Button onClick={handleSubmit(postFormData)} isLoading={state.isLoading}>
              <Trans>Confirm</Trans>
            </Button>
          </Flex>
        </Flex>
      )}
    </MicroModal>
  );
});

export default CancelSubscriptionDialog;
