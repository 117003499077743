import ReportParamDescriptionCard from './ReportParamDescriptionCard';
import { useReportIcd } from '..';
import ReportParamDescriptionCardBinary from './ReportParamDescriptionCardBinary';

export function ReportParamDescription({ reportDetails, userIsStaff }) {
  const diseases = reportDetails.result?.results;
  const { selectedIcdId, setSelectedIcdId } = useReportIcd();

  let selected = diseases.findIndex((d) => d.code === selectedIcdId);
  if (selected === -1) {
    selected = 0;
  }

  const disease = diseases[selected];
  const allDiseasesMaxContribution = (disease?.explanations || []).reduce(
    (acc, curr) => Math.max(acc, Math.abs(curr.value)),
    0,
  );

  const prev = () => setSelectedIcdId(diseases[selected - 1].code);
  const next = () => setSelectedIcdId(diseases[selected + 1].code);

  return (
    <ReportParamDescriptionCard
      disease={disease}
      diseaseColor={reportDetails.result?.visualization?.legend?.[selected]?.color}
      allDiseasesMaxContribution={allDiseasesMaxContribution}
      selectedIndex={selected}
      diseaseCount={diseases.length}
      prev={prev}
      next={next}
      userIsStaff={userIsStaff}
    />
  );
}

export function ReportParamDescriptionBinary({ reportDetails, userIsStaff }) {
  const diseases = reportDetails.result?.results;

  const allDiseasesMaxContribution = (diseases[0]?.explanations || []).reduce(
    (acc, curr) => Math.max(acc, Math.abs(curr.value)),
    0,
  );

  return (
    <ReportParamDescriptionCardBinary
      disease1={{
        name: diseases[0].description,
        color: reportDetails.result?.visualization?.legend?.[0]?.color,
      }}
      disease2={{
        name: diseases[1].description,
        color: reportDetails.result?.visualization?.legend?.[1]?.color,
      }}
      explanations={diseases[1]?.explanations || []}
      allDiseasesMaxContribution={allDiseasesMaxContribution}
      userIsStaff={userIsStaff}
    />
  );
}
