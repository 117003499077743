import { NavLink } from 'react-router-dom';

const style = {
  textDecoration: 'none',
  outline: 'none',
};

const LinkBase = ({ ...rest }) => (
  <NavLink
    style={style}
    activeStyle={{
      fontWeight: 'bold',
    }}
    {...rest}
  />
);

export default LinkBase;
