import Markdown from 'markdown-to-jsx';
import { Text } from 'rebass';

export default function MarkdownViewer({ markdown }) {
  return (
    <Markdown
      options={{
        overrides: {
          p: {
            component: Text,
            props: {
              style: { whiteSpace: 'break-spaces' },
            },
          },
          ul: { props: { style: { margin: 0 } } },
          table: {
            props: {
              style: {
                // width: '100%',
                minWidth: '240px',
                border: '1px solid #d0d7de',
                borderCollapse: 'collapse',
                margin: '4px 0',
                tableLayout: 'fixed',
              },
            },
          },
          td: {
            props: {
              style: { border: '1px solid #d0d7de', borderCollapse: 'collapse', padding: '4px 8px', width: '50%' },
            },
          },
          th: {
            props: {
              style: {
                border: '1px solid #d0d7de',
                borderCollapse: 'collapse',
                padding: '4px 8px',
                textAlign: 'left',
                width: '50%',
              },
            },
          },
        },
      }}
    >
      {markdown || ' '}
    </Markdown>
  );
}
