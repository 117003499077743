import { useCallback, useRef, useState } from 'react';
import { Flex, Box, Text } from 'rebass';
import { Label } from '@rebass/forms';
import { withRouter } from 'react-router';
import { FaChevronDown, FaChevronUp, FaSortDown, FaSortUp } from 'react-icons/fa';
import orderBy from 'lodash/orderBy';
import { Trans } from '@lingui/macro';

import { DateFormatDistance } from 'components/common/DateFormat';
import { RenderGender, calculateAge } from 'utils';
import useGlobal from 'hooks/global';
import MarkdownViewer from 'components/common/Markdown';
import { useIsOverflow } from 'hooks/useIsOverflow';

const PatientsList = ({ patients, history }) => {
  const [sortedBy, handleSortedBy] = useState('created');
  const [order, handleOrder] = useState('desc');

  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal();

  const handlePatientSelect = (patient) => {
    globalActions.setPatient(patient);
    history.push(`/patient/${patient.id}`);
  };

  const changeSorting = (key) => {
    const newOrder = order === 'asc' ? 'desc' : 'asc';
    handleSortedBy(key);
    handleOrder(newOrder);
  };

  let sortedPatients = orderBy(patients, sortedBy, order);

  return [
    <Flex key="head" variant="tableHead" sx={{ opacity: 0.8 }}>
      <Flex px={3} width={[1, 1, '40%', '40%', '50%']} onClick={() => changeSorting('uid')}>
        <Label color="darkergray" fontSize={2}>
          <Trans>Patient ID</Trans>
          {sortedBy === 'uid' && (
            <span style={{ verticalAlign: 'middle' }}>{order === 'desc' ? <FaSortDown /> : <FaSortUp />}</span>
          )}
        </Label>
      </Flex>
      <Flex px={3} width={[1, 1, '60%', '60%', '50%']}>
        <Label color="darkergray" fontSize={2} onClick={() => changeSorting('birth_year')}>
          <Trans>Age</Trans>
          {sortedBy === 'birth_year' && (
            <span style={{ verticalAlign: 'middle' }}>{order === 'desc' ? <FaSortDown /> : <FaSortUp />}</span>
          )}
        </Label>

        <Label color="darkergray" fontSize={2} onClick={() => changeSorting('gender')}>
          <Trans>Gender</Trans>
          {sortedBy === 'gender' && (
            <span style={{ verticalAlign: 'middle' }}>{order === 'desc' ? <FaSortDown /> : <FaSortUp />}</span>
          )}
        </Label>
        <Label color="darkergray" fontSize={2} onClick={() => changeSorting('number_of_blood_tests')}>
          <Trans>Blood tests</Trans>
          {sortedBy === 'number_of_blood_tests' && (
            <span style={{ verticalAlign: 'middle' }}>{order === 'desc' ? <FaSortDown /> : <FaSortUp />}</span>
          )}
        </Label>

        <Label color="darkergray" fontSize={2} onClick={() => changeSorting('number_of_reports')}>
          <Trans>Reports</Trans>
          {sortedBy === 'number_of_reports' && (
            <span style={{ verticalAlign: 'middle' }}>{order === 'desc' ? <FaSortDown /> : <FaSortUp />}</span>
          )}
        </Label>

        <Label color="darkergray" fontSize={2} minWidth="120px" onClick={() => changeSorting('last_report')}>
          <Trans>Last Report</Trans>
          {sortedBy === 'last_report' && (
            <span style={{ verticalAlign: 'middle' }}>{order === 'desc' ? <FaSortDown /> : <FaSortUp />}</span>
          )}
        </Label>
      </Flex>
    </Flex>,
    sortedPatients.map((patient, index) => (
      <PatientPreview
        key={`patient-${index}`}
        patient={patient}
        onPatientClick={handlePatientSelect}
        isStaff={globalState.user && globalState.user.is_staff}
      />
    )),
  ];
};

function PatientPreview({ patient, onPatientClick, isStaff }) {
  const ref = useRef();

  const isOverflow = useIsOverflow(ref);
  const [isExpanded, setExpanded] = useState(false);

  const toggleExpandMode = useCallback((e) => {
    console.log(e);
    e.preventDefault();
    setExpanded((prevState) => !prevState);
  }, []);

  return (
    <Box ref={ref} sx={{ maxHeight: isExpanded ? 'unset' : '120px', overflow: 'hidden', position: 'relative' }}>
      <Flex
        color="text"
        onClick={() => onPatientClick(patient)}
        variant="dashboard.row"
        sx={{ flexDirection: 'column', lineHeight: '1em', paddingBottom: isExpanded ? '26px' : undefined }}
      >
        <Flex sx={{ alignItems: 'center' }}>
          <Flex width={[1, 1, '40%', '40%', '50%']} px={3}>
            <Text fontSize={[2, 2, 3, 3, 3]}>{patient.uid}</Text>
          </Flex>
          <Flex width={[1, 1, '60%', '60%', '50%']} px={3}>
            <Box width={1}>
              {calculateAge(patient.birth_year, patient.birth_date)} <Trans>years</Trans>
            </Box>
            <Box width={1}>
              <RenderGender gender={patient.gender} />
            </Box>
            <Box width={1}>
              <Text sx={{ display: 'inline-block' }} textAlign="center">
                {patient.number_of_blood_tests}
              </Text>
            </Box>
            <Box width={1}>
              <Text sx={{ display: 'inline-block' }}>{patient.number_of_reports}</Text>
            </Box>
            <Box minWidth="120px" width={1}>
              {patient.last_report && (
                <span>
                  <DateFormatDistance from={new Date(patient.last_report)} to={new Date()} /> <Trans>ago</Trans>
                </span>
              )}
            </Box>
          </Flex>
        </Flex>
        <Flex px={3}>
          {isStaff && patient.comment && (
            <Text color="darkergray" fontSize={1} data-color-mode="light" width={'100%'}>
              <MarkdownViewer markdown={patient.comment} />
            </Text>
          )}
        </Flex>
      </Flex>
      {isOverflow.isOverflow && (
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            backgroundImage: 'linear-gradient(#ffffff2a, #ffffffe9, #ffffff, white)',
          }}
        >
          <Text
            color="darkergray"
            fontSize="12px"
            variant="clickable"
            onClick={toggleExpandMode}
            sx={{ display: 'flex', alignItems: 'center', px: 3, py: 2, pt: 3 }}
          >
            {isExpanded ? (
              <>
                <FaChevronUp style={{ marginRight: '1ch' }} />
                <Trans>Collapse</Trans>
              </>
            ) : (
              <>
                <FaChevronDown style={{ marginRight: '1ch' }} />
                <Trans>Expand</Trans>
              </>
            )}
          </Text>
        </div>
      )}
    </Box>
  );
}

export default withRouter(PatientsList);
