import { useState, useCallback, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { Flex, Box, Button } from 'rebass';
import { Label, Input } from '@rebass/forms';
import { Trans } from '@lingui/macro';
import { t } from '@lingui/macro';
import startsWith from 'lodash/startsWith';

import PatientsList from './PatientsList';
import { getGender, calculateAge, pickJsonFile } from 'utils';
import Loader from 'components/common/Loader';
import { getPatients, importPatient } from 'api';
import useGlobal from 'hooks/global';
import Breadcrumbs from 'components/common/Breadcrumbs';
import Message from 'components/common/Message';
import { FaFileImport } from 'react-icons/fa';
import { useErrorDialog } from 'components/errorDialog/ErrorDialog';

const Dashboard = () => {
  const { showError } = useErrorDialog();
  const [isLoading, handleLoading] = useState(true);
  const [importPatientState, setImportPatientState] = useState(undefined);
  const [searchTerm, handleSearch] = useState('');

  const [globalState, globalActions] = useGlobal();

  const foundString = (patient) => {
    if (
      patient.uid.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1 ||
      startsWith(getGender(patient.gender, globalState.user.report_language).toLowerCase(), searchTerm.toLowerCase()) ||
      calculateAge(patient.birth_year, patient.birth_date).indexOf(searchTerm.toLowerCase()) !== -1 ||
      (globalState.user.is_staff &&
        patient &&
        patient.comment &&
        patient.comment.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1)
    )
      return true;
  };

  const fetchPatients = useCallback(async () => {
    try {
      const response = await getPatients();
      await globalActions.setPatients(response.reverse());
    } catch (e) {
      console.log(e);
      showError(e);
    } finally {
      handleLoading(false);
    }
  }, [globalActions, showError]);

  const importPatientHandle = useCallback(() => {
    setImportPatientState(undefined);

    const onValidJsonFileContent = async (fileContent) => {
      handleLoading(true);
      try {
        await importPatient(fileContent);
        setImportPatientState({
          status: 'ok',
        });
      } catch (error) {
        if (error.detail === 'This person id is already used/imported') {
          setImportPatientState({
            status: 'error',
            type: 'already_imported',
          });
        } else {
          setImportPatientState({
            status: 'error',
            type: 'post_error',
          });
        }
      }

      fetchPatients();
    };

    const onInvalidJsonFileContent = (fileName) => {
      setImportPatientState({ status: 'error', type: 'invalid_json', fileName });
    };

    pickJsonFile(onValidJsonFileContent, onInvalidJsonFileContent);
  }, [fetchPatients]);

  useEffect(() => {
    fetchPatients();
  }, [fetchPatients]);

  if (isLoading) {
    return (
      <Flex variant="container" mb={5}>
        <Loader big text="Loading patients" />
      </Flex>
    );
  }

  if (!isLoading && globalState.patients.length === 0) {
    return <Redirect to="/add-patient" />;
  }

  if (!isLoading && globalState.patients.length > 0) {
    const filteredPatients = globalState.patients.filter((p) => foundString(p));

    const ImportPatientStateMessage = () => {
      if (!importPatientState) {
        return null;
      }

      if (importPatientState.status === 'ok') {
        return (
          <Box width={[1]} mt={2}>
            <Message type={importPatientState.status} message={<Trans>Patient succesfully imported!</Trans>} />
          </Box>
        );
      } else {
        switch (importPatientState.type) {
          case 'invalid_json':
            return (
              <Box width={[1]} mt={2}>
                <Message
                  type={importPatientState.status}
                  message={<Trans>Selected file `{importPatientState.fileName}` contains invalid JSON content!</Trans>}
                />
              </Box>
            );

          case 'post_error':
            return (
              <Box width={[1]} mt={2}>
                <Message
                  type={importPatientState.status}
                  message={<Trans>Something went wrong, failed to import patient!</Trans>}
                />
              </Box>
            );

          case 'already_imported':
            return (
              <Box width={[1]} mt={2}>
                <Message
                  type={importPatientState.status}
                  message={<Trans>Import failed, patient's ID already exists!</Trans>}
                />
              </Box>
            );

          default:
            return (
              <Box width={[1]} mt={2}>
                <Message type={importPatientState.status} message={<Trans>Something went wrong!</Trans>} />
              </Box>
            );
        }
      }
    };

    return (
      <Flex variant="container" mb={5}>
        <Breadcrumbs />
        <Flex py={3} sx={{ justifyContent: 'space-between', flexFlow: 'row wrap' }}>
          <Box width={[1, 1, 1 / 2, 1 / 2, 1 / 2]}>
            <Label htmlFor="name">
              <Trans>Search</Trans>
            </Label>
            <Input
              placeholder={globalState.user.is_staff ? t`Patient id, age, gender, comment` : t`Patient id, age, gender`}
              onChange={(e) => handleSearch(e.target.value)}
            />
          </Box>
          <Box width={[1, 1, 1 / 2, 1 / 2, 1 / 2]} sx={{ textAlign: 'right' }}>
            {globalState.user.is_staff && (
              <Button mt={4} marginRight={2} onClick={importPatientHandle}>
                <FaFileImport style={{ height: '0.8em', marginRight: '0.2em' }} />
                <Trans>Import patient</Trans>
              </Button>
            )}
            <Link to="/add-patient">
              <Button variant="orange" mt={4}>
                <Trans>Add Patient</Trans>
              </Button>
            </Link>
          </Box>

          <ImportPatientStateMessage />
        </Flex>
        <Box width={[1]}>
          <PatientsList patients={filteredPatients} />
        </Box>
      </Flex>
    );
  }

  return <Flex variant="container" mb={5}></Flex>;
};

export default Dashboard;
