import { useState } from 'react';
import { Heading, Box, Flex, Text, Image } from 'rebass';
import { Input, Label } from '@rebass/forms';
import { withRouter, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import Logo from 'assets/sba-logo.svg';
import SbaGraph from 'assets/sba-graph-2.png';

import Message from 'components/common/Message';
import Button from 'components/common/Button';
import { renderError } from 'utils';
import { resetPasswordConfirm } from 'api';

const SetPassword = ({ match, history }) => {
  const [isLoading, handleIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();
  const [response, handleResponse] = useState();

  const onSubmit = async (data) => {
    handleIsLoading(true);
    handleResponse();
    try {
      await resetPasswordConfirm({
        ...data,
        uid: match.params.uid,
        token: match.params.token,
      });
      handleResponse({
        success: 'You have successfully set your new password! You will be redirected to login in 5 seconds... ',
      });
      setTimeout(() => history.push('/login'), 5000);
    } catch (e) {
      handleResponse({ error: renderError(e) });
    } finally {
      handleIsLoading(false);
    }
  };

  return (
    <Flex
      variant="container"
      alignItems="center"
      justifyContent="flex-start"
      width="100%"
      sx={{ minHeight: '100vh' }}
      bg="lightgray"
      maxWidth="100%"
    >
      <Link to="/login">
        <Image m={5} src={Logo} alignSelf="center" width={300} />
      </Link>
      <Box variant="card">
        <Heading as="h3" color="primary" pb={1} textAlign="center">
          Reset your password
        </Heading>
        <Text my={3} color="text">
          The password must be at least 10 characters long, not entirely numeric or too common.
        </Text>
        <Box as="form" mt={3} onSubmit={handleSubmit(onSubmit)}>
          <Label>New password</Label>
          <Input
            className={errors.new_password ? 'error' : ''}
            {...register('new_password', { required: true })}
            type="password"
          />
          {errors.new_password && (
            <Text py={2} fontSize={1} color="orange">
              New password is required
            </Text>
          )}
          <Label>Repeat New password</Label>
          <Input
            className={errors.new_password_repeat ? 'error' : ''}
            {...register('new_password_repeat', {
              validate: {
                required: (value) => value !== '',
                equal: (value) => value === getValues().new_password,
              },
            })}
            type="password"
          />
          {errors.new_password_repeat && errors.new_password_repeat.type === 'required' && (
            <Text py={2} fontSize={1} color="orange">
              New password is required
            </Text>
          )}
          {errors.new_password_repeat && errors.new_password_repeat.type === 'equal' && (
            <Text py={2} fontSize={1} color="orange">
              Passwords don't match
            </Text>
          )}
          <Flex justifyContent="flex-end">
            <Button type="submit" mt={4} mb={4} isLoading={isLoading}>
              Set new password
            </Button>
          </Flex>
        </Box>
        {response && <Message message={response.error || response.success} type={response.error ? 'error' : 'ok'} />}
        <Text pt={4} textAlign="center" sx={{ borderTop: '1px solid', borderColor: 'darkgray' }}>
          <Link to="/login">Back to Sign In </Link>
        </Text>
      </Box>

      <Flex justifyContent="center" p={4}>
        <Image m={4} width={['80%', '50%', '30%']} alignSelf="center" src={SbaGraph} />
      </Flex>
    </Flex>
  );
};

export default withRouter(SetPassword);
