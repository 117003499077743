import Breadcrumbs from 'components/common/Breadcrumbs';
import { Box, Flex, Text } from 'rebass';
import { PricingTable } from './pricingTable/PricingTable';
import { useEffect, useState } from 'react';
import { renderError } from 'utils';
import { getStripePricingTable } from 'api';
import Loader from 'components/common/Loader';

export default function Pricing() {
  const [state, setState] = useState({
    isLoading: true,
    error: undefined,
    pricingData: undefined,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const pricingData = await getStripePricingTable();

        setState({
          isLoading: false,
          error: undefined,
          pricingData,
        });
      } catch (error) {
        setState({
          isLoading: false,
          error: renderError(error),
          pricingData: undefined,
        });
      }
    };

    fetchData();
  }, []);

  return (
    <Flex pb={4} variant="container" color={'text'}>
      <Breadcrumbs />

      {state.isLoading ? (
        <Loader big />
      ) : (
        <>
          <PricingTable data={state.pricingData} />

          <Flex justifyContent={'center'} alignItems={'center'} mt={4}>
            <Box maxWidth={'1000px'} mb={5}>
              <Text>
                * A billing event represents any SBAS product (SBAS Software or VIRUS vs. BACTERIA) used for an
                individual blood test. You are charged only for the first SBAS Software model run for an individual
                blood test. All subsequent model runs for the same set of blood test results are included in the same
                billing event.
              </Text>
              <br />
              <Text>The License Fee is exclusive of VAT or any relevant local sales taxes.</Text>
              <br />
              <Text>
                If you have a Standard or Pro plan and do not cancel it at least 30 days prior to the end of the
                subscription period, it will automatically renew annually. If you cancel your plan in time, your user
                account will be accessible till the end of the subscription period. After the account will be disabled,
                and you can renew your subscription anytime.
              </Text>
            </Box>
          </Flex>
        </>
      )}
    </Flex>
  );
}
