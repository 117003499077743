import { Box, Flex, Heading } from 'rebass';
import { CustomCardButton, CustomCardSettings } from '../ReportParamDescription/ReportParamDescriptionCard';
import { Trans } from '@lingui/macro';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { Label, Select } from '@rebass/forms';
import { useCallback, useState } from 'react';
import { DiseaseColorBox } from '../ReportParamDescription/ReportParamDescriptionCard/DiseaseColorBox';
import { SimpleTable } from '../_common/SimpleTable';

export function RecommendationCard({ disease, diseaseColor, selectedIndex, diseaseCount, prev, next, userIsStaff }) {
  const [noOfParams, setNoOfParams] = useState(10);

  const onSelectChange = useCallback((el) => {
    setNoOfParams(el.target.value);
  }, []);

  return (
    <div>
      <Box
        style={{
          position: 'sticky',
          top: 0,
          background: 'linear-gradient(white,white 90%, #ffffffcc)',
          marginBottom: '1em',
          zIndex: 10,
        }}
      >
        <Flex justifyContent={'space-between'} alignItems={'baseline'}>
          <CustomCardButton
            text={<Trans>Previous</Trans>}
            icon={<FaAngleLeft style={{ verticalAlign: 'sub' }} />}
            disabled={selectedIndex === 0}
            onClick={prev}
          />

          <Flex flexDirection={'column'}>
            <Heading p={2} color="orange">
              <DiseaseColorBox color={diseaseColor} />
              {disease.code} - {disease.description}
            </Heading>
            {userIsStaff && (
              <Heading fontSize={2} color="darkergray">
                <Flex justifyContent="center" alignItems="center"></Flex>
              </Heading>
            )}
          </Flex>

          <CustomCardButton
            text={<Trans>Next</Trans>}
            iconIsRight
            icon={<FaAngleRight style={{ verticalAlign: 'sub' }} />}
            disabled={selectedIndex === diseaseCount - 1}
            onClick={next}
          />
        </Flex>
      </Box>
      <Flex flexWrap={'wrap'} fontSize={1} style={{ position: 'relative' }}>
        <div style={{ position: 'absolute', right: '10px' }}>
          <CustomCardSettings smallWidth>
            <Label htmlFor="no_of_paramas">
              <Trans>Parameters:</Trans>
            </Label>
            <Select
              name="no_of_paramas"
              value={noOfParams}
              style={{
                padding: 3,
              }}
              onChange={onSelectChange}
            >
              {[5, 10, 20, 40, 80].map((value) => (
                <option key={value}>{value}</option>
              ))}
              <option value={Number.MAX_VALUE}>∞</option>
            </Select>
          </CustomCardSettings>
        </div>

        <SimpleTable
          data={disease.recommendations.slice(0, noOfParams)}
          cols={[
            {
              label: <Trans>Informative non-measured blood parameters</Trans>,
              value: (row) => row.description,
            },
          ]}
        />
      </Flex>
    </div>
  );
}
