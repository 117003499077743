import { Text } from 'rebass';
import styles from './Interval.module.css';

export default function Interval({ selected, setSelected }) {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.radioGroup} role="radiogroup" aria-labelledby="pricing-interval">
          <label className={styles.srOnly} id="pricing-interval" role="none">
            Payment frequency
          </label>
          <Item isChecked={selected === 'month'} label={'Monthly'} onClick={() => setSelected('month')} />
          <Item isCheaper isChecked={selected === 'year'} label={'Annually'} onClick={() => setSelected('year')} />
        </div>
      </div>

      <Text fontSize={1} style={{ textAlign: 'center', marginBottom: '1em', marginTop: '-0.5em' }}>
        Choose Annually billing and save 15%
      </Text>
    </>
  );
}

function Item({ label, isChecked, onClick, isCheaper }) {
  return (
    <div
      className={[styles.radioItem, isChecked ? styles.radioItemChecked : ''].join(' ')}
      role="radio"
      aria-checked={isChecked}
      // tabIndex={-1}
      onClick={onClick}
      style={{ position: 'relative' }}
    >
      <span>{label}</span>
      {isCheaper && <CheaperItem />}
    </div>
  );
}

export function CheaperItem() {
  return (
    <span
      style={{
        position: 'absolute',
        top: '-8px',
        right: '-1.4em',
        display: 'inline-flex',
        alignItems: 'center',
        gap: '0.2rem',
        borderRadius: '40px',
        backgroundColor: '#495e85',
        // border: '2px solid white',
        padding: '0.2em 0.5em',
        fontSize: '0.7rem',
        fontWeight: '600',
        color: 'white',
        zIndex: 1,
        boxShadow: '2px 2px 40px 4px white',
      }}
    >
      -15%
    </span>
  );
}
