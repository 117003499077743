export const getReferenceValues = (unit, gender) => {
  if (!unit) {
    return { minRef: null, maxRef: null, min: null, max: null };
  }

  const { male_min, male_max, female_min, female_max, min, max } = unit;
  if (gender === 'M') {
    return {
      minRef: male_min,
      maxRef: male_max,
      min: min,
      max: max,
    };
  } else if (gender === 'F' && (female_max !== null || female_min !== null)) {
    return {
      minRef: female_min,
      maxRef: female_max,
      min: min,
      max: max,
    };
  } else
    return {
      minRef: male_min,
      maxRef: male_max,
      min: min,
      max: max,
    };
};
