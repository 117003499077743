import { Trans } from '@lingui/macro';
import { Box } from 'rebass';

export function AboutImport() {
  return (
    <Box color="darkergray" mb={3} fontSize={1} sx={{ border: '1px solid #EDEFF3' }} p={2}>
      <Trans>The partner laboratories offering the »Import blood test« code are:</Trans>

      <ul>
        <li>
          <Trans>Adria lab (Synlab Slovenia)</Trans>
        </li>
        <li>
          <Trans>Synlab Croatia</Trans>
        </li>
      </ul>

      <Trans>
        If you have any further inquiries regarding the »Import blood test« functionality, please get in touch with us at{' '}
        <a href="mailto:support@sba-swiss.com">support@sba-swiss.com</a>.
      </Trans>
    </Box>
  );
}
