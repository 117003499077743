import { Flex, Text } from 'rebass';
import { Trans } from '@lingui/macro';
import round from 'lodash/round';

export function BinaryModelDescription({ reportDetails }) {
  const results = reportDetails?.result?.results;
  const legend = reportDetails?.result?.visualization?.legend;

  const viewData = results.map((result, index) => {
    return {
      color: legend[index].color,
      label: result.description,
      noOfPeople: round(result.ranking_factor, 2),
    };
  });

  return (
    <Flex variant="fluid" alignItems="center" mr={2} ml={1} mt={3} p={3} sx={{ gap: 2, bg: '#f4f8fb' }}>
      {viewData.map((def, i) => {
        const defStyle = { color: def.color, fontWeight: 'bold' };

        return (
          <Text key={i} sx={{ textAlign: 'left' }} fontSize={1}>
            <Trans>
              <span style={defStyle}>{def.noOfPeople?.toFixed?.(0)} out of 100</span> people with similar blood test
              results are diagnosed with `<span style={defStyle}>{def.label}</span>`.
            </Trans>
          </Text>
        );
      })}
    </Flex>
  );
}
