import { Box, Button, Flex, Heading, Text } from 'rebass';
import { Trans } from '@lingui/macro';
import MicroModal from 'react-micro-modal';
import { useCallback, useState } from 'react';
import Loader from 'components/common/Loader';
import { insertBloodTestEmptyParameters } from 'api';
import { useErrorDialog } from 'components/errorDialog/ErrorDialog';

export default function ModelRequirmentsNotMetModal({
  handleClose,
  title,
  message,
  open,
  isLarge,
  notFoundParams,
  bloodTestId,
  bloodTestStatus,
  patientId,
  onSuccessfulEmptyParamInset,
  defaultConventionType,
}) {
  const { showError } = useErrorDialog();
  const [isSavingParams, setIsSavingParams] = useState(false);
  const applyEmptyParams = useCallback(() => {
    setIsSavingParams(true);

    const addEmptyParams = async () => {
      try {
        await insertBloodTestEmptyParameters(
          patientId,
          bloodTestId,
          notFoundParams.map((nfp) => ({ code: nfp.code, unit: nfp.units?.[defaultConventionType]?.[0] })),
        );
        onSuccessfulEmptyParamInset();
      } catch (e) {
        console.log(e);
        showError(e);
        setIsSavingParams(false);
      }
    };

    addEmptyParams();
  }, [notFoundParams, bloodTestId, patientId, onSuccessfulEmptyParamInset, defaultConventionType, showError]);

  const isAbleToAutoAddParams = bloodTestStatus === 'draft' && notFoundParams != null && notFoundParams.length > 0;

  return (
    <MicroModal
      open={open}
      handleClose={handleClose}
      openInitially={false}
      overrides={
        isLarge
          ? {
              Dialog: {
                style: { maxWidth: '800px', width: '100%' },
              },
            }
          : undefined
      }
      // eslint-disable-next-line react/no-children-prop
      children={() => (
        <Flex flexDirection={'column'}>
          <Heading mb={3} color="text">
            {title}
          </Heading>
          <Text color="text" mb={2}>
            {message}
          </Text>
          <Flex mt={2} justifyContent={isAbleToAutoAddParams ? 'space-between' : 'end'}>
            {isAbleToAutoAddParams && (
              <Button onClick={applyEmptyParams} variant="orange" disabled={isSavingParams}>
                <Flex justifyItems={'center'} alignItems="center">
                  {isSavingParams && (
                    <Box pr={2}>
                      <Loader />
                    </Box>
                  )}

                  <Trans>Add required blood parameters</Trans>
                </Flex>
              </Button>
            )}
            <Button onClick={handleClose} disabled={isSavingParams}>
              <Trans>Close</Trans>
            </Button>
          </Flex>
        </Flex>
      )}
    />
  );
}
