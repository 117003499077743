import MicroModal from 'react-micro-modal';
import 'react-micro-modal/dist/index.css';
import { Flex, Text, Heading } from 'rebass';
import { Trans } from '@lingui/macro';

import Button from 'components/common/Button';

const ConfirmationModal = ({
  open,
  openInitially,
  handleConfirm,
  handleClose,
  message,
  trigger,
  header,
  cancelText,
}) => {
  return (
    <MicroModal
      open={open}
      openInitially={openInitially}
      handleClose={handleClose}
      trigger={trigger}
      // eslint-disable-next-line react/no-children-prop
      children={(handleCloseUncontrolled) => (
        <Flex width={400} flexDirection={'column'}>
          <Heading mb={3} color="text">
            {header ? <>{header}</> : <Trans>Confirm your action</Trans>}
          </Heading>
          <Text color="text" mb={4}>
            {message}
          </Text>
          <Flex justifyContent="flex-end">
            <Button mr={handleConfirm ? 3 : undefined} onClick={handleClose ? handleClose : handleCloseUncontrolled}>
              {cancelText ? <>{cancelText}</> : <Trans>Cancel</Trans>}
            </Button>
            {handleConfirm && (
              <Button
                onClick={() => {
                  handleConfirm();
                  if (handleClose) {
                    handleClose();
                  } else if (handleCloseUncontrolled) {
                    handleCloseUncontrolled();
                  }
                }}
              >
                <Trans>OK</Trans>
              </Button>
            )}
          </Flex>
        </Flex>
      )}
    />
  );
};

export default ConfirmationModal;
