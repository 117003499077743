import { Trans } from '@lingui/macro';
import { getSubscriptionBillingEvents, getSubscriptionStatusFromUser } from 'components/common/Dropdown';
import { useUser } from 'components/common/ProtectedRoute';
import { Link } from 'react-router-dom';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { Box, Flex } from 'rebass';

export function ExistingBilling() {
  const [{ user }] = useUser();
  const subscription = getSubscriptionStatusFromUser(user);
  const subscriptionIsActive = subscription?.status === 'active';
  const remainingBillingEvents = getSubscriptionBillingEvents(subscription, 0);

  if (subscription?.plan_name?.toLowerCase?.() === 'contract') {
    return <Message text={<Trans>You already have an active subscription.</Trans>} />;
  }

  if (subscriptionIsActive && remainingBillingEvents > 0) {
    return (
      <Message
        text={<Trans>You already have an active subscription with {remainingBillingEvents} billing events left.</Trans>}
      />
    );
  }

  return null;
}

function Message({ text }) {
  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      bg="lightgray"
      p={3}
      my={3}
      mt="-20px"
      sx={{ borderRadius: '1rem' }}
    >
      <Box color={'primary'} fontWeight={'bold'} mb={1}>
        {text}
      </Box>
      <Box mb={3} fontWeight={1} fontSize={'0.9em'} fontStyle={'italic'}>
        <Trans>No action is needed. Enjoy our service!</Trans>
      </Box>
      <Box>
        <Link to="/">
          <div style={{ display: 'flex', gap: '0.5ch' }}>
            <FaExternalLinkAlt />
            <Trans>Go to homepage</Trans>
          </div>
        </Link>
      </Box>
    </Flex>
  );
}
