export function DiseaseColorBox({ color }) {
  return (
    <div
      style={{
        display: 'inline-block',
        width: '2em',
        height: '1em',
        background: color,
        borderRadius: '0.2em',
        marginRight: '1ch',
        border: '1px solid #CED3DE',
        marginBottom: '-0.2em',
        boxShadow: '0 0 2px rgba(0, 0, 0, 0.1)',
      }}
    />
  );
}
