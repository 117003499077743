import { useEffect, useState } from 'react';
import { Flex, Box, Text } from 'rebass';
import { withRouter } from 'react-router-dom';
import find from 'lodash/find';
import { Trans } from '@lingui/macro';

import { getBloodTestParameters } from 'api';
import ParameterGroup from './ParameterGroup';
import useGlobal from 'hooks/global';
import Loader from 'components/common/Loader';
import { useErrorDialog } from 'components/errorDialog/ErrorDialog';

const Parameters = ({ match, isLocked }) => {
  const { showError } = useErrorDialog();
  const [globalState, globalActions] = useGlobal();
  const [isLoading, handleLoading] = useState(true);
  const [groupView, toggleGroupView] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      try {
        const res = await getBloodTestParameters(match.params.id, match.params.bloodTestId);
        globalActions.setSavedParameters(res);
      } catch (e) {
        showError(e);
        console.log(e);
      } finally {
        handleLoading(false);
      }
    };
    fetch();
  }, [globalActions, match.params.bloodTestId, match.params.id, showError]);

  const renderToggle = () => {
    return (
      <Flex variant="fluid" sx={{ borderBottom: '1px solid', borderColor: 'lightgray' }}>
        <Box color="darkergray" width={[1, 1 / 2]} pt={2} pl={1} sx={{ textAlign: ['center', 'left'] }}>
          <Trans>Saved parameters: {globalState.savedParameters.length}</Trans>
        </Box>
        <Box width={[1, 1 / 2]} pr={1} pb={4} pt={2} sx={{ textAlign: ['center', 'right'] }}>
          <Text fontWeight={groupView && 'bold'} variant="clickable" onClick={() => toggleGroupView(true)} mr={3}>
            <Trans>Groups view</Trans>
          </Text>
          <Text sx={{ display: 'inline-block' }} color="lightgray">
            |
          </Text>
          <Text fontWeight={!groupView && 'bold'} variant="clickable" onClick={() => toggleGroupView(false)} ml={3}>
            <Trans>List view</Trans>
          </Text>
        </Box>
      </Flex>
    );
  };

  const renderGroupView = () => {
    // split saved parameters into groups
    let groups = {};
    globalState.savedParameters.map((item) => {
      const paramInfo = find(globalState.parameters, (p) => p.code === item.code);
      if (paramInfo) {
        if (!groups[paramInfo.group]) {
          groups[paramInfo.group] = { name: paramInfo.group, order: paramInfo.group_order || 0, params: [] };
        }
        groups[paramInfo.group].params.push(item);
        return item;
      }
      return null;
    });

    return (
      <Box>
        {Object.values(groups)
          .sort((a, b) => a.order - b.order)
          .map((group, index) => (
            <ParameterGroup isLocked={isLocked} key={index} name={group.name} savedParameters={group.params} />
          ))}
      </Box>
    );
  };

  const renderListView = () => (
    <Box>
      <ParameterGroup isLocked={isLocked} key="all" name="All" savedParameters={globalState.savedParameters} />
    </Box>
  );

  // Loader
  if (isLoading || globalState.parameters.length === 0) return <Loader big text="Loading parameters" />;

  // Main return
  const view = groupView ? renderGroupView() : renderListView();

  return (
    <Flex key="groups" variant="patient.container">
      {globalState.savedParameters.length > 0 && renderToggle()}
      {view}
    </Flex>
  );
};

export default withRouter(Parameters);
