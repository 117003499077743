import { Trans } from '@lingui/macro';
import { useCallback, useState } from 'react';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { Box, Text } from 'rebass';
import { ReportParamDescription, ReportParamDescriptionBinary } from './ReportParamDescription';

export default function ReportParamDescriptionContainer(props) {
  const [isShown, setIsShown] = useState(false);

  const toggleShown = useCallback(() => {
    setIsShown((prevstate) => !prevstate);
  }, []);

  return (
    <Box
      my={1}
      sx={{
        border: '1px solid',
        borderColor: isShown ? 'lightgray' : 'lightgray',
        pb: isShown ? 3 : undefined,
      }}
    >
      <Text
        display={'flex'}
        color="orange"
        alignItems={'center'}
        sx={{
          borderBottom: isShown ? '1px solid' : 'none',
          borderColor: 'lightgray',
          textAlign: 'left',
          cursor: 'pointer',
          backgroundColor: 'lightergray',
        }}
        p={2}
        py={'0.75em'}
        onClick={toggleShown}
      >
        {isShown ? <FaAngleUp /> : <FaAngleDown />} &nbsp; <Trans>Parameter contribution</Trans>
      </Text>
      {isShown && (
        <Box pt={3}>
          {props.reportDetails?.result?.results?.length === 2 ? (
            <ReportParamDescriptionBinary {...props} />
          ) : (
            <ReportParamDescription {...props} />
          )}
        </Box>
      )}
    </Box>
  );
}
