import { useState } from 'react';
import { Flex, Box } from 'rebass';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router-dom';
import { Input, Label, Radio } from '@rebass/forms';
import { Trans } from '@lingui/macro';

import { createPatient } from 'api';
import { renderError } from 'utils';
import useGlobal from 'hooks/global';

import InputErrorMessage from 'components/common/InputErrorMessage';
import Button from 'components/common/Button';
import Textarea from 'components/common/Textarea';
import Breadcrumbs from 'components/common/Breadcrumbs';
import Message from 'components/common/Message';
import DatePicker from 'components/common/DatePicker';

const AddPatient = (props) => {
  const {
    register,
    handleSubmit,
    setValue: setFormValue,
    formState: { errors },
  } = useForm();
  const [isLoading, handleLoading] = useState(false);
  const [apiResponse, handleResponse] = useState({ message: '', type: '' });
  // eslint-disable-next-line
  const [globalState, globalActions] = useGlobal();

  const onSubmit = async (data) => {
    handleLoading(true);

    try {
      // create patient
      const patient = await createPatient(data);
      globalActions.setPatient(patient);

      // display success message
      handleResponse({ message: 'Patient created succesfully, redirecting to new blood test...', type: 'ok' });
      setTimeout(() => {
        handleLoading(false);
        props.history.push(`/patient/${patient.id}`);
      }, 1000);
    } catch (e) {
      handleLoading(false);
      handleResponse({ message: renderError(e), type: 'error' });
    }
  };

  return (
    <Flex variant="container" alignItems="center" width="100%">
      <Box width={1}>
        <Breadcrumbs />
      </Box>
      <Box p={[1, 1, 1, 2, 4]} width={[1, 1, 3 / 4, 1 / 2, 2 / 5]} as="form" onSubmit={handleSubmit(onSubmit)}>
        {apiResponse && <Message type={apiResponse.type} message={apiResponse.message} />}
        <Label mt={3}>
          <Trans>Patient ID</Trans>
        </Label>
        <Input className={errors.uid ? 'error' : ''} {...register('uid', { required: true })} />
        <InputErrorMessage name="uid" errors={errors} />
        <Flex variant="fluid">
          <Box width={[1, 1 / 2, 1 / 2, 1 / 2, 1 / 2]}>
            <Label mt={3}>
              <Trans>Date of birth</Trans>
            </Label>
            <DatePicker
              name="birth_date"
              register={register}
              registerProps={{ required: true }}
              over18
              maxYears={110}
              setFormValue={setFormValue}
              errors={errors.birth_date}
            />
            <InputErrorMessage name="birth_date" errors={errors} />
          </Box>
          <Box width={[1, 1 / 2, 1 / 2, 1 / 2, 1 / 2]} pl={3}>
            <Label mt={3}>
              <Trans>Gender</Trans>
            </Label>
            <Flex height="48px">
              <Box width={1 / 2}>
                <Label sx={{ justifyContent: 'left', alignItems: 'center' }}>
                  <Radio {...register('gender', { required: true })} value="M" />
                  <Trans>Male</Trans>
                </Label>
              </Box>
              <Box width={1 / 2}>
                <Label sx={{ justifyContent: 'left', alignItems: 'center' }}>
                  <Radio {...register('gender', { required: true })} value="F" />
                  <Trans>Female</Trans>
                </Label>
              </Box>
            </Flex>
            <InputErrorMessage name="gender" errors={errors} />
          </Box>
        </Flex>
        <Label mt={3}>
          <Trans>Comments</Trans>
        </Label>
        <Textarea name="comment" register={register} />
        <InputErrorMessage name="comment" errors={errors} />
        <Flex justifyContent="flex-end">
          <Button mt={4} mb={4} type="submit" isLoading={isLoading}>
            <Trans>Save patient</Trans>
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
};

export default withRouter(AddPatient);
