export const setJwt = (jwt) => {
  if (jwt.access) {
    localStorage.setItem('jwt', jwt.access);
  }
  if (jwt.refresh) {
    localStorage.setItem('jwt_refresh', jwt.refresh);
  }
};

export const setRemember = (device_hash) => {
  if (device_hash) {
    localStorage.setItem('remember', device_hash);
  }
};

export const getRemember = () => {
  return localStorage.getItem('remember');
};

export const getJwt = () => {
  return localStorage.getItem('jwt');
};

export const getJwtRefresh = () => localStorage.getItem('jwt_refresh');

export const removeJwt = () => {
  localStorage.removeItem('lang');
  localStorage.removeItem('jwt');
  localStorage.removeItem('jwt_refresh');
};

export const setLocalStorage = (key, value) => {
  localStorage.setItem(key, value);
};

export const getLocalStorage = (key, coalsceValue) => {
  return localStorage.getItem(key) || coalsceValue;
};
