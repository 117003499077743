import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { createTrainingUser } from 'api';
import { Flex, Box, Heading, Text, Image } from 'rebass';
import { Input, Label, Select, Checkbox } from '@rebass/forms';
import Graph from 'assets/sba-graph.png';
import Button from 'components/common/Button';
import Message from 'components/common/Message';
import { renderError } from 'utils';
import { RequiredData, getSharedData } from 'components/common/SharedData/SharedData';
import { withRouter } from 'react-router-dom';
import { TermAndPolicy } from 'components/Signup/terms/TermAndPolicy';

function Training() {
  const countries = useRef(getSharedData('COUNTRIES'));
  const {
    register,
    handleSubmit,
    control,
    setValue: setFormValue,
    formState: { errors },
  } = useForm();
  const [message, handleMessage] = useState();
  const [apiError, handleError] = useState('');
  const [isLoading, handleLoading] = useState(false);

  const onSubmit = async (data) => {
    handleMessage('');
    handleError('');
    handleLoading(true);

    try {
      await createTrainingUser(data);
      handleMessage(
        <span>
          You need to confirm your email address before we can proceed. Please check your mailbox! <br />
          If you do not receive the confirmation message within a few minutes of signing up, please check your Spam or
          Bulk E-Mail folder just in case the confirmation message was delivered there instead of to your Inbox. <br />
          If so, select the confirmation message and mark it as “Not Spam”, which should allow future messages to get
          through. If you are having problems <a href="mailto:support@sba-swiss.com">contact us</a>.
        </span>,
      );
      handleLoading(false);
    } catch (e) {
      handleError(renderError(e));
      handleLoading(false);
    }
  };

  return (
    <Flex variant="container" alignItems="center" justifyContent="center" width="100%">
      <div className="circle-outer">
        <div className="circle-inner" />
      </div>
      <Image src={Graph} width={['60%', '60%', '60%', '60%', '30%']} mb={4} />
      <Heading fontSize={[5, 5, 5, 5, 6]} color="primary">
        Health Professionals only
        <br />
      </Heading>
      <Box as="form" onSubmit={handleSubmit(onSubmit)} p={[1, 2, 2, 2, 4]} width={[1, 1, 4 / 5, 3 / 5]} mb={3}>
        <Text fontSize={3} pb={3} color="orange" textAlign="center">
          The use of the SBAS Software is intended for medical doctors only.
          <br />
          Please use your medical institution email when registering.
          <br />
          All applications not compliant with this request may be ignored.
        </Text>
        <Flex variant="fluid">
          <Box pr={[0, 0, 0, 0, 3]} width={['100%', '100%', '100%', '100%', 1 / 2]}>
            <Label mt={3}>First Name *</Label>
            <Input className={errors.first_name ? 'error' : ''} {...register('first_name', { required: true })} />
            {errors.first_name && (
              <Text py={2} fontSize={1} color="orange">
                Field is required
              </Text>
            )}
          </Box>
          <Box width={['100%', '100%', '100%', '100%', 1 / 2]}>
            <Label mt={3}>Last Name *</Label>
            <Input className={errors.last_name ? 'error' : ''} {...register('last_name', { required: true })} />
            {errors.last_name && (
              <Text py={2} fontSize={1} color="orange">
                Field is required
              </Text>
            )}
          </Box>
        </Flex>

        <Flex variant="fluid">
          <Box width={['100%', '100%', '100%', '100%', 1 / 2]} pr={[0, 0, 0, 0, 3]}>
            <Label mt={3}>E-mail *</Label>
            <Input className={errors.email ? 'error' : ''} {...register('email', { required: true })} />
            {errors.email && (
              <Text py={2} fontSize={1} color="orange">
                E-mail is required
              </Text>
            )}
          </Box>
          <Box width={['100%', '100%', '100%', '100%', 1 / 2]}>
            <Label mt={3}>Medical Institution or Company *</Label>
            <Input className={errors.company ? 'error' : ''} {...register('company', { required: true })} />
            {errors.company && (
              <Text py={2} fontSize={1} color="orange">
                Field is required
              </Text>
            )}
          </Box>
        </Flex>

        <Flex variant="fluid" mt={3}>
          <Box width={['100%', '100%', '100%', '100%', 1 / 2]} pr={[0, 0, 0, 0, 3]}>
            <Label>Country *</Label>
            <Select className={errors.country ? 'error' : ''} {...register('country', { required: true })}>
              <option></option>
              {countries.current.map((country, index) => (
                <option key={index} value={country.code}>
                  {country.name}
                </option>
              ))}
            </Select>
            {errors.country && (
              <Text py={2} fontSize={1} color="orange">
                Country is required
              </Text>
            )}
          </Box>
          <Box width={['100%', 1 / 2]}></Box>
        </Flex>

        <Flex>
          <Label
            className={errors.termsOfUse ? 'error' : ''}
            sx={{ alignItems: 'center', display: 'grid !important', gridTemplateColumns: '30px auto' }}
          >
            <TermAndPolicy
              control={control}
              register={register}
              setFormValue={setFormValue}
              countryProperty="country"
            />
          </Label>
        </Flex>
        <Flex>
          <Label
            className={errors.marketing_emails ? 'error' : ''}
            sx={{ alignItems: 'center', display: 'grid !important', gridTemplateColumns: '30px auto' }}
          >
            <Checkbox id="marketing_emails" {...register('marketing_emails')} />
            <Box>
              Please send me Smart Blood Analytics Swiss product and promotional emails. You can unsubscribe at any
              time.
            </Box>
          </Label>
        </Flex>
        <Flex justifyContent="flex-end">
          <Button type="submit" mt={4} mb={4} isLoading={isLoading} disabled={message != null}>
            Submit
          </Button>
        </Flex>
        {message && <Message type="ok" message={message} handleHide={() => handleError('')} />}
        {apiError && <Message type="error" message={apiError} handleHide={() => handleError('')} />}
      </Box>
    </Flex>
  );
}

const PrepareDataForTraining = () => {
  return (
    <RequiredData dataKeys={['COUNTRIES']} loaderIsDark loaderIsVeryBig>
      <Training />
    </RequiredData>
  );
};

export default withRouter(PrepareDataForTraining);
