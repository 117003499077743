import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { createUser } from 'api';
import { Flex, Box, Heading, Text, Image } from 'rebass';
import { Label, Checkbox } from '@rebass/forms';
import { withRouter, Link, useParams } from 'react-router-dom';
import Graph from 'assets/sba-graph.png';
import Button from 'components/common/Button';
import Message from 'components/common/Message';
import { renderError } from 'utils';
import { parseLocalizedDate } from 'components/common/DateFormat';
import { UserInputForm } from './forms/UserInputForm';
import { InputFormContainer } from './forms/InputFormContainer';
import { OrganizationInputForm } from './forms/OrganizationInputForm';
import { TermAndPolicy } from './terms/TermAndPolicy';
import { RequiredData } from 'components/common/SharedData/SharedData';

const Signup = () => {
  const { organizationId: organization_id } = useParams();
  const {
    register,
    handleSubmit,
    setValue: setFormValue,
    formState: { errors },
    control,
  } = useForm();

  const [message, handleMessage] = useState('');
  const [apiError, handleError] = useState('');
  const [isLoading, handleLoading] = useState(false);

  const onSubmit = async (data) => {
    handleMessage('');
    handleError('');
    handleLoading(true);

    try {
      await createUser({
        ...data,
        organization_id,
        birthday: parseLocalizedDate(data.birthday),
      });
      handleMessage(
        <span>
          You need to confirm your email address before we can proceed. Please check your mailbox! <br />
          If you do not receive the confirmation message within a few minutes of signing up, please check your Spam or
          Bulk E-Mail folder just in case the confirmation message was delivered there instead of to your Inbox. <br />
          If so, select the confirmation message and mark it as “Not Spam”, which should allow future messages to get
          through. If you are having problems <a href="mailto:support@sba-swiss.com">contact us</a>.
        </span>,
      );
      handleLoading(false);
    } catch (e) {
      handleError(renderError(e));
      handleLoading(false);
    }
  };

  return (
    <Flex variant="container" alignItems="center" justifyContent="center" width="100%">
      <div className="circle-outer">
        <div className="circle-inner" />
      </div>
      <Image src={Graph} width={['60%', '60%', '60%', '60%', '30%']} mb={4} />
      <Heading fontSize={[5, 5, 5, 5, 6]} color="primary">
        Health Professionals only
        <br />
      </Heading>
      <Text fontSize={3} my={3} color="orange" textAlign="center">
        The use of SBAS web application is intended for medical doctors only.
        <br />
        Please use your medical organization email when registering.
        <br />
        All sign ups not compliant with this request may be ignored.
      </Text>
      <Box as="form" onSubmit={handleSubmit(onSubmit)} p={[1, 2, 2, 2, 4]} width={[1, 1, 4 / 5, 3 / 5]} mb={3}>
        {!organization_id && (
          <InputFormContainer title="Medical organization" sx={{ mb: 3 }}>
            <OrganizationInputForm errors={errors} register={register} setFormValue={setFormValue} control={control} />
          </InputFormContainer>
        )}
        <InputFormContainer title="User" sx={{ mb: 3 }}>
          <UserInputForm control={control} errors={errors} register={register} setFormValue={setFormValue} />
        </InputFormContainer>
        <InputFormContainer title="Terms & Conditions" sx={{ mb: 3 }}>
          <Flex>
            <Label
              className={errors.termsOfUse ? 'error' : ''}
              sx={{ alignItems: 'center', display: 'grid !important', gridTemplateColumns: '30px auto', mt: 3 }}
            >
              <TermAndPolicy
                control={control}
                register={register}
                setFormValue={setFormValue}
                countryProperty="organization_country"
              />
            </Label>
          </Flex>
          <Flex>
            <Label
              className={errors.marketing_emails ? 'error' : ''}
              sx={{ alignItems: 'center', display: 'grid !important', gridTemplateColumns: '30px auto' }}
            >
              <Checkbox id="marketing_emails" {...register('marketing_emails')} />
              <Box>
                Please send me Smart Blood Analytics Swiss product and promotional emails. (You can unsubscribe at any
                time.)
              </Box>
            </Label>
          </Flex>
        </InputFormContainer>
        <Flex justifyContent="flex-end">
          <Button type="submit" mt={4} mb={4} isLoading={isLoading} disabled={message}>
            Sign up
          </Button>
        </Flex>
        {message && <Message type="ok" message={message} handleHide={() => handleError('')} />}
        {apiError && <Message type="error" message={apiError} handleHide={() => handleError('')} />}
      </Box>
      <Box width={[1, 1, 3 / 5]}>
        <hr style={{ opacity: 0.1 }} />
      </Box>
      <Text textAlign="center" py={5}>
        <Link to="/login">Already have an account? Sign in!</Link>
      </Text>
    </Flex>
  );
};

const PrepareDataForSignUp = () => {
  return (
    <RequiredData dataKeys={['COUNTRIES']} loaderIsDark loaderIsVeryBig>
      <Signup />
    </RequiredData>
  );
};

export default withRouter(PrepareDataForSignUp);
