import { Box } from 'rebass';

const style = {
  padding: 3,
  borderRadius: 8,
  border: '1px solid',
  textAlign: 'center',
  width: '100%',
};

const Message = ({ type, message, mb }) => {
  if (type === 'error') {
    return (
      <Box mb={mb} bg="white" color="red" borderColor="red" sx={style}>
        {message}
      </Box>
    );
  }
  if (type === 'ok') {
    return (
      <Box mb={mb} bg="white" color="green" borderColor="green" sx={style}>
        {message}
      </Box>
    );
  }
  return null;
};

export default Message;
