import './loader.css';
import { Box, Text } from 'rebass';

const Loader = ({ big, padding }) => {
  // optimize this, without className
  const wrap = big ? 'loader-box big' : 'loader-box';
  const c = big ? 'loader big' : 'loader';
  return (
    <Box className={wrap} p={big ? padding || 5 : 0}>
      <div className={c}></div>
      {false && big && (
        <Text pt={3} color="primary">
          {'Loading'}
        </Text>
      )}
    </Box>
  );
};

export const LoaderSmall = ({ text, dark, inline, style }) => {
  const classes = `loader ${dark ? 'dark' : ''} ${inline ? 'inline' : ''}`;

  return [
    <div key="loader" className={classes} style={style}></div>,
    text && (
      <Text sx={{ display: 'inline-block' }} fontSize={2} color="text" key="text">
        {text}
      </Text>
    ),
  ];
};

export default Loader;
