import { Box } from 'rebass';
import Breadcrumbs from 'components/common/Breadcrumbs';
import PatientInfo from 'components/common/PatientInfo';
import BloodTestReportContent from './BloodTestReportContent';

export default function BloodTestReport() {
  return (
    <Box variant="container">
      <Breadcrumbs />
      <PatientInfo />
      <BloodTestReportContent />
    </Box>
  );
}
